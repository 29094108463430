// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Q_Vm1jGlajQjMUeYOlaH {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.f851RA8y3hc_TlcjQ9sl {
  width: 100%;
  box-sizing: border-box;
  font-family: var(--font-secondary);
  font-weight: var(--font-weight--normal);
  font-size: var(--font-size--md);
  display: flex;
  height: 6rem;
  background: var(--white);
  border: var(--primary-border);
  border-radius: 0.6rem;
  gap: 3rem;
  align-items: center;
}

.Rqwvn1Y1SIMKGTA8Ud30 {
  width: 100%;
  padding: 0 1.5rem;
  cursor: pointer !important;
}

.PfDvWhFJctYCYdAyvRjn {
  background-color: #dfdcf8 !important;
  color: var(--black) !important;
}`, "",{"version":3,"sources":["webpack://./src/components/2-molecules/Form/FormFieldSelect/FormFieldSelect.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,sBAAA;AACF;;AAEA;EACE,WAAA;EACA,sBAAA;EACA,kCAAA;EACA,uCAAA;EACA,+BAAA;EACA,aAAA;EACA,YAAA;EACA,wBAAA;EACA,6BAAA;EACA,qBAAA;EACA,SAAA;EACA,mBAAA;AACF;;AAEA;EACE,WAAA;EACA,iBAAA;EACA,0BAAA;AACF;;AAEA;EACE,oCAAA;EACA,8BAAA;AACF","sourcesContent":[".FormFieldSelect {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n}\n\n.FormFieldSelect__container {\n  width: 100%;\n  box-sizing: border-box;\n  font-family: var(--font-secondary);\n  font-weight: var(--font-weight--normal);\n  font-size: var(--font-size--md);\n  display: flex;\n  height: 6rem;\n  background: var(--white);\n  border: var(--primary-border);\n  border-radius: 0.6rem;\n  gap: 3rem;\n  align-items: center;\n}\n\n.FormFieldSelect__controller {\n  width: 100%;\n  padding: 0 1.5rem;\n  cursor: pointer !important;\n}\n\n.FormFieldSelect__options {\n  background-color: #dfdcf8 !important;\n  color: var(--black) !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"FormFieldSelect": `Q_Vm1jGlajQjMUeYOlaH`,
	"FormFieldSelect__container": `f851RA8y3hc_TlcjQ9sl`,
	"FormFieldSelect__controller": `Rqwvn1Y1SIMKGTA8Ud30`,
	"FormFieldSelect__options": `PfDvWhFJctYCYdAyvRjn`
};
export default ___CSS_LOADER_EXPORT___;
