import { store } from '@/store';
import axios, { AxiosInstance, AxiosResponse } from 'axios';

const createApi = (): AxiosInstance => {
  const api = axios.create({
    baseURL: process.env.API_BASEURL ?? 'https://localhost:7229/api',
    timeout: 15000,
    withCredentials: true,
    headers: {
      // 'Content-Type': 'application/json',
      Authorization: `Bearer ${store.getState().user.token}`,
      Accept: '*/*',
    },
  });

  api.interceptors.response.use(
    (response: AxiosResponse) => {
      if (response.headers['content-type'] == 'application/json') {
        return (response.data = JSON.parse(response.data));
      }

      return response.data;
    },
    (error) => {
      console.log('Axios: Failed Request Error:', error);

      if (error.response?.status === 401) {
        console.log('Axios: Unauthorized Error:', error);
      }

      return Promise.reject({
        success: false,
        message: error.message,
        status: error.response ? error.response.status : undefined,
      });
    },
  );

  return api;
};

export default createApi;
