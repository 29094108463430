// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.xK60RB2gOkjmAB2lwxbC {
  width: 100%;
}

.UbMsabY5cF3T7Q4qALlm {
  min-height: 53.2rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
}

.Byh27aRDcKZtrzw0vTuj {
  margin-bottom: 2rem;
}`, "",{"version":3,"sources":["webpack://./src/components/3-organisms/ContractsOverview/ContractsOverview.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;;AAEA;EACE,mBAAA;EACA,aAAA;EACA,mBAAA;EACA,sBAAA;EACA,uBAAA;EACA,SAAA;AACF;;AAEA;EACE,mBAAA;AACF","sourcesContent":[".ContractsOverview {\n  width: 100%;\n}\n\n.ContractsOverview__emptyList {\n  min-height: 53.2rem;\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n  justify-content: center;\n  gap: 2rem;\n}\n\n.ContractsOverview__emptyListHeading {\n  margin-bottom: 2rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ContractsOverview": `xK60RB2gOkjmAB2lwxbC`,
	"ContractsOverview__emptyList": `UbMsabY5cF3T7Q4qALlm`,
	"ContractsOverview__emptyListHeading": `Byh27aRDcKZtrzw0vTuj`
};
export default ___CSS_LOADER_EXPORT___;
