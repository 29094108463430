import classNames from 'classnames';

import styles from './FormFieldCta.module.scss';

export interface FormFieldCtaProps {
  className?: string;
  errorMessage?: string;
  id: string;
  label: string;
  disabled?: boolean;
  type: 'submit' | 'reset';
}

// TODO: storybook

export const FormFieldCta = ({ className, id, label, disabled, type }: FormFieldCtaProps) => {
  return (
    <input
      type={type}
      className={classNames(styles.FormFieldCta, styles[`FormFieldCta__${type}`], className)}
      id={id}
      disabled={disabled}
      value={label}
    />
  );
};
