// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SZfJMObPfjOKhmcqdeeq {
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  padding-top: 8rem;
}

.XBhosoeZicybQpJVgWAp {
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.styVtcqCxl0WFLbeOw91 {
  width: 60rem;
  display: flex;
  flex-direction: column;
  padding: 8rem 4rem;
  gap: 2rem;
  align-items: center;
}

.KFhZF01sIU4i5pskH2Kp {
  background: var(--white);
  box-shadow: var(--primary-shadow);
  padding: 1.5rem 2.4rem;
  border-radius: 0.6rem;
  display: flex;
  align-items: center;
  gap: 2rem;
  align-self: start;
}

.fGNhxTae87ryeMSvaQbw {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 4rem;
}

.gcYv8YJHbtim9c4e2nP6 {
  width: 50%;
}

.y2M_s3biF93F33v_5qs5 {
  width: 2rem;
  height: 2rem;
}

.utyUp_55MsYP58GYij7G {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.D6W8sg7KzuEw2ZVJfVby {
  display: flex;
  align-items: center;
  gap: 3rem;
}`, "",{"version":3,"sources":["webpack://./src/components/3-organisms/TenderDetails/ChatOverview/ChatList/ChatList.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,WAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;EACA,iBAAA;AACF;;AAEA;EACE,WAAA;EACA,WAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;AACF;;AAEA;EACE,YAAA;EACA,aAAA;EACA,sBAAA;EACA,kBAAA;EACA,SAAA;EACA,mBAAA;AACF;;AAEA;EACE,wBAAA;EACA,iCAAA;EACA,sBAAA;EACA,qBAAA;EACA,aAAA;EACA,mBAAA;EACA,SAAA;EACA,iBAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,aAAA;AACF;;AAEA;EACE,UAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;AACF;;AAEA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,SAAA;AACF","sourcesContent":[".ChatList {\n  width: 100%;\n  height: 90%;\n  display: flex;\n  flex-direction: column;\n  gap: 4rem;\n  padding-top: 8rem;\n}\n\n.ChatList__emptyList {\n  width: 100%;\n  height: 90%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n}\n\n.ChatList__addMessageCard {\n  width: 60rem;\n  display: flex;\n  flex-direction: column;\n  padding: 8rem 4rem;\n  gap: 2rem;\n  align-items: center;\n}\n\n.ChatList__addMessageButton {\n  background: var(--white);\n  box-shadow: var(--primary-shadow);\n  padding: 1.5rem 2.4rem;\n  border-radius: 0.6rem;\n  display: flex;\n  align-items: center;\n  gap: 2rem;\n  align-self: start;\n}\n\n.ChatList__popup {\n  display: flex;\n  flex-direction: column;\n  gap: 2rem;\n  padding: 4rem;\n}\n\n.ChatList__selectSupplier {\n  width: 50%;\n}\n\n.ChatList__uploadIcon {\n  width: 2rem;\n  height: 2rem;\n}\n\n.ChatList__popupFooter {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.ChatList__actionButtonsWrapper {\n  display: flex;\n  align-items: center;\n  gap: 3rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ChatList": `SZfJMObPfjOKhmcqdeeq`,
	"ChatList__emptyList": `XBhosoeZicybQpJVgWAp`,
	"ChatList__addMessageCard": `styVtcqCxl0WFLbeOw91`,
	"ChatList__addMessageButton": `KFhZF01sIU4i5pskH2Kp`,
	"ChatList__popup": `fGNhxTae87ryeMSvaQbw`,
	"ChatList__selectSupplier": `gcYv8YJHbtim9c4e2nP6`,
	"ChatList__uploadIcon": `y2M_s3biF93F33v_5qs5`,
	"ChatList__popupFooter": `utyUp_55MsYP58GYij7G`,
	"ChatList__actionButtonsWrapper": `D6W8sg7KzuEw2ZVJfVby`
};
export default ___CSS_LOADER_EXPORT___;
