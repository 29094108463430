import classNames from 'classnames';
import { AnchorLink, Heading, Image, TextBox } from '@/components/1-atoms';
import styles from './Tasks.module.scss';
import EmptyTasksImage from '@/assets/Images/image-empty-tasks-single-tender.svg';
import { TaskCard } from '@/components/2-molecules';

export interface TasksProps {
  className?: string;
  tasks?: TenderTasks[];
}

export const Tasks = ({ className, tasks }: TasksProps) => {
  return (
    <div className={classNames(styles.Tasks, className)}>
      {tasks && tasks.length > 0 ? (
        <div className={styles.Tasks__list}>
          {tasks.map((task) => (
            <TaskCard key={task.id} task={task} />
          ))}
        </div>
      ) : (
        <div className={styles.Tasks__emptyList}>
          <Image src={EmptyTasksImage} alt={'Empty Tasks Image'} />
          <TextBox
            title={
              <Heading headingLevel={'h3'} className={styles.Tasks__emptyListHeading}>
                Hurray
              </Heading>
            }
            description={
              <>
                You have finished all tasks! <br />
                <AnchorLink link={''}>Start a new tender</AnchorLink> or{' '}
                <AnchorLink link={''}>take a look</AnchorLink> at our wiki <br /> pages and read
                something interesting.
              </>
            }
            alignment={'centre'}
          />
        </div>
      )}
    </div>
  );
};
