// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NxWuX1bB91iaahIln2I3 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: row;
}

.wjAIchCjjse8bEMQHsMk {
  display: flex;
  flex-flow: column;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/4-templates/LeadsLayout/LeadsLayout.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,cAAA;AACF;;AAEA;EACE,aAAA;EACA,iBAAA;EACA,WAAA;AACF","sourcesContent":[".Layout {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-flow: row;\n}\n\n.Layout__contentWrapper {\n  display: flex;\n  flex-flow: column;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Layout": `NxWuX1bB91iaahIln2I3`,
	"Layout__contentWrapper": `wjAIchCjjse8bEMQHsMk`
};
export default ___CSS_LOADER_EXPORT___;
