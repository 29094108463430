export const mockedResponses: PhaseResponseDetails[] = [
  {
    id: '1',
    tenderId: '23212345',
    supplierName: 'Inkasso & juridik AB',
    phaseName: 'RFI Inkasso',
    score: '15',
    questions: {
      completed: '1/2',
      responses: [
        {
          id: '0001',
          question: 'Hur många handläggare har ni med språkkunskaper i svenska, finska och norska?',
          answer: 'Vi har 2 svenska, 3 finska och 1 norsktalande',
          score: '2',
          note: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt?',
          category: 1,
        },
        {
          id: '0002',
          question: 'Hur många kunder har ni inom segmentet Jordbruk?',
          answer: 'Vi har 50 + kunder inom jordbrukssektorn',
          score: '2',
          note: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt?',
          category: 1,
        },
        {
          id: '0003',
          question: 'Har ni en 2 vägs identifiering?',
          answer: 'Ja, vi har hög it säkerhet',
          score: '2',
          note: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt?',
          category: 3,
        },

        {
          id: '0004',
          question: 'Erbjuder ni en SaaS applikation där vi själva kan registrera ärenden?',
          answer: 'Vi har en användarvänlig portal ni kan fritt använda',
          score: '2',
          note: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt?',
          category: 4,
        },
      ],
    },
    documents: {
      completed: '1/2',
      responses: [
        {
          id: '0002',
          question: 'Inkasso upphandling.doc',
          score: '2',
          note: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt?',
          url: 'file',
          category: 1,
        },
      ],
    },
  },

  {
    id: '2',
    tenderId: '23212345',
    supplierName: 'Kollekten Inkasso AB',
    phaseName: 'RFI Inkasso',
    score: '15',
    questions: {
      completed: '1/2',
      responses: [
        {
          id: '0001',
          question: 'Hur många handläggare har ni med språkkunskaper i svenska, finska och norska?',
          answer: 'Vi har 16 svenska 0 finska och 0 norska',
          score: '2',
          note: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt?',
          category: 1,
        },
        {
          id: '0002',
          question: 'Hur många kunder har ni inom segmentet Jordbruk?',
          answer: 'Vi har 500 + kunder inom jordbrukssektorn',
          score: '2',
          note: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt?',
          category: 1,
        },
        {
          id: '0003',
          question: 'Har ni en 2 vägs identifiering?',
          answer: 'Ja, vi tillhandahåller 2 vägs identifiering.',
          score: '2',
          note: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt?',
          category: 3,
        },

        {
          id: '0004',
          question: 'Erbjuder ni en SaaS applikation där vi själva kan registrera ärenden?',
          answer: 'Ni har tillgång till samma portal som vi använder.',
          score: '2',
          note: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt?',
          category: 4,
        },
      ],
    },
    documents: {
      completed: '1/2',
      responses: [
        {
          id: '0002',
          question: 'Inkasso upphandling.doc',
          score: '2',
          note: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt?',
          url: 'file',
          category: 1,
        },
      ],
    },
  },
];

export const mockedCompareResponses: PhaseResponsesCompare = {
  id: '1',
  tenderId: '23212345',
  phaseName: 'Phase One',
  questions: [
    {
      completed: '1/2',
      category: 1,
      question: 'Hur många handläggare har ni med språkkunskaper i svenska, finska och norska?',
      responses: [
        {
          id: '0001',
          supplierName: 'Inkasso & juridik AB',
          supplierId: '1',
          answer: 'Vi har 2 svenska, 3 finska och 1 norsktalande',
          score: '15',
          note: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt?',
        },
        {
          id: '0003',
          supplierName: 'Kollekten Inkasso AB',
          supplierId: '3',
          answer: 'Vi har 16 svenska 0 finska och 0 norska',
          score: '10',
          note: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt?',
        },
      ],
    },

    {
      completed: '1/2',
      category: 1,
      question: 'Hur många kunder har ni inom segmentet Jordbruk?',
      responses: [
        {
          id: '0001',
          supplierName: 'Inkasso & juridik AB',
          supplierId: '1',
          answer: 'Vi har 50 + kunder inom jordbrukssektorn',
          score: '15',
          note: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt?',
        },
        {
          id: '0003',
          supplierName: 'Kollekten Inkasso AB',
          supplierId: '3',
          answer: 'Vi har 500 + kunder inom jordbrukssektorn',
          score: '10',
          note: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt?',
        },
      ],
    },
    {
      completed: '1/2',
      category: 3,
      question: 'Har ni en 2 vägs identifiering?',
      responses: [
        {
          id: '0001',
          supplierName: 'Inkasso & juridik AB',
          supplierId: '1',
          answer: 'Vi har en användarvänlig portal ni kan fritt använda',
          score: '15',
          note: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt?',
        },
        {
          id: '0003',
          supplierName: 'Kollekten Inkasso AB',
          supplierId: '3',
          answer: 'Ja, vi tillhandahåller 2 vägs identifiering.',
          score: '10',
          note: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt?',
        },
      ],
    },
    {
      completed: '1/2',
      category: 4,
      question: 'Erbjuder ni en SaaS applikation där vi själva kan registrera ärenden?',
      responses: [
        {
          id: '0001',
          supplierName: 'Inkasso & juridik AB',
          supplierId: '1',
          answer: 'Vi har en användarvänlig portal ni kan fritt använda',
          score: '15',
          note: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt?',
        },
        {
          id: '0003',
          supplierName: 'Kollekten Inkasso AB',
          supplierId: '3',
          answer: 'Ni har tillgång till samma portal som vi använder',
          score: '10',
          note: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt?',
        },
      ],
    },
  ],
  documents: [
    {
      completed: '1/2',
      category: 1,
      responses: [
        {
          id: '0001',
          supplierName: 'Inkasso & juridik AB',
          supplierId: '1',
          answer: 'Inkasso upphandling.doc',
          score: '15',
          url: 'file',
          note: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt?',
        },

        {
          id: '0003',
          supplierName: 'Kollekten Inkasso AB',
          supplierId: '3',
          answer: 'Inkasso upphandling.doc',
          score: '10',
          url: 'file',
          note: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt?',
        },
      ],
    },
  ],
};
