// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RjWFfNRfekAe5zG0ETM3 {
  display: flex;
  height: 100%;
}

.PXaIeye3xs7rgMtJ3XCX {
  width: 30%;
  max-width: 35rem;
  min-width: 25rem;
  height: 100%;
  border-right: var(--primary-border);
}

.opaEqhV6tZEBnZ7EH3Ux {
  padding: 5rem;
  flex-grow: 1;
  background-color: var(--light-grey-100);
}`, "",{"version":3,"sources":["webpack://./src/components/5-pages/tenders/SettingsOverviewPage/SettingsOverviewPage.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,YAAA;AACF;;AAEA;EACE,UAAA;EACA,gBAAA;EACA,gBAAA;EACA,YAAA;EACA,mCAAA;AACF;;AAEA;EACE,aAAA;EACA,YAAA;EACA,uCAAA;AACF","sourcesContent":[".SettingsOverviewPage {\n  display: flex;\n  height: 100%;\n}\n\n.SettingsOverviewPage__settingsList {\n  width: 30%;\n  max-width: 35rem;\n  min-width: 25rem;\n  height: 100%;\n  border-right: var(--primary-border);\n}\n\n.SettingsOverviewPage__settingPage {\n  padding: 5rem;\n  flex-grow: 1;\n  background-color: var(--light-grey-100);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SettingsOverviewPage": `RjWFfNRfekAe5zG0ETM3`,
	"SettingsOverviewPage__settingsList": `PXaIeye3xs7rgMtJ3XCX`,
	"SettingsOverviewPage__settingPage": `opaEqhV6tZEBnZ7EH3Ux`
};
export default ___CSS_LOADER_EXPORT___;
