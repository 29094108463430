// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qR7hd_3esMNPPgNCZNV5 {
  display: flex;
  flex-direction: column;
}

.ZhowTo9BXzDbwB0bQKnt {
  margin-bottom: 1rem;
}

.QNo8kO0o0koXZWq1Oo6J {
  display: none;
}

.XmlYj_7hWHl5J8nalyA0 {
  background-color: var(--white);
  padding: 3.3rem;
  border: 2px dashed var(--light-grey-200);
  border-radius: 0.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 1.8rem;
}

.rnviBJov8zatYjkIHiRI {
  width: 4.5rem;
  height: 4.5rem;
}

.dEukOk1B3AoNgzPZkSow {
  color: var(--light-grey-400);
}

.vmG1RUYLcMnRBdDJEMZq {
  color: var(--accent-color);
}`, "",{"version":3,"sources":["webpack://./src/components/2-molecules/Form/FormFieldFileInput/FormFieldFileInput.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;AACF;;AAEA;EACE,mBAAA;AACF;;AAEA;EACE,aAAA;AACF;;AAEA;EACE,8BAAA;EACA,eAAA;EACA,wCAAA;EACA,qBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;EACA,eAAA;EACA,aAAA;EACA,sBAAA;EACA,WAAA;AACF;;AAEA;EACE,aAAA;EACA,cAAA;AACF;;AAEA;EACE,4BAAA;AACF;;AACA;EACE,0BAAA;AAEF","sourcesContent":[".FormFieldFileInput {\n  display: flex;\n  flex-direction: column;\n}\n\n.FormFieldFileInput__heading {\n  margin-bottom: 1rem;\n}\n\n.FormFieldFileInput__input {\n  display: none;\n}\n\n.FormFieldFileInput__preview {\n  background-color: var(--white);\n  padding: 3.3rem;\n  border: 2px dashed var(--light-grey-200);\n  border-radius: 0.6rem;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 100%;\n  cursor: pointer;\n  display: flex;\n  flex-direction: column;\n  gap: 1.8rem;\n}\n\n.FormFieldFileInput__icon {\n  width: 4.5rem;\n  height: 4.5rem;\n}\n\n.FormFieldFileInput__previewText {\n  color: var(--light-grey-400);\n}\n.FormFieldFileInput__browse {\n  color: var(--accent-color);\n}\n\n// TODO: Add Disabled State and Error State\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"FormFieldFileInput": `qR7hd_3esMNPPgNCZNV5`,
	"FormFieldFileInput__heading": `ZhowTo9BXzDbwB0bQKnt`,
	"FormFieldFileInput__input": `QNo8kO0o0koXZWq1Oo6J`,
	"FormFieldFileInput__preview": `XmlYj_7hWHl5J8nalyA0`,
	"FormFieldFileInput__icon": `rnviBJov8zatYjkIHiRI`,
	"FormFieldFileInput__previewText": `dEukOk1B3AoNgzPZkSow`,
	"FormFieldFileInput__browse": `vmG1RUYLcMnRBdDJEMZq`
};
export default ___CSS_LOADER_EXPORT___;
