import Cookies from 'js-cookie';

const storageName = 'reduxCookie';

export const loadStateFromCookie = () => {
  try {
    const serializedState = Cookies.get(storageName) || null;

    if (!serializedState) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (error) {
    return undefined;
  }
};

export const saveStateToCookie = (state: any, expirationDate?: Date) => {
  try {
    const serializedState = JSON.stringify(state);

    Cookies.set(storageName, serializedState, { expires: expirationDate });
  } catch (error) {
    console.log(error);
  }
};

export const clearCookie = () => {
  Cookies.remove(storageName);
};
