// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.yRph4X8Nei7Tdg5tC2S9 {
  width: 100%;
}

.Hcg5g_z1r1VWH_XWFHG_ {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.AVOuM_t89OQ9WGkUSxLQ {
  min-height: 53.2rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
}

.BYYKBsCyVgbMYtdAfpMg {
  margin-bottom: 2rem;
}`, "",{"version":3,"sources":["webpack://./src/components/3-organisms/TenderDetails/Tasks/Tasks.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;;AAEA;EACE,gBAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;AACF;;AAEA;EACE,mBAAA;EACA,aAAA;EACA,mBAAA;EACA,sBAAA;EACA,uBAAA;EACA,SAAA;AACF;;AAEA;EACE,mBAAA;AACF","sourcesContent":[".Tasks {\n  width: 100%;\n}\n\n.Tasks__list {\n  margin-top: 2rem;\n  display: flex;\n  flex-direction: column;\n  gap: 2rem;\n}\n\n.Tasks__emptyList {\n  min-height: 53.2rem;\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n  justify-content: center;\n  gap: 2rem;\n}\n\n.Tasks__emptyListHeading {\n  margin-bottom: 2rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Tasks": `yRph4X8Nei7Tdg5tC2S9`,
	"Tasks__list": `Hcg5g_z1r1VWH_XWFHG_`,
	"Tasks__emptyList": `AVOuM_t89OQ9WGkUSxLQ`,
	"Tasks__emptyListHeading": `BYYKBsCyVgbMYtdAfpMg`
};
export default ___CSS_LOADER_EXPORT___;
