// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NM5MzG7rAoYosHGKEjCt {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 4rem;
}

.PX_jpJ4FFkUPF7mT31k6 {
  display: flex;
  flex-direction: row;
  gap: 5rem;
}

.KHX_aZQ1rPqXmnbYcQfH {
  min-width: 17rem;
}

.fVoPZuln8OhDPTCYQIVW {
  text-align: left;
}

.N3ynKK6kEC6eqUwNRjUf {
  display: flex;
  justify-content: space-between;
  gap: 2.5%;
}

.fVoPZuln8OhDPTCYQIVW button {
  font-size: 16px;
}

.mUijsfXJLPx5JvOEpjjC {
  padding: 20px 0 0 30px;
}
.mUijsfXJLPx5JvOEpjjC h2 {
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  color: #2e9cca;
}

.X8erC2iuBzeVO8BI1OJ1 {
  width: 23%;
  border: 1px solid #bebeca;
  padding: 24px 29px;
}
.X8erC2iuBzeVO8BI1OJ1 p {
  margin-top: 10px;
}

.BFunclOoJIQgew437fCB {
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
}

.qBCKWxq6mAiCARimxY6Q {
  display: flex;
  flex-direction: row;
  padding: 20px 30px;
  border-bottom: 1px solid #bebeca;
}
.qBCKWxq6mAiCARimxY6Q h3 {
  width: 40%;
}
.qBCKWxq6mAiCARimxY6Q p {
  width: 60%;
}

.NtekSWsdXjj5Dl2WIa3f {
  text-align: center;
  margin-top: 50px;
}`, "",{"version":3,"sources":["webpack://./src/components/3-organisms/LeadDetails/LeadDetails.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;EACA,gBAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EAEA,SAAA;AAAF;;AAGA;EACE,gBAAA;AAAF;;AAGA;EACE,gBAAA;AAAF;;AAGA;EACE,aAAA;EACA,8BAAA;EACA,SAAA;AAAF;;AAIE;EACE,eAAA;AADJ;;AAKA;EACE,sBAAA;AAFF;AAIE;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,cAAA;AAFJ;;AAMA;EACE,UAAA;EACA,yBAAA;EACA,kBAAA;AAHF;AAKE;EACE,gBAAA;AAHJ;;AAOA;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;AAJF;;AAOA;EACE,aAAA;EACA,mBAAA;EACA,kBAAA;EACA,gCAAA;AAJF;AAME;EACE,UAAA;AAJJ;AAME;EACE,UAAA;AAJJ;;AAQA;EACE,kBAAA;EACA,gBAAA;AALF","sourcesContent":[".LeadDetails {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  gap: 2rem;\n  margin-top: 4rem;\n}\n\n.LeadDetails__dataRow {\n  display: flex;\n  flex-direction: row;\n  // align-items: center;\n  gap: 5rem;\n}\n\n.LeadDetails__rowHeading {\n  min-width: 17rem;\n}\n\n.LeadDetails__LeadDetailsBack {\n  text-align: left;\n}\n\n.LeadDetails__companyInfoRow {\n  display: flex;\n  justify-content: space-between;\n  gap: 2.5%;\n}\n\n.LeadDetails__LeadDetailsBack {\n  button {\n    font-size: 16px;\n  }\n}\n\n.LeadDetails_Heading2 {\n  padding: 20px 0 0 30px;\n\n  h2 {\n    font-size: 18px;\n    font-weight: 700;\n    line-height: 22px;\n    color: #2e9cca;\n  }\n}\n\n.LeadDetails__companyInfoBox {\n  width: 23%;\n  border: 1px solid #bebeca;\n  padding: 24px 29px;\n\n  p {\n    margin-top: 10px;\n  }\n}\n\n.Details__infoItemHeading {\n  font-size: 18px;\n  font-weight: 700;\n  line-height: 22px;\n}\n\n.LeadDetails__leadInfoItem {\n  display: flex;\n  flex-direction: row;\n  padding: 20px 30px;\n  border-bottom: 1px solid #bebeca;\n\n  h3 {\n    width: 40%;\n  }\n  p {\n    width: 60%;\n  }\n}\n\n.LeadDetails__leadButtonRow {\n  text-align: center;\n  margin-top: 50px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"LeadDetails": `NM5MzG7rAoYosHGKEjCt`,
	"LeadDetails__dataRow": `PX_jpJ4FFkUPF7mT31k6`,
	"LeadDetails__rowHeading": `KHX_aZQ1rPqXmnbYcQfH`,
	"LeadDetails__LeadDetailsBack": `fVoPZuln8OhDPTCYQIVW`,
	"LeadDetails__companyInfoRow": `N3ynKK6kEC6eqUwNRjUf`,
	"LeadDetails_Heading2": `mUijsfXJLPx5JvOEpjjC`,
	"LeadDetails__companyInfoBox": `X8erC2iuBzeVO8BI1OJ1`,
	"Details__infoItemHeading": `BFunclOoJIQgew437fCB`,
	"LeadDetails__leadInfoItem": `qBCKWxq6mAiCARimxY6Q`,
	"LeadDetails__leadButtonRow": `NtekSWsdXjj5Dl2WIa3f`
};
export default ___CSS_LOADER_EXPORT___;
