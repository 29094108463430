// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qIydRP5VUha_qAaUSObL {
  border-radius: 0.6rem;
  border: var(--primary-border);
  background: var(--white);
  box-shadow: var(--primary-shadow);
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/2-molecules/Cards/ActionCard/ActionCard.module.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;EACA,6BAAA;EACA,wBAAA;EACA,iCAAA;EACA,eAAA;AACF","sourcesContent":[".ActionCard {\n  border-radius: 0.6rem;\n  border: var(--primary-border);\n  background: var(--white);\n  box-shadow: var(--primary-shadow);\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ActionCard": `qIydRP5VUha_qAaUSObL`
};
export default ___CSS_LOADER_EXPORT___;
