// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rtQZbDHlNfDwa7cwFR5G {
  display: flex;
  flex-flow: column;
  gap: 2.4rem;
  padding: 7rem 8rem;
  justify-content: center;
}

.nyNxXXG1N_vRgh9DSeBW {
  align-self: center;
  margin-top: 4rem;
}`, "",{"version":3,"sources":["webpack://./src/components/3-organisms/ContractsOverview/CreateSupplierForm/CreateSupplierForm.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,iBAAA;EACA,WAAA;EACA,kBAAA;EACA,uBAAA;AACF;;AAEA;EACE,kBAAA;EACA,gBAAA;AACF","sourcesContent":[".CreateSupplierForm {\n  display: flex;\n  flex-flow: column;\n  gap: 2.4rem;\n  padding: 7rem 8rem;\n  justify-content: center;\n}\n\n.ChallengeContractForm__submitButton {\n  align-self: center;\n  margin-top: 4rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CreateSupplierForm": `rtQZbDHlNfDwa7cwFR5G`,
	"ChallengeContractForm__submitButton": `nyNxXXG1N_vRgh9DSeBW`
};
export default ___CSS_LOADER_EXPORT___;
