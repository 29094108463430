// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AYmci5TgLG1qUvdbtgru {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 80%;
}

.UH2nW6yjItC4LfePjCdQ {
  min-width: 15rem;
}

.xHcLWn6ZIAm1YCl6WLiQ {
  color: var(--dark-grey-600);
}

.CCqGuhZyDnSkCmAzIfdO {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  padding-bottom: 4rem;
}

.c7Pvk5oN9V1LdmA8x0gZ {
  border: none;
}

.jPRDjWa7gP2By8JbjZl3 th:first-child {
  flex: 2;
}
.jPRDjWa7gP2By8JbjZl3 td:nth-child(odd) {
  flex: 2;
}`, "",{"version":3,"sources":["webpack://./src/components/3-organisms/TenderDetails/Details/PhaseAccordion/PhaseAccordion.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,8BAAA;EACA,UAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE,2BAAA;AACF;;AAEA;EACE,aAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;EACA,oBAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAGE;EACE,OAAA;AAAJ;AAGE;EACE,OAAA;AADJ","sourcesContent":[".PhaseAccordion__headingWrapper {\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: space-between;\n  width: 80%;\n}\n\n.PhaseAccordion__phaseName {\n  min-width: 15rem;\n}\n\n.PhaseAccordion__heading {\n  color: var(--dark-grey-600);\n}\n\n.PhaseAccordion__contentWrapper {\n  padding: 2rem;\n  display: flex;\n  flex-direction: column;\n  gap: 4rem;\n  padding-bottom: 4rem;\n}\n\n.PhaseAccordion__dataTable {\n  border: none;\n}\n\n.PhaseAccordion__dataTable_questions {\n  th:first-child {\n    flex: 2;\n  }\n\n  td:nth-child(odd) {\n    flex: 2;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"PhaseAccordion__headingWrapper": `AYmci5TgLG1qUvdbtgru`,
	"PhaseAccordion__phaseName": `UH2nW6yjItC4LfePjCdQ`,
	"PhaseAccordion__heading": `xHcLWn6ZIAm1YCl6WLiQ`,
	"PhaseAccordion__contentWrapper": `CCqGuhZyDnSkCmAzIfdO`,
	"PhaseAccordion__dataTable": `c7Pvk5oN9V1LdmA8x0gZ`,
	"PhaseAccordion__dataTable_questions": `jPRDjWa7gP2By8JbjZl3`
};
export default ___CSS_LOADER_EXPORT___;
