// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rUc7GZcCndDJNcXy5U_X {
  width: 100%;
  display: flex;
  gap: 4rem;
  flex-direction: column;
  padding: 4rem 0;
}

.SrrcrpbR_mpWVvaqQmlO {
  border-radius: 0.6rem;
  border: var(--primary-border);
  overflow: hidden;
}

.RXXqZQjFcCKeJBDuPNDq {
  display: flex;
  justify-content: flex-start;
}

._ZJOLm2fwDILKkzaKJej {
  rotate: 90deg;
}

.MjMJpoD0vb7naxNMp_7b {
  display: flex;
  justify-content: space-between;
  background-color: var(--light-grey-150);
  padding: 2rem;
  margin-bottom: 2rem;
  border-bottom: var(--primary-border);
  border-radius: 0.6rem;
}

.jC5460f8Unkom8LKcJVn {
  display: flex;
  align-items: center;
  gap: 8rem;
  flex-flow: row;
}
.jC5460f8Unkom8LKcJVn h3 {
  color: var(--dark-grey-600);
}

.HdGkA3z3xSIBgjdjzddt {
  color: var(--secondary-color);
}`, "",{"version":3,"sources":["webpack://./src/components/3-organisms/TenderDetails/Responses/ResponsesCompare/ResponsesCompare.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,SAAA;EACA,sBAAA;EACA,eAAA;AACF;;AAEA;EACE,qBAAA;EACA,6BAAA;EACA,gBAAA;AACF;;AAEA;EACE,aAAA;EACA,2BAAA;AACF;;AACA;EACE,aAAA;AAEF;;AACA;EACE,aAAA;EACA,8BAAA;EACA,uCAAA;EACA,aAAA;EACA,mBAAA;EACA,oCAAA;EACA,qBAAA;AAEF;;AACA;EACE,aAAA;EACA,mBAAA;EACA,SAAA;EACA,cAAA;AAEF;AADE;EACE,2BAAA;AAGJ;;AACA;EACE,6BAAA;AAEF","sourcesContent":[".ResponsesCompare {\n  width: 100%;\n  display: flex;\n  gap: 4rem;\n  flex-direction: column;\n  padding: 4rem 0;\n}\n\n.ResponsesCompare__card {\n  border-radius: 0.6rem;\n  border: var(--primary-border);\n  overflow: hidden;\n}\n\n.ResponsesCompare__backButton {\n  display: flex;\n  justify-content: flex-start;\n}\n.ResponsesCompare__arrowIcon {\n  rotate: 90deg;\n}\n\n.ResponsesCompare__cardHeading {\n  display: flex;\n  justify-content: space-between;\n  background-color: var(--light-grey-150);\n  padding: 2rem;\n  margin-bottom: 2rem;\n  border-bottom: var(--primary-border);\n  border-radius: 0.6rem;\n}\n\n.ResponsesCompare__cardStatus {\n  display: flex;\n  align-items: center;\n  gap: 8rem;\n  flex-flow: row;\n  h3 {\n    color: var(--dark-grey-600);\n  }\n}\n\n.ResponsesCompare__accordionTitle {\n  color: var(--secondary-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ResponsesCompare": `rUc7GZcCndDJNcXy5U_X`,
	"ResponsesCompare__card": `SrrcrpbR_mpWVvaqQmlO`,
	"ResponsesCompare__backButton": `RXXqZQjFcCKeJBDuPNDq`,
	"ResponsesCompare__arrowIcon": `_ZJOLm2fwDILKkzaKJej`,
	"ResponsesCompare__cardHeading": `MjMJpoD0vb7naxNMp_7b`,
	"ResponsesCompare__cardStatus": `jC5460f8Unkom8LKcJVn`,
	"ResponsesCompare__accordionTitle": `HdGkA3z3xSIBgjdjzddt`
};
export default ___CSS_LOADER_EXPORT___;
