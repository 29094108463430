import { useMemo } from 'react';
import classNames from 'classnames';
import { ColumnDef } from '@tanstack/react-table';
import { AnchorLink, Heading, StatusText, TextBox, RatingStar } from '@/components/1-atoms';
import { ContentCard, DataTable, Dropdown } from '@/components/2-molecules';
import { ReactComponent as MenuIcon } from '@/assets/Icons/icon-menu.svg';

import styles from './SuppliersOverview.module.scss';

export interface SuppliersOverviewProps {
  className?: string;
  suppliersList?: Supplier[];
}

export const SuppliersOverview = ({ className, suppliersList }: SuppliersOverviewProps) => {
  const handleOptionClicked = (value: string) => {
    console.log(value);
  };

  const columns = useMemo<ColumnDef<Supplier>[]>(
    () => [
      {
        header: 'Name',
        accessorKey: 'name',
      },
      {
        header: 'INDUSTRY',
        accessorKey: 'industry',
      },
      {
        header: 'ORG.NO.',
        accessorKey: 'organizationNumber',
      },
      {
        header: 'ACTIVE CONTRACTS',
        accessorKey: 'activeContracts',
      },
      {
        header: 'RATING',
        cell: (table: any) => {
          const rating = table.row.original.rating;

          return <RatingStar rating={rating} />;
        },
      },
      {
        header: 'STATUS',
        cell: (table: any) => {
          const status = table.row.original.status;

          switch (status) {
            case 'Inactive':
              return <StatusText status={'inactive'} text={status} />;
            case 'Active':
              return <StatusText status={'active'} text={status} />;
          }
        },
      },
      {
        header: 'ACTIONS',
        cell: () => {
          return (
            <Dropdown
              className={styles.SuppliersOverview__actionsButton}
              options={[
                { value: 'viewSupplier', label: 'View supplier' },
                { value: 'editSupplier', label: 'Edit supplier' },
                { value: 'deleteSupplier', label: 'Delete supplier' },
                { value: 'rateSupplier', label: 'Rate supplier' },
              ]}
              onChange={handleOptionClicked}
            >
              <MenuIcon />
            </Dropdown>
          );
        },
      },
    ],
    [],
  );

  return (
    <div className={classNames(styles.SuppliersOverview, className)}>
      <ContentCard heading={<Heading headingLevel={'h2'}>Suppliers</Heading>}>
        <DataTable columns={columns} data={suppliersList || []} />

        {!suppliersList?.length && (
          <div className={styles.SuppliersOverview__emptyList}>
            <TextBox
              title={
                <Heading headingLevel={'h3'} className={styles.SuppliersOverview__emptyListHeading}>
                  There are no suppliers for this company.
                </Heading>
              }
              description={
                <>
                  Not sure how to get started? Try{' '}
                  <AnchorLink link={''}>
                    <>
                      watching our video <br /> guide
                    </>
                  </AnchorLink>{' '}
                  or <AnchorLink link={''}> send as a question. </AnchorLink>
                </>
              }
              alignment={'centre'}
            />
          </div>
        )}
      </ContentCard>
    </div>
  );
};
