import { LoginType } from '@/constants/loginTypes';
import { useAppDispatch, useAppSelector } from '@/store';
import { getProfile as getBuyerProfile } from '@/store/slices/buyerProfileSlice';
import { getProfile as getSupplierProfile } from '@/store/slices/supplierProfileSlice';

export const useCustomerProfile = () => {
  const dispatch = useAppDispatch();
  const customerType = useAppSelector((state) => state?.user?.loginProfile?.version);

  const buyerProfile = useAppSelector((state) => state?.buyerProfile);
  const supplierProfile = useAppSelector((state) => state?.supplierProfile);

  const dispatchCustomerProfile = () => {
    switch (customerType) {
      case LoginType.Buyer:
        dispatch(getBuyerProfile());
        break;
      case LoginType.Supplier:
        dispatch(getSupplierProfile());
        break;
      default:
        break;
    }
  };

  const selectCustomerProfile = () => {
    switch (customerType) {
      case LoginType.Buyer:
        return buyerProfile;
      case LoginType.Supplier:
        return supplierProfile;
      default:
        return undefined;
    }
  };

  const isBuyerProfile = (profile: any): profile is BuyerProfileOverview => {
    return (profile as BuyerProfileOverview)?.supplierOverview !== undefined;
  };

  const isSupplierProfile = (profile: any): profile is SupplierProfileOverview => {
    return (profile as SupplierProfileOverview)?.leadOverview !== undefined;
  };

  return {
    selectCustomerProfile,
    dispatchCustomerProfile,
    isBuyerProfile,
    isSupplierProfile,
    customerType,
  };
};
