import classNames from 'classnames';
import React from 'react';
import styles from './Heading.module.scss';

export type HeadingLevel = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

export interface HeadingProps {
  className?: string;
  headingLevel: HeadingLevel;
  children: React.ReactNode;
  id?: string;
}

export const Heading = ({ className, children, headingLevel, id }: HeadingProps) =>
  React.createElement(
    headingLevel,
    { id: id, className: classNames(styles.Heading, className) },
    children,
  );
