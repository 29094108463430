// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.By5_SRk8McgdJMlkMhvo {
  max-width: 100rem;
  width: 60vw;
  display: flex;
  flex-flow: column;
  gap: 4rem;
}

.G3l_JMJg6WPj56PR5Opn {
  margin-bottom: 4rem;
}

.q0NMvckAplDdgcSO9oin {
  margin-bottom: 4rem;
}

.KVU2Ayff1JLf4WkP931v {
  display: flex;
  width: 100%;
  gap: 4rem;
}
.KVU2Ayff1JLf4WkP931v:nth-of-type(2) {
  position: relative;
}
.KVU2Ayff1JLf4WkP931v:nth-of-type(2)::after {
  content: "";
  width: 100%;
  border-top: 2px dashed var(--light-grey-200);
  position: absolute;
  bottom: -2rem;
}

.CUw50qXNZZp6zrm3qRFw {
  align-self: center;
}`, "",{"version":3,"sources":["webpack://./src/components/3-organisms/DashBoard/CreateOrganisationForm/UpdateOrganisationStep/UpdateOrganisationStep.module.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,WAAA;EACA,aAAA;EACA,iBAAA;EACA,SAAA;AACF;;AAEA;EACE,mBAAA;AACF;;AAEA;EACE,mBAAA;AACF;;AAEA;EACE,aAAA;EACA,WAAA;EACA,SAAA;AACF;AACE;EACE,kBAAA;AACJ;AAAI;EACE,WAAA;EACA,WAAA;EACA,4CAAA;EACA,kBAAA;EACA,aAAA;AAEN;;AAGA;EACE,kBAAA;AAAF","sourcesContent":[".UpdateOrganisationStep {\n  max-width: 100rem;\n  width: 60vw;\n  display: flex;\n  flex-flow: column;\n  gap: 4rem;\n}\n\n.UpdateOrganisationStep__pageTitle {\n  margin-bottom: 4rem;\n}\n\n.UpdateOrganisationStep__avatarInput {\n  margin-bottom: 4rem;\n}\n\n.UpdateOrganisationStep__row {\n  display: flex;\n  width: 100%;\n  gap: 4rem;\n\n  &:nth-of-type(2) {\n    position: relative;\n    &::after {\n      content: '';\n      width: 100%;\n      border-top: 2px dashed var(--light-grey-200);\n      position: absolute;\n      bottom: -2rem;\n    }\n  }\n}\n\n.UpdateOrganisationStep__submitBtn {\n  align-self: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"UpdateOrganisationStep": `By5_SRk8McgdJMlkMhvo`,
	"UpdateOrganisationStep__pageTitle": `G3l_JMJg6WPj56PR5Opn`,
	"UpdateOrganisationStep__avatarInput": `q0NMvckAplDdgcSO9oin`,
	"UpdateOrganisationStep__row": `KVU2Ayff1JLf4WkP931v`,
	"UpdateOrganisationStep__submitBtn": `CUw50qXNZZp6zrm3qRFw`
};
export default ___CSS_LOADER_EXPORT___;
