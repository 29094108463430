import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import classNames from 'classnames';

import { FormFieldCta, FormFieldRadio, FormFieldSelect } from '@/components/2-molecules/Form';
import { FormFieldInput, TextInfo } from '@/components/2-molecules';
import { Heading, TextArea } from '@/components/1-atoms';

import styles from './CreateTenderDetails.module.scss';
import { useAppDispatch, useAppSelector } from '@/store';
import { setError, setLoading } from '@/store/slices/PageStatusSlice';
import { createTender, updateTender } from '@/store/slices/createTenderSlice';

export interface CreateTenderDetailsProps {
  className?: string;
  defaultTender?: TenderForm;
  moveToNextPage: () => void;
}

export const CreateTenderDetails = ({
  className,
  defaultTender,
  moveToNextPage,
}: CreateTenderDetailsProps) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<TenderForm>({
    defaultValues: defaultTender,
  });

  const dispatch = useAppDispatch();

  const title = 'Setup the parameters for your tender';

  const description = `Lorem ipsum dolor sit amet,
  consectetur adipiscing elit, sed do eiusmod tempor.
  Ut enim ad minim veniam, quis nostrud exercitation`;

  const { tenderTypeOptions } =
    useAppSelector((state) => state.buyerProfile.tenderOverview?.tenderCreate) ?? {};

  const tenderTypes = tenderTypeOptions?.map((option) => ({
    label: option.name,
    value: option.id,
  }));

  // TODO: need to fix with backend
  const visibilityOptions = [
    { label: 'Yes', value: 'yes' },
    { label: 'No', value: 'no' },
  ];

  const onSubmit: SubmitHandler<TenderForm> = async (data: TenderForm) => {
    const tenderIdExist = data?.tenderId ? true : false;

    dispatch(setLoading(true));
    let requestStatus = '';

    if (tenderIdExist) {
      const response = await dispatch(updateTender(data));
      requestStatus = response?.meta?.requestStatus;
    }

    if (!tenderIdExist) {
      const response = await dispatch(createTender(data));
      requestStatus = response?.meta?.requestStatus;
    }

    dispatch(setLoading(false));

    if (requestStatus == 'fulfilled') {
      moveToNextPage();
    }

    if (requestStatus == 'rejected') {
      () => dispatch(setError(true));
    }
  };

  return (
    <div className={classNames(styles.CreateTenderDetails, className)}>
      <form className={styles.CreateTenderDetailsForm} onSubmit={handleSubmit(onSubmit)}>
        <TextInfo headingLevel='h3' title={title} description={description} />

        <Controller
          control={control}
          name={'tenderTypeId'}
          render={({ field: { onChange, value } }) => (
            <FormFieldSelect
              className={styles.CreateTenderDetailsForm__select}
              id='type'
              label='Tender type'
              placeholder='Tender type'
              options={tenderTypes || []}
              onChange={onChange}
              state={{ required: true }}
              defaultValue={value && value}
            />
          )}
        />

        <FormFieldInput
          className={styles.CreateTenderDetailsForm__input}
          id={'name'}
          label={'Tender name'}
          type={'text'}
          name={'tenderName'}
          register={register}
          state={{ required: true }}
        />

        <div>
          <Heading
            headingLevel='h3'
            id={'description'}
            className={styles.CreateTenderDetailsForm__textAreaTitle}
          >
            Please describe your needs for this tender
          </Heading>

          <Controller
            control={control}
            name={'tenderDescription'}
            render={({ field: { onChange, value } }) => (
              <TextArea
                id={'tenderDescription'}
                name='tenderDescription'
                className={styles.CreateTenderDetailsForm__textArea}
                rows={4}
                required
                onChange={onChange}
                defaultValue={value}
              />
            )}
          />
        </div>

        <Controller
          control={control}
          name={'isAnonymous'}
          render={({ field: { onChange, value } }) => (
            <FormFieldRadio
              title='Do you want to be anonymous for this tender?'
              name='anonymousRadio'
              options={visibilityOptions || []}
              defaultSelectedValue={value ? 'yes' : 'no'}
              onChange={(value) => (value === 'yes' ? onChange(true) : onChange(false))}
              state={{ required: true }}
            />
          )}
        />

        <FormFieldCta
          type='submit'
          id={'next'}
          label={'Next'}
          className={styles.CreateTenderDetailsForm__cta}
        />
      </form>
    </div>
  );
};
