// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mzYcw6R7nxQLg666_TCI {
  display: flex;
  flex-flow: column;
  list-style-type: none;
  position: relative;
}`, "",{"version":3,"sources":["webpack://./src/components/3-organisms/SettingsOverview/SettingsList/SettingsList.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,iBAAA;EACA,qBAAA;EACA,kBAAA;AACF","sourcesContent":[".SettingsList {\n  display: flex;\n  flex-flow: column;\n  list-style-type: none;\n  position: relative;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SettingsList": `mzYcw6R7nxQLg666_TCI`
};
export default ___CSS_LOADER_EXPORT___;
