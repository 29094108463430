// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RYMQ3WeG7kIfEvANAJVT {
  display: block;
  position: relative;
  padding-left: 3.5rem;
  margin-bottom: 1.2rem;
  cursor: pointer;
  font-size: 2.2rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.TEu2ZGKzSQUsYnADa989 {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.TEu2ZGKzSQUsYnADa989:checked ~ .a5ybMlAxzqDxBwdMd9bR {
  background-color: var(--accent-color);
  border-color: transparent;
}
.TEu2ZGKzSQUsYnADa989:checked ~ .a5ybMlAxzqDxBwdMd9bR::after {
  display: block;
}

.a5ybMlAxzqDxBwdMd9bR {
  position: absolute;
  top: 0;
  left: 0;
  height: 1.8rem;
  width: 1.8rem;
  background-color: transparent;
  border-radius: 0.5rem;
  border: 1px solid var(--dark-grey-600);
}
.a5ybMlAxzqDxBwdMd9bR::after {
  content: "";
  position: absolute;
  left: 0.6rem;
  top: 0.3rem;
  width: 0.4rem;
  height: 0.8rem;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}`, "",{"version":3,"sources":["webpack://./src/components/2-molecules/Form/FormFieldCheckbox/FormFieldCheckbox.module.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,kBAAA;EACA,oBAAA;EACA,qBAAA;EACA,eAAA;EACA,iBAAA;EACA,yBAAA;EACA,sBAAA;EACA,qBAAA;EACA,iBAAA;AACF;;AAEA;EACE,kBAAA;EACA,UAAA;EACA,eAAA;EACA,SAAA;EACA,QAAA;AACF;AACE;EACE,qCAAA;EACA,yBAAA;AACJ;AAAI;EACE,cAAA;AAEN;;AAGA;EACE,kBAAA;EACA,MAAA;EACA,OAAA;EACA,cAAA;EACA,aAAA;EACA,6BAAA;EACA,qBAAA;EACA,sCAAA;AAAF;AAEE;EACE,WAAA;EACA,kBAAA;EACA,YAAA;EACA,WAAA;EACA,aAAA;EACA,cAAA;EACA,mBAAA;EACA,yBAAA;EACA,gCAAA;EACA,4BAAA;EACA,wBAAA;AAAJ","sourcesContent":[".FormFieldCheckbox {\n  display: block;\n  position: relative;\n  padding-left: 3.5rem;\n  margin-bottom: 1.2rem;\n  cursor: pointer;\n  font-size: 2.2rem;\n  -webkit-user-select: none;\n  -moz-user-select: none;\n  -ms-user-select: none;\n  user-select: none;\n}\n\n.FormFieldCheckbox__input {\n  position: absolute;\n  opacity: 0;\n  cursor: pointer;\n  height: 0;\n  width: 0;\n\n  &:checked ~ .FormFieldCheckbox__checkMark {\n    background-color: var(--accent-color);\n    border-color: transparent;\n    &::after {\n      display: block;\n    }\n  }\n}\n\n.FormFieldCheckbox__checkMark {\n  position: absolute;\n  top: 0;\n  left: 0;\n  height: 1.8rem;\n  width: 1.8rem;\n  background-color: transparent;\n  border-radius: 0.5rem;\n  border: 1px solid var(--dark-grey-600);\n\n  &::after {\n    content: '';\n    position: absolute;\n    left: 0.6rem;\n    top: 0.3rem;\n    width: 0.4rem;\n    height: 0.8rem;\n    border: solid white;\n    border-width: 0 2px 2px 0;\n    -webkit-transform: rotate(45deg);\n    -ms-transform: rotate(45deg);\n    transform: rotate(45deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"FormFieldCheckbox": `RYMQ3WeG7kIfEvANAJVT`,
	"FormFieldCheckbox__input": `TEu2ZGKzSQUsYnADa989`,
	"FormFieldCheckbox__checkMark": `a5ybMlAxzqDxBwdMd9bR`
};
export default ___CSS_LOADER_EXPORT___;
