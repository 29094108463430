import classNames from 'classnames';
import { PhaseForm } from './../PhaseForm';
import styles from './CreateTenderPhasesList.module.scss';
import { Button, FormFieldCta } from '@/components/2-molecules';
import { useForm, useFieldArray, FormProvider } from 'react-hook-form';
import { deletePhase, setTender } from '@/store/slices/createTenderSlice';
import { useAppDispatch } from '@/store';
import { useEffect } from 'react';
export interface CreateTenderPhasesListProps {
  className?: string;
  moveToNextPage: () => void;
  moveToPreviousPage: () => void;
  defaultTender?: TenderForm;
}

export const CreateTenderPhasesList = ({
  className,
  moveToNextPage,
  moveToPreviousPage,
  defaultTender,
}: CreateTenderPhasesListProps) => {
  const fieldArrayName = 'tenderPhases';

  const dispatch = useAppDispatch();

  const methods = useForm<TenderForm>({
    defaultValues: defaultTender,
  });
  const { control, handleSubmit, getValues } = methods;

  const { fields, append, remove } = useFieldArray({
    control,
    name: fieldArrayName,
  });

  const checkQuestionAndDocumentAdded = () => {
    const firstQuestion = getValues(`${fieldArrayName}.0.tenderQuestions.0`);
    const firstDocument = getValues(`${fieldArrayName}.0.documents.0`);
    return firstQuestion && firstDocument ? true : false;
  };

  const addPhase = () => {
    append({
      // order: fields.length + 1,
      phaseName: '',
      startDate: '',
      endDate: '',
      isMandatory: true,
      phaseVisabilityId: 1,
      documents: [],
      tenderQuestions: [],
    });
  };

  const removePhase = (index: number) => {
    const phaseId = getValues(`${fieldArrayName}.${index}.phaseId`);
    remove(index);
    if (!phaseId) return;
    dispatch(deletePhase(phaseId));
  };

  useEffect(() => {
    if (defaultTender && defaultTender?.tenderPhases?.length <= 0) {
      addPhase();
    }
  }, []);

  const onSubmit = (data: TenderForm) => {
    // console.log('first document ', checkQuestionAndDocumentAdded());
    // if (checkQuestionAndDocumentAdded()) {
    //   console.log(' submitted data', data);
    //   submitTender(data);
    //   moveToNext();
    // }
    dispatch(setTender(data));
    console.log(' submitted data', data);
    moveToNextPage();
  };

  return (
    <div className={classNames(styles.PhasesList, className)}>
      <FormProvider {...methods}>
        <form className={styles.PhasesList__form} onSubmit={handleSubmit(onSubmit)}>
          {fields.map((field, index) => (
            <PhaseForm
              key={field.id}
              phaseDefaultTitle={`Phase ${index + 1}`}
              phasesArrayName={fieldArrayName}
              index={index}
              removePhase={() => removePhase(index)}
            />
          ))}

          <Button
            type={'primaryColor'}
            className={styles.PhasesList__addPhase}
            onClick={() => addPhase()}
          >
            Add new phase
          </Button>
          <div className={styles.PhasesList__ctaWrapper}>
            <Button type={'secondary'} onClick={() => moveToPreviousPage()}>
              Back
            </Button>

            <FormFieldCta type='submit' id={'save'} label={'Next'} />
          </div>
        </form>
      </FormProvider>
    </div>
  );
};
