import { ChallengeContractForm, ContractsOverview } from '@/components/3-organisms';
import { LeadsLayout } from '@/components/4-templates';
import { useAppDispatch, useAppSelector } from '@/store';
import { setTitle } from '@/store/slices/headerSlice';

import styles from './ContractsOverviewPage.module.scss';
import { useEffect } from 'react';
import { getContractsList } from '@/store/slices/contractsSlice';
import { Route, Routes, useLocation } from 'react-router-dom';

export const ContractsOverviewPage = () => {
  const dispatch = useAppDispatch();
  const contractsList = useAppSelector((state) => state.contracts?.contractsList);
  const location = useLocation();

  const currentPage = location.pathname.endsWith('/challenge-contract')
    ? 'challengeContract'
    : location.pathname.endsWith('/add-new-contract')
    ? 'newContract'
    : 'contracts';

  useEffect(() => {
    dispatch(
      setTitle(
        currentPage === 'challengeContract'
          ? 'Challenge contract'
          : currentPage === 'newContract'
          ? 'Add new contract'
          : 'contracts',
      ),
    );
    dispatch(getContractsList());
  }, [currentPage]);

  return (
    <LeadsLayout>
      <section className={styles.ContractsOverviewPage}>
        <Routes>
          <Route path='/' element={<ContractsOverview contractsList={contractsList} />} />
        </Routes>
      </section>
    </LeadsLayout>
  );
};
