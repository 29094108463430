import classNames from 'classnames';
import styles from './ActionCard.module.scss';

export interface ActionCardProps {
  onClick: () => void;
  children: React.ReactNode;
  className?: string;
}

export const ActionCard = ({ onClick, children, className }: ActionCardProps) => {
  return (
    <div onClick={onClick} className={classNames(styles.ActionCard, className)}>
      {children}
    </div>
  );
};
