import classNames from 'classnames';
import styles from './Paragraph.module.scss';

export interface ParagraphProps {
  className?: string;
  children: string | JSX.Element | React.ReactNode;
}

export const Paragraph = ({ className, children }: ParagraphProps) => (
  <p className={classNames(styles.Paragraph, className)}>{children}</p>
);
