// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PFU6VAeGxg4DM1Jd3yYA {
  display: flex;
  flex-direction: row;
  width: 100%;
  border: 1px solid var(--white);
  border-radius: 0.6rem;
  overflow: hidden;
}

.lehkijeY5OMpQjkvkjFV {
  border-radius: 0.6rem;
}

.wlwFds9AnMsJoPkbJWxE {
  gap: 2rem;
}

.UuarSXB5IUszR86vInie {
  padding: 1rem;
  border: unset;
  font-size: var(--font-size--md);
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  flex: 1 1 0;
  width: 0;
  font-family: var(--font-secondary);
  font-weight: var(--font-weight--normal);
}`, "",{"version":3,"sources":["webpack://./src/components/2-molecules/Filters/Filters.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,WAAA;EACA,8BAAA;EACA,qBAAA;EACA,gBAAA;AACF;;AAEA;EACE,qBAAA;AACF;;AAEA;EACE,SAAA;AACF;;AAEA;EACE,aAAA;EACA,aAAA;EACA,+BAAA;EACA,eAAA;EACA,gCAAA;EACA,WAAA;EACA,QAAA;EACA,kCAAA;EACA,uCAAA;AACF","sourcesContent":[".Filters {\n  display: flex;\n  flex-direction: row;\n  width: 100%;\n  border: 1px solid var(--white);\n  border-radius: 0.6rem;\n  overflow: hidden;\n}\n\n.Filters_grouped {\n  border-radius: 0.6rem;\n}\n\n.Filters_rounded {\n  gap: 2rem;\n}\n\n.Filters__button {\n  padding: 1rem;\n  border: unset;\n  font-size: var(--font-size--md);\n  cursor: pointer;\n  transition: all 0.2s ease-in-out;\n  flex: 1 1 0;\n  width: 0;\n  font-family: var(--font-secondary);\n  font-weight: var(--font-weight--normal);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Filters": `PFU6VAeGxg4DM1Jd3yYA`,
	"Filters_grouped": `lehkijeY5OMpQjkvkjFV`,
	"Filters_rounded": `wlwFds9AnMsJoPkbJWxE`,
	"Filters__button": `UuarSXB5IUszR86vInie`
};
export default ___CSS_LOADER_EXPORT___;
