// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Dg8eEsAkpj66FtRCDua4 {
  display: flex;
  width: 100%;
  margin-right: 7rem;
  gap: 8rem;
  align-items: center;
}

.w6BPzRqbpUm79fuvNSTk {
  color: var(--dark-grey-600);
}`, "",{"version":3,"sources":["webpack://./src/components/3-organisms/CreateTender/PhaseForm/PhasesHeading/PhasesHeading.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,WAAA;EACA,kBAAA;EACA,SAAA;EACA,mBAAA;AACF;;AAEA;EACE,2BAAA;AACF","sourcesContent":[".PhasesHeading {\n  display: flex;\n  width: 100%;\n  margin-right: 7rem;\n  gap: 8rem;\n  align-items: center;\n}\n\n.PhasesHeading__param {\n  color: var(--dark-grey-600);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"PhasesHeading": `Dg8eEsAkpj66FtRCDua4`,
	"PhasesHeading__param": `w6BPzRqbpUm79fuvNSTk`
};
export default ___CSS_LOADER_EXPORT___;
