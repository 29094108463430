import classNames from 'classnames';
import styles from './TenderContract.module.scss';
import { ContractItem } from './ContractItem';
import { Button } from '@/components/2-molecules';

export interface TenderContractProps {
  className?: string;
  tenderContracts?: TenderContract[];
}

export const TenderContract = ({ className, tenderContracts }: TenderContractProps) => {
  return (
    <div className={classNames(styles.TenderContract, className)}>
      <Button className={styles.TenderContract__uploadButton} type={'primaryColor'}>
        Upload old contract
      </Button>
      <div className={styles.TenderContract__list}>
        {tenderContracts &&
          tenderContracts.map((contract) => (
            <ContractItem
              key={contract.id}
              className={styles.ContractItem}
              tenderContract={contract}
            />
          ))}
      </div>
    </div>
  );
};
