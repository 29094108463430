import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { Heading, TextArea } from '@/components/1-atoms';
import { FormFieldInput, FormFieldCta, FormFieldSelect } from '@/components/2-molecules';
import { ReactComponent as BuildingIcon } from '@/assets/Icons/icon-building.svg';
import { ReactComponent as CompanyDetailsIcon } from '@/assets/Icons/icon-company-details.svg';
import { ReactComponent as FactoryIcon } from '@/assets/Icons/icon-factory.svg';
import { ReactComponent as PersonIcon } from '@/assets/Icons/icon-person-profile.svg';
import { ReactComponent as MailIcon } from '@/assets/Icons/icon-mail.svg';
import { ReactComponent as PhoneIcon } from '@/assets/Icons/icon-phone.svg';
import { ReactComponent as AddressIcon } from '@/assets/Icons/icon-address.svg';
import { ReactComponent as CoinsIcon } from '@/assets/Icons/icon-coins.svg';

import styles from './AddSupplierForm.module.scss';

type SupplierDetailsForm = {
  name: string;
  organizationNumber: number;
  industry: string;
  contactPerson: string;
  email: string;
  phone: string;
  contractName: string;
  contractStatus: string;
  contractValue: string;
  contractStartDate: string;
  contractEndDate: string;
  setAlert: string;
  additionalInfo: string;
};

export const AddSupplierForm = () => {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<SupplierDetailsForm>();

  const onSubmit: SubmitHandler<SupplierDetailsForm> = (data) => {
    console.log(data);

    navigate('/app/suppliers');
  };

  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  return (
    <form className={styles.AddSupplierForm} onSubmit={handleSubmit(onSubmit)}>
      <FormFieldInput
        className={styles.AddSupplierForm__inputs}
        id={'name'}
        label={'Name'}
        type={'text'}
        name={'name'}
        icon={<BuildingIcon />}
        register={register}
        state={{ required: true }}
        // TODO: Add Disabled State and Error State
      />
      <FormFieldInput
        id={'organizationNumber'}
        label={'Organisation number'}
        type={'text'}
        name={'organizationNumber'}
        icon={<CompanyDetailsIcon />}
        register={register}
        state={{ required: true }}
        // TODO: Add Disabled State and Error State
      />
      <FormFieldInput
        id={'industry'}
        label={'Industry'}
        type={'text'}
        name={'industry'}
        icon={<FactoryIcon />}
        register={register}
        state={{ required: true }}
        // TODO: Add Disabled State and Error State
      />
      <FormFieldInput
        id={'contactPerson'}
        label={'Contact Person'}
        type={'text'}
        name={'contactPerson'}
        icon={<PersonIcon />}
        register={register}
        state={{ required: true }}
        // TODO: Add Disabled State and Error State
      />
      <FormFieldInput
        id={'email'}
        label={'Email'}
        type={'email'}
        name={'email'}
        icon={<MailIcon />}
        register={register}
        state={{ required: true }}
        // TODO: Add Disabled State and Error State
      />
      <FormFieldInput
        id={'phone'}
        label={'Phone'}
        type={'tel'}
        name={'phone'}
        icon={<PhoneIcon />}
        register={register}
        state={{ required: true }}
        // TODO: Add Disabled State and Error State
      />
      <div className={styles.AddSupplierForm__heading}>
        <Heading headingLevel='h3'>Optional information about your contract</Heading>
      </div>
      <FormFieldInput
        id={'contractName'}
        label={'Contract Name'}
        type={'text'}
        name={'contractName'}
        icon={<AddressIcon />}
        register={register}
        // TODO: Add Disabled State and Error State
      />
      <Controller
        control={control}
        name='contractStatus'
        render={({ field: { onChange } }) => (
          <FormFieldSelect
            label='Contract status'
            id={'contractStatus'}
            onChange={onChange}
            options={[
              { value: 'contractStatus1', label: 'Contract Status 1' },
              { value: 'contractStatus2', label: 'Contract Status 2' },
              { value: 'contractStatus3', label: 'Contract Status 3' },
            ]}
          />
        )}
      />
      <FormFieldInput
        id={'contractValue'}
        label={'Contract Value'}
        type={'text'}
        name={'contractValue'}
        icon={<CoinsIcon />}
        register={register}
        // TODO: Add Disabled State and Error State
      />
      <FormFieldInput
        id={'contractStartDate'}
        label={'Contract start date'}
        type={'date'}
        name={'contractStartDate'}
        register={register}
        // TODO: Add Disabled State and Error State
      />
      <FormFieldInput
        id={'contractEndDate'}
        label={'Contract end date'}
        type={'date'}
        name={'contractEndDate'}
        register={register}
        // TODO: Add Disabled State and Error State
      />
      <Controller
        control={control}
        name='setAlert'
        render={({ field: { onChange } }) => (
          <FormFieldSelect
            label='Set alert before expiring contract'
            id={'setAlert'}
            onChange={onChange}
            options={[
              { value: 'alert1', label: 'Alert 1' },
              { value: 'alert2', label: 'Alert 2' },
              { value: 'alert3', label: 'Alert 3' },
            ]}
          />
        )}
      />
      <div className={styles.AddSupplierForm__textArea}>
        <Heading
          className={styles.AddSupplierForm__textAreaHeading}
          headingLevel='h3'
          id={'additionalInfo'}
        >
          Additional contract information
        </Heading>
        <Controller
          control={control}
          name='additionalInfo'
          render={({ field: { onChange } }) => (
            <TextArea
              id={'additionalInfo'}
              name='additionalInfo'
              reference={textAreaRef}
              onChange={onChange}
              // hasError={textAreaEmpty}
            />
          )}
        />
      </div>
      <div className={styles.AddSupplierForm__buttonContainer}>
        <FormFieldCta
          className={styles.AddSupplierForm__submitButton}
          type='reset'
          id={'reset'}
          label={'Cancel'}
        />

        <FormFieldCta
          className={styles.AddSupplierForm__submitButton}
          type='submit'
          id={'save'}
          label={'Save'}
        />
      </div>
    </form>
  );
};
