import { AnimatedButton, Button, ContentCard, DataTable } from '@/components/2-molecules';
import { useMemo } from 'react';
import { ColumnDef } from '@tanstack/react-table';

import styles from './CompanyOverview.module.scss';
import { Heading } from '@/components/1-atoms';
import { useNavigate } from 'react-router-dom';

export interface CompanyOverviewProps {
  className?: string;
  organisations?: OrganisationDetails[];
}

export const CompanyOverview = ({ className, organisations }: CompanyOverviewProps) => {
  const navigate = useNavigate();

  const handleEditClick = (organisation: OrganisationDetails) => {
    navigate('update-company', { state: { organisation } });
  };

  const columns = useMemo<ColumnDef<OrganisationDetails>[]>(
    () => [
      {
        header: 'Name',
        accessorKey: 'companyName',
      },
      {
        header: 'ORG. NO.',
        accessorKey: 'orgId',
      },
      {
        header: 'INDUSTRY',
        accessorKey: 'industry',
      },
      {
        header: 'ACTIONS',
        cell: ({ row }) => {
          const organisation = row.original;
          return (
            <div className={styles.CompanyOverview__actionButtons}>
              <AnimatedButton title={'activate'} type={'activate'} />
              <AnimatedButton title={'delete'} type={'delete'} />
              <AnimatedButton
                title={'edit'}
                type={'edit'}
                onClick={() => handleEditClick(organisation)}
              />
            </div>
          );
        },
      },
    ],
    [],
  );

  return (
    <ContentCard
      heading={<Heading headingLevel={'h2'}>Companies</Heading>}
      actionButton={
        <Button type={'primary'} link={'create'}>
          Add new company
        </Button>
      }
    >
      {organisations && <DataTable columns={columns} data={organisations} />}
    </ContentCard>
  );
};
