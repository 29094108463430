export const categories: Category[] = [
  { label: 'General', value: 1, checked: true },
  { label: 'Security', value: 2, checked: false },
  { label: 'Legal', value: 3, checked: false },
  { label: 'IT', value: 4, checked: false },
  { label: 'Finance', value: 5, checked: false },
  { label: 'Healthcare', value: 6, checked: false },
  { label: 'Education', value: 7, checked: false },
  { label: 'Technology', value: 8, checked: false },
  { label: 'Environment', value: 9, checked: false },
  { label: 'Custom Category', value: 10, checked: false },
];
