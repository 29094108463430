import { Heading } from '@/components/1-atoms';
import styles from './PhasesHeading.module.scss';
import { formatDateAsDottedString } from '@/utilities/dateHandler';
import { TextInfo } from '@/components/2-molecules';

export interface PhasesHeadingProps {
  editHeading: boolean;
  phaseDefaultTitle: string;
  phase: TenderFormPhase;
}

export const PhasesHeading = ({ phaseDefaultTitle, editHeading, phase }: PhasesHeadingProps) => {
  const { phaseName, startDate, endDate, isMandatory, phaseVisabilityId } = phase;

  return editHeading ? (
    <TextInfo
      headingLevel='h2'
      title={phaseDefaultTitle}
      description={'Some kind of description'}
    />
  ) : (
    <div className={styles.PhasesHeading}>
      <Heading headingLevel={'h3'}>{phaseName}</Heading>
      <Heading className={styles.PhasesHeading__param} headingLevel={'h3'}>
        Start date: {formatDateAsDottedString(new Date(startDate))}.
      </Heading>
      <Heading className={styles.PhasesHeading__param} headingLevel={'h3'}>
        End date: {formatDateAsDottedString(new Date(endDate))}.
      </Heading>
      <Heading className={styles.PhasesHeading__param} headingLevel={'h3'}>
        Mandatory: {isMandatory ? 'Yes' : 'No'}
      </Heading>
      <Heading className={styles.PhasesHeading__param} headingLevel={'h3'}>
        Visibility: {phaseVisabilityId ? 'Visible to all' : 'Hidden'}
      </Heading>
    </div>
  );
};
