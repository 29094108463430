import classNames from 'classnames';
import React, { useState } from 'react';
import styles from './TabsNavigation.module.scss';
import { Heading } from '@/components/1-atoms';

export interface TabsNavigationProps {
  tabsHeaders: { title: string; id: string }[];
  initialTabID: string;
  className?: string;
  onTabClick: (tabId: string) => void;
}

export const TabsNavigation = ({
  className,
  onTabClick,
  tabsHeaders,
  initialTabID,
}: TabsNavigationProps) => {
  const [activeTab, setActiveTab] = useState(initialTabID);

  return (
    <ul className={classNames(styles.TabsNavigation, className)}>
      {tabsHeaders.map((tab) => (
        <li
          key={tab.id}
          className={classNames(
            styles.TabsNavigation__item,
            activeTab === tab.id && styles.TabsNavigation__item_active,
          )}
          onClick={() => {
            onTabClick(tab.id);
            setActiveTab(tab.id);
          }}
        >
          <Heading headingLevel={'h3'}>{tab.title}</Heading>
        </li>
      ))}
    </ul>
  );
};
