import { useState } from 'react';
import classNames from 'classnames';
import { TabsNavigation } from '@/components/2-molecules';
import styles from './PhaseContent.module.scss';
import { HeadingForm } from './HeadingForm';
import { QuestionsForm } from './QuestionsForm';
import { DocumentsForm } from './DocumentsForm';

export interface PhaseContentProps {
  className?: string;
  currentPhaseName: string;
  editHeading: boolean;
  stopEditHeading: () => void;
}

export const PhaseContent = ({
  className,
  currentPhaseName,
  editHeading,
  stopEditHeading,
}: PhaseContentProps) => {
  const [activeTab, setActiveTab] = useState<string>('Questions');

  return (
    <div className={classNames(styles.PhaseContent, className)}>
      {editHeading ? (
        <HeadingForm currentPhaseName={currentPhaseName} stopEditing={() => stopEditHeading()} />
      ) : (
        <>
          <TabsNavigation
            tabsHeaders={[
              { title: 'Documents', id: 'Documents' },
              { title: 'Questions', id: 'Questions' },
            ]}
            initialTabID={activeTab}
            onTabClick={(tabId: string) => setActiveTab(tabId)}
          />
          <DocumentsForm
            currentPhaseName={currentPhaseName}
            className={activeTab == 'Documents' ? '' : styles.PhaseContent__Tab_hide}
          />
          <QuestionsForm
            currentPhaseName={currentPhaseName}
            className={activeTab == 'Questions' ? '' : styles.PhaseContent__Tab_hide}
          />
        </>
      )}
    </div>
  );
};
