import { Heading } from '@/components/1-atoms';
import { FormFieldInput, FormFieldCta, FormFieldImageInput } from '@/components/2-molecules';

import { useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';

import styles from './AccountOverview.module.scss';
import { ReactComponent as MailIcon } from '@/assets/Icons/icon-mail.svg';
import { ReactComponent as ProfileIcon } from '@/assets/Icons/icon-person.svg';
import { ReactComponent as PhoneIcon } from '@/assets/Icons/icon-phone.svg';

export interface AccountOverviewProps {
  name?: string;
  email?: string;
  phone?: string;
  image?: string;
}

type AccountDetailsForm = {
  name: string;
  email: string;
  phone: number;
  image: FileList;
};

export const AccountOverview = ({ name, email, phone, image }: AccountOverviewProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<AccountDetailsForm>();
  const onSubmit: SubmitHandler<AccountDetailsForm> = (data) => {
    console.log(data);
  };

  const onImageChanged = (e: React.FormEvent<HTMLInputElement>) => {
    const target = e?.target as HTMLInputElement;

    if (target.files) {
      const urlImage = URL.createObjectURL(target.files[0]);
      setImageSrc(urlImage);
    }
  };

  const [imageSrc, setImageSrc] = useState<string>(
    image
      ? image
      : 'https://fastly.picsum.photos/id/111/180/180.jpg?hmac=gw7hnY310DqPXIghei3V8kxHi5SUiRCWpoEy6cxAi8k',
  );

  return (
    <section className={styles.AccountOverview}>
      <Heading headingLevel='h2' className={styles.AccountOverview__pageTitle}>
        Account settings
      </Heading>

      <form className={styles.AccountOverview__form} onSubmit={handleSubmit(onSubmit)}>
        <FormFieldImageInput
          className={styles.AccountOverview__avatarInput}
          defaultImage={imageSrc}
          ariaLabel='Click to choose a profile picture'
          id={'image'}
          label={'avatar'}
          name={'image'}
          onImageChange={onImageChanged}
          register={register}
        />

        <FormFieldInput
          className={styles.AccountOverview__nameInput}
          id={'name'}
          label={'Name'}
          type={'text'}
          name={'name'}
          defaultValue={name}
          register={register}
          icon={<ProfileIcon />}
          // TODO: Add Disabled State and Error State
        />

        <div className={styles.AccountOverview__row}>
          <FormFieldInput
            className={styles.AccountOverview__emailInput}
            id={'email'}
            label={'Email'}
            type={'email'}
            name={'email'}
            defaultValue={email}
            register={register}
            icon={<MailIcon />}
            // TODO: Add Disabled State and Error State
          />

          <FormFieldInput
            className={styles.AccountOverview__phoneInput}
            id={'phone'}
            label={'Phone'}
            type={'tel'}
            name={'phone'}
            defaultValue={phone}
            register={register}
            icon={<PhoneIcon />}
            // TODO: Add Disabled State and Error State
          />
        </div>

        <div className={styles.AccountOverview__cta}>
          <FormFieldCta type='reset' id={'cancel'} label={'Cancel'} />
          <FormFieldCta type='submit' id={'save'} label={'Save'} />
        </div>
      </form>
    </section>
  );
};
