import classNames from 'classnames';
import { Heading, Paragraph } from '@/components/1-atoms';
import styles from './LeadDetails.module.scss';
import { TextInfo, Button, IconButton } from '@/components/2-molecules';
import { ReactComponent as ArrowIcon } from '@/assets/Icons/icon-chevron-left-black.svg';

import { useNavigate } from 'react-router-dom';

export interface LeadDetailsProps {
  className?: string;
  lead?: Lead;
  onActionTriggered: () => void;
}

export const LeadDetails = ({ className, lead, onActionTriggered }: LeadDetailsProps) => {
  const navigate = useNavigate();
  const { name, type, details } = lead ?? {};

  return (
    <div className={classNames(styles.LeadDetails, className)}>
      <div className={styles.LeadDetails__LeadDetailsBack}>
        <IconButton
          icon={<ArrowIcon className={styles.ResponseItem__arrowIcon} />}
          onClick={() => {
            navigate(-1);
          }}
        >
          Back
        </IconButton>
      </div>
      <div className={styles.LeadDetails_Heading2}>
        <TextInfo headingLevel={'h2'} title={'COMPANY INFO'} description={'description'} />
      </div>
      <div className={styles.LeadDetails__companyInfoRow}>
        <div className={styles.LeadDetails__companyInfoBox}>
          <Heading className={styles.Details__infoBoxHeading} headingLevel={'h3'}>
            Turnover:
          </Heading>
          <Paragraph>{details?.turnover}</Paragraph>
        </div>
        <div className={styles.LeadDetails__companyInfoBox}>
          <Heading className={styles.Details__infoBoxHeading} headingLevel={'h3'}>
            Number of invoices in:
          </Heading>
          <Paragraph>{details?.ingoingInvoices}</Paragraph>
        </div>
        <div className={styles.LeadDetails__companyInfoBox}>
          <Heading className={styles.Details__infoBoxHeading} headingLevel={'h3'}>
            Number of invoices out:
          </Heading>
          <Paragraph>{details?.outgoingInvoices}</Paragraph>
        </div>
        <div className={styles.LeadDetails__companyInfoBox}>
          <Heading className={styles.Details__infoBoxHeading} headingLevel={'h3'}>
            Number of employees:
          </Heading>
          <Paragraph>{details?.numberOfEmployees}</Paragraph>
        </div>
      </div>

      <div className={styles.LeadDetails_Heading2}>
        <TextInfo headingLevel={'h2'} title={'TENDER SPECIFIC'} description={'description'} />
      </div>
      <div className={styles.LeadDetails__leadInfoRow}>
        <div className={styles.LeadDetails__leadInfoItem}>
          <Heading className={styles.Details__infoItemHeading} headingLevel={'h3'}>
            Tender name:
          </Heading>
          <Paragraph>{name}</Paragraph>
        </div>

        <div className={styles.LeadDetails__leadInfoItem}>
          <Heading className={styles.Details__infoItemHeading} headingLevel={'h3'}>
            Tender category:
          </Heading>
          <Paragraph>{type?.name}</Paragraph>
        </div>

        <div className={styles.LeadDetails__leadInfoItem}>
          <Heading className={styles.Details__infoItemHeading} headingLevel={'h3'}>
            Description:
          </Heading>
          <Paragraph>{details?.description}</Paragraph>
        </div>
      </div>
      {/* TODO: Update logic when/if isSponsored is implemented */}
      {lead?.isSponsored ? (
        <div className={styles.LeadDetails__leadButtonRow}>
          <Button type={'primary'} onClick={onActionTriggered}>
            Respond to tender
          </Button>
        </div>
      ) : (
        <div className={styles.LeadDetails__leadButtonRow}>
          <Button type={'primary'} onClick={onActionTriggered}>
            Buy lead
          </Button>
        </div>
      )}
    </div>
  );
};
