import { createSlice } from '@reduxjs/toolkit';
import { mockedResponses, mockedCompareResponses } from './constants/responses';

export interface ResponsesState {
  responsesDetails: PhaseResponseDetails[];
  responsesCompare: PhaseResponsesCompare | undefined;
}

const initialState: ResponsesState = {
  responsesDetails: [],
  responsesCompare: undefined,
};

export const responsesSlice = createSlice({
  name: 'responses',
  initialState,
  reducers: {
    getResponseDetails: (state: ResponsesState) => {
      state.responsesDetails = mockedResponses;
    },

    getResponsesCompare: (state: ResponsesState) => {
      state.responsesCompare = mockedCompareResponses;
    },
  },
});

export const { getResponseDetails, getResponsesCompare } = responsesSlice.actions;
export default responsesSlice.reducer;
