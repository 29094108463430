// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EFV2vYsIwYwG8iKTfT66 {
  background-color: var(--white);
  height: 16rem;
  border-bottom: var(--primary-border);
  width: 100%;
}

.EFV2vYsIwYwG8iKTfT66 .active {
  background-color: #f4f5f6;
}

.d05MNmmNFNPyNP9O_vqe {
  display: flex;
  flex-flow: column;
  justify-content: center;
  text-decoration: none;
  height: 100%;
  padding-left: 5rem;
}

.SyerKq4o0BdlF96IPP2V {
  margin-bottom: 2rem;
}

.XXmpr2j2TtnhG9cZZOyW {
  line-height: 2.1rem;
  letter-spacing: 0em;
  color: var(--dark-grey-600);
}`, "",{"version":3,"sources":["webpack://./src/components/3-organisms/SettingsOverview/SettingsList/SettingsItem/SettingsItem.module.scss"],"names":[],"mappings":"AAAA;EACE,8BAAA;EACA,aAAA;EACA,oCAAA;EACA,WAAA;AACF;;AAEA;EACE,yBAAA;AACF;;AAEA;EACE,aAAA;EACA,iBAAA;EACA,uBAAA;EACA,qBAAA;EACA,YAAA;EACA,kBAAA;AACF;;AAEA;EACE,mBAAA;AACF;;AAEA;EACE,mBAAA;EACA,mBAAA;EACA,2BAAA;AACF","sourcesContent":[".SettingsItem {\n  background-color: var(--white);\n  height: 16rem;\n  border-bottom: var(--primary-border);\n  width: 100%;\n}\n\n.SettingsItem :global(.active) {\n  background-color: #f4f5f6; // TODO: the color is not in the variables;\n}\n\n.SettingsItem__link {\n  display: flex;\n  flex-flow: column;\n  justify-content: center;\n  text-decoration: none;\n  height: 100%;\n  padding-left: 5rem;\n}\n\n.SettingsItem__title {\n  margin-bottom: 2rem;\n}\n\n.SettingsItem__subTitle {\n  line-height: 2.1rem;\n  letter-spacing: 0em;\n  color: var(--dark-grey-600);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SettingsItem": `EFV2vYsIwYwG8iKTfT66`,
	"SettingsItem__link": `d05MNmmNFNPyNP9O_vqe`,
	"SettingsItem__title": `SyerKq4o0BdlF96IPP2V`,
	"SettingsItem__subTitle": `XXmpr2j2TtnhG9cZZOyW`
};
export default ___CSS_LOADER_EXPORT___;
