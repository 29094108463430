import './styles/main.scss';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';

import { TendersRoutes } from './components/5-pages/tenders';
import { LeadsRoutes } from './components/5-pages/leads';
import { OnBoardingPage } from './components/5-pages/OnBoardingPage';

import { LoadingCard } from './components/2-molecules';
import { getUserLogin, setAccessToken } from './store/slices/userLoginSlice';
import { useAppDispatch, useAppSelector } from './store';
import { LoginType } from './constants/loginTypes';

const App = () => {
  const { loginWithRedirect, isLoading, isAuthenticated, getAccessTokenSilently, logout } =
    useAuth0();
  const dispatch = useAppDispatch();

  const { loginProfile, tokenExpirationDate } = useAppSelector((state) => state.user);
  const navigate = useNavigate();
  const location = useLocation();

  const fetchUserData = async () => {
    const token = await getAccessTokenSilently();
    await dispatch(setAccessToken(token));
    await dispatch(getUserLogin());
  };

  const isTokenValid = () => new Date(tokenExpirationDate) > new Date();
  const isUserLoggedIn = () => loginProfile && loginProfile?.version != undefined;

  useEffect(() => {
    if (isTokenValid() && isUserLoggedIn()) return; // token is not expired and user is logged in

    if (!isAuthenticated && !isLoading) {
      loginWithRedirect().catch((error) => console.error(error));
    }
    if (isAuthenticated) {
      fetchUserData();
    }
  }, [loginProfile, isLoading]);

  useEffect(() => {
    if (!isUserLoggedIn()) return;
    if (location.pathname !== '/') return;

    switch (loginProfile?.version) {
      case LoginType.Buyer:
        navigate('/tenders');
        break;

      case LoginType.Supplier:
        navigate('/leads');
        break;

      default:
        navigate('/onboarding');
    }
  }, [loginProfile?.version]);

  return (
    <>
      <Routes>
        <Route path='/onboarding' element={<OnBoardingPage />} />
        <Route path='/tenders/*' element={<TendersRoutes />} />
        <Route path='/leads/*' element={<LeadsRoutes />} />
        {/* <Route path='*' element={<h1>404</h1>} /> */}
      </Routes>

      {(isLoading || !loginProfile) && <LoadingCard />}
    </>
  );
};

export default App;
