import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { Accordion } from '@/components/2-molecules';

import { PhasesHeading } from './PhasesHeading';
import { PhaseContent } from './PhaseContent';

import { ReactComponent as TrashIcon } from '@/assets/Icons/icon-trash-grey.svg';
import { ReactComponent as PenIcon } from '@/assets/Icons/icon-pen.svg';
import classNames from 'classnames';
import styles from './PhaseForm.module.scss';

export interface PhaseFormProps {
  className?: string;
  phaseDefaultTitle: string;
  phasesArrayName: string;
  index: number;
  removePhase: () => void;
}

export const PhaseForm = ({
  className,
  phaseDefaultTitle,
  phasesArrayName,
  index,
  removePhase,
}: PhaseFormProps) => {
  const currentPhaseName = `${phasesArrayName}.${index}`;

  const [editHeading, setEditHeading] = useState<boolean>(true);

  const { getValues } = useFormContext();
  const phase = getValues(currentPhaseName);

  useEffect(() => {
    if (phase?.phaseId) {
      setEditHeading(false);
    }
  }, []);

  return (
    <Accordion
      className={classNames(styles.PhaseForm, className)}
      startOpen={true}
      heading={
        <div className={styles.PhaseForm__heading}>
          <PhasesHeading
            phase={phase}
            phaseDefaultTitle={phaseDefaultTitle}
            editHeading={editHeading}
          />

          <div className={styles.PhaseForm__icons} onClick={(e) => e.stopPropagation()}>
            <span onClick={() => setEditHeading(true)}>{<PenIcon />}</span>
            <span onClick={() => removePhase()}> {<TrashIcon />}</span>
          </div>
        </div>
      }
    >
      <PhaseContent
        currentPhaseName={currentPhaseName}
        editHeading={editHeading}
        stopEditHeading={() => setEditHeading(false)}
      />
    </Accordion>
  );
};
