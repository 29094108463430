import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { GetBuyerProfile } from '@/services/ProfileServices';

const initialState: BuyerProfileOverview = {
  userId: undefined,
  contact: undefined,
  availableOrganisations: [],
  dashboardOverview: undefined,
  tenderOverview: undefined,
  contractOverview: {
    contracts: [],
  },
  supplierOverview: {
    suppliers: [],
    contractStatuses: [],
  },
  settingsOverview: {
    buyerContact: undefined,
    organisations: [],
  },
};

export const getProfile = createAsyncThunk('profile/getProfile', async () => {
  return await GetBuyerProfile();
});

export const buyerProfileSlice = createSlice({
  name: 'buyerProfile',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getProfile.fulfilled, (state, action) => {
      return { ...action.payload };
    });
  },
});

export default buyerProfileSlice.reducer;
