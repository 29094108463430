import { useEffect, useState } from 'react';
import { useParams, Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@/store';
import { setTitle } from '@/store/slices/headerSlice';
import { GetBuyerTender } from '@/services/TenderServices';
import styles from './TenderDetailsPage.module.scss';
import {
  Details,
  ResponseItem,
  ResponsesList,
  ResponsesCompare,
  Tasks,
  NoticeBoard,
  ChatList,
  ChatRoomDetails,
  TenderContract,
} from '@/components/3-organisms';
import { TendersLayout } from '@/components/4-templates';
import { TopNavigation } from '@/components/2-molecules';

export const TenderDetailsPage = () => {
  const { tenderId, responseId = 1 } = useParams();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [tender, setTender] = useState<TenderDetails | undefined>(undefined);
  const [tenderParticipants, setTenderParticipants] = useState<TenderParticipants[] | []>([]);
  const [noticeBoardAlerts, setNoticeBoardAlerts] = useState<TenderNotice[]>([]);

  const responsesDetails = useAppSelector((state) =>
    state.responses?.responsesDetails.find((item) => item.id == responseId),
  );

  const contactId = useAppSelector((state) => state.supplierProfile.contact?.id);
  const responsesCompare = useAppSelector((state) => state.responses?.responsesCompare);

  useEffect(() => {
    const getTenderDetails = async () => {
      try {
        const response = await GetBuyerTender(Number(tenderId));
        setTender(response?.tender);
        setTenderParticipants(response?.participants);
        dispatch(setTitle(tender?.details?.name ?? 'Tender details'));
        setNoticeBoardAlerts(response.tender.noticeBoard.alerts);
      } catch (error) {
        console.log(error);
      }
    };

    getTenderDetails();
  }, []);

  return (
    <TendersLayout>
      <section className={styles.TenderDetailsPage}>
        <TopNavigation
          navigationList={[
            { label: 'Tasks', url: 'tasks' },
            { label: 'Tender details', url: 'tenderDetails' },
            { label: 'Tender responses', url: 'tenderResponses' },
            { label: 'Notice board', url: 'noticeBoard' },
            { label: 'Chat', url: 'chat' },
            { label: 'Contract', url: 'contract' },
          ]}
        />

        <Routes>
          <Route path='/' element={<Navigate to={'tasks'} />} />
          <Route path='tasks' element={<Tasks tasks={tender?.tasks} />} />
          <Route path='tenderDetails' element={<Details tender={tender} />} />
          <Route path='tenderResponses' element={<ResponsesList phases={[]} />}></Route>
          <Route
            path='tenderResponses/:responseId/*'
            element={<ResponseItem responsesDetails={responsesDetails} />}
          />

          <Route
            path='tenderResponses/compareResponses/*'
            element={<ResponsesCompare responsesCompare={responsesCompare} />}
          />

          <Route
            path='noticeBoard'
            element={
              <NoticeBoard
                notices={noticeBoardAlerts}
                tender={tender}
                contactId={contactId}
                updateNotices={(alert) =>
                  setNoticeBoardAlerts((currentAlerts) => [...currentAlerts, alert])
                }
              />
            }
          />

          <Route
            path='chat'
            element={
              <ChatList
                chatRooms={tender?.chatRooms ?? []}
                suppliersList={tenderParticipants}
                userRole='buyer'
                contactId={contactId}
              />
            }
          />

          <Route
            path='chat/:roomId'
            element={<ChatRoomDetails userRole='buyer' contactId={contactId} />}
          />

          <Route path='contract' element={<TenderContract tenderContracts={[]} />} />
        </Routes>
      </section>
    </TendersLayout>
  );
};
