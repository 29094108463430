import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Button.module.scss';

export interface ButtonProps {
  children: React.ReactNode;
  className?: string;
  link?: string;
  onClick?: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  isDisabled?: boolean;
  type:
    | 'primary'
    | 'secondary'
    | 'secondaryColor'
    | 'primaryColor'
    | 'borderlessPrimary'
    | 'borderlessSecondary';
}

export const Button = ({ children, className, link, onClick, type, isDisabled }: ButtonProps) => {
  return link ? (
    <Link
      className={classNames(
        styles.Button,
        styles.Button__link,
        styles[`Button__${type}`],
        className,
      )}
      to={link}
    >
      {children}
    </Link>
  ) : (
    <button
      className={classNames(styles.Button, styles[`Button__${type}`], className)}
      onClick={onClick}
      disabled={isDisabled}
    >
      {children}
    </button>
  );
};
