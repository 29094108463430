// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CDTT64Zk5PJYmEFYWg6_ {
  border-radius: 4rem;
  padding: 1.4rem 4.5rem;
  cursor: pointer;
  font-weight: var(--font-weight--bold);
  font-size: var(--font-size--lg);
  line-height: 2.2rem;
  min-width: 15rem;
}

.yfEkNIqrBCn24WHVEIjN {
  background-color: var(--accent-color);
  color: var(--white);
  border: none;
}

.rdlDgDX6p5LxMnyiIkwi {
  background-color: var(--light-grey-100);
  border: 2px solid var(--light-grey-300);
  color: var(--dark-grey-600);
}`, "",{"version":3,"sources":["webpack://./src/components/2-molecules/Form/FormFieldCta/FormFieldCta.module.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,sBAAA;EACA,eAAA;EACA,qCAAA;EACA,+BAAA;EACA,mBAAA;EACA,gBAAA;AACF;;AAEA;EACE,qCAAA;EACA,mBAAA;EACA,YAAA;AACF;;AAEA;EACE,uCAAA;EACA,uCAAA;EACA,2BAAA;AACF","sourcesContent":[".FormFieldCta {\n  border-radius: 4rem;\n  padding: 1.4rem 4.5rem;\n  cursor: pointer;\n  font-weight: var(--font-weight--bold);\n  font-size: var(--font-size--lg);\n  line-height: 2.2rem;\n  min-width: 15rem;\n}\n\n.FormFieldCta__submit {\n  background-color: var(--accent-color);\n  color: var(--white);\n  border: none;\n}\n\n.FormFieldCta__reset {\n  background-color: var(--light-grey-100);\n  border: 2px solid var(--light-grey-300);\n  color: var(--dark-grey-600);\n}\n\n// TODO: Hover state\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"FormFieldCta": `CDTT64Zk5PJYmEFYWg6_`,
	"FormFieldCta__submit": `yfEkNIqrBCn24WHVEIjN`,
	"FormFieldCta__reset": `rdlDgDX6p5LxMnyiIkwi`
};
export default ___CSS_LOADER_EXPORT___;
