import { useRef, useState, useEffect } from 'react';
import classNames from 'classnames';
import styles from './Dropdown.module.scss';
import { Paragraph } from '@/components/1-atoms';

type Option = {
  value: string;
  label: string;
};

export interface DropdownProps {
  children?: JSX.Element;
  className?: string;
  options: Option[];
  onChange: (value: string) => void;
}

export const Dropdown = ({ className, children, options, onChange }: DropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleOptionClick = (value: string) => {
    onChange(value);
    setIsOpen(false);
  };

  const toggleDropdown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    setIsOpen((prevState) => !prevState);
  };

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  return (
    <div
      className={classNames(styles.Dropdown, className)}
      ref={dropdownRef}
      onClick={(e) => toggleDropdown(e)}
      role='button'
      aria-label={'drop-down menu'}
    >
      {children}
      <ul className={classNames(styles.Dropdown__menu, isOpen && styles.Dropdown__menu_open)}>
        {options &&
          options.map((option, index) => (
            <li
              className={styles.Dropdown__menuItem}
              key={index}
              onClick={() => handleOptionClick(option.value)}
            >
              <Paragraph> {option.label} </Paragraph>
            </li>
          ))}
      </ul>
    </div>
  );
};
