// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SOkcgLtp0H8BhsV7XDO0 {
  width: 100%;
}

.qv6J3sf9vLMkbI8kqo8c {
  width: 50rem;
  display: flex;
  align-items: center;
  gap: 2rem;
}

.CRpErln67kHqRn1jGbTu,
.iAOAyM3hJbd27JLu30eG,
.DKSEHDgOAIcTmBqXtXVo {
  border-radius: 8rem;
  color: var(--white);
  padding: 0.7rem 1.7rem;
}

.CRpErln67kHqRn1jGbTu {
  background: var(--accent-color);
}

.iAOAyM3hJbd27JLu30eG {
  background: var(--primary-color);
}

.DKSEHDgOAIcTmBqXtXVo {
  background: var(--secondary-color);
}

.khVsNXDyj17E4_d0fyg4 {
  min-height: 53.2rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
}

.C4_3krdUivlzR9qgdnNq {
  margin-bottom: 2rem;
}`, "",{"version":3,"sources":["webpack://./src/components/3-organisms/DashBoard/TasksOverview/TasksOverview.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;;AAEA;EACE,YAAA;EACA,aAAA;EACA,mBAAA;EACA,SAAA;AACF;;AAEA;;;EAGE,mBAAA;EACA,mBAAA;EACA,sBAAA;AACF;;AAEA;EACE,+BAAA;AACF;;AAEA;EACE,gCAAA;AACF;;AAEA;EACE,kCAAA;AACF;;AAEA;EACE,mBAAA;EACA,aAAA;EACA,mBAAA;EACA,sBAAA;EACA,uBAAA;EACA,SAAA;AACF;;AAEA;EACE,mBAAA;AACF","sourcesContent":[".TasksOverview {\n  width: 100%;\n}\n\n.TasksOverview__filters {\n  width: 50rem;\n  display: flex;\n  align-items: center;\n  gap: 2rem;\n}\n\n.TasksOverview__tendersFilter,\n.TasksOverview__contractsFilter,\n.TasksOverview__suppliersFilter {\n  border-radius: 8rem;\n  color: var(--white);\n  padding: 0.7rem 1.7rem;\n}\n\n.TasksOverview__tendersFilter {\n  background: var(--accent-color);\n}\n\n.TasksOverview__contractsFilter {\n  background: var(--primary-color);\n}\n\n.TasksOverview__suppliersFilter {\n  background: var(--secondary-color);\n}\n\n.TasksOverview__emptyList {\n  min-height: 53.2rem;\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n  justify-content: center;\n  gap: 2rem;\n}\n\n.TasksOverview__emptyListHeading {\n  margin-bottom: 2rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TasksOverview": `SOkcgLtp0H8BhsV7XDO0`,
	"TasksOverview__filters": `qv6J3sf9vLMkbI8kqo8c`,
	"TasksOverview__tendersFilter": `CRpErln67kHqRn1jGbTu`,
	"TasksOverview__contractsFilter": `iAOAyM3hJbd27JLu30eG`,
	"TasksOverview__suppliersFilter": `DKSEHDgOAIcTmBqXtXVo`,
	"TasksOverview__emptyList": `khVsNXDyj17E4_d0fyg4`,
	"TasksOverview__emptyListHeading": `C4_3krdUivlzR9qgdnNq`
};
export default ___CSS_LOADER_EXPORT___;
