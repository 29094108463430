import classNames from 'classnames';
import styles from './Filters.module.scss';

type filterOption = {
  label: string;
  value: string;
  className?: string;
  activeClassName?: string;
};

export interface FiltersProps {
  className?: string;
  onChange: (value: string) => void;
  options: filterOption[];
  selectedValue: string;
  type: 'grouped' | 'rounded' | 'spread';
}

export const Filters = ({ className, onChange, options, selectedValue, type }: FiltersProps) => {
  const handleClick = (value: string) => {
    onChange(value);
  };

  return (
    <div className={classNames(styles.Filters, styles[`Filters_${type}`], className)}>
      {options.map((option) => (
        <button
          key={option.value}
          className={classNames(
            `${styles.Filters__button} ${selectedValue === option.value && option.activeClassName}`,
            option.className,
          )}
          onClick={() => handleClick(option.value)}
        >
          {option.label}
        </button>
      ))}
    </div>
  );
};
