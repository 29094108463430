const storageName = 'reduxState';

export const loadStateFromLocalStorage = () => {
  try {
    const serializedState = localStorage.getItem(storageName);

    if (!serializedState) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (error) {
    return undefined;
  }
};

export const saveStateToLocalStorage = (state: any) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(storageName, serializedState);
  } catch (error) {
    console.log(error);
  }
};

export const clearLocalStorage = () => {
  localStorage.clear();
};
