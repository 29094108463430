// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nJ_dZmW9eIfaDWoYYEsU {
  display: flex;
  flex-flow: column;
  gap: 4rem;
}

.C7F98PBm0tOypgqEzcQl {
  width: 50%;
}

.vZ97tYUiqkppv1E8Dzj_ {
  width: 50%;
}

.aDATd0y8khc0ZwYqldAc {
  margin-bottom: 1rem;
}

.t2BTgjQEsm1NLqrj_X1j {
  width: 100%;
  height: 25rem;
}

.vXyMW_yAzN6N8fqx_qQm {
  margin-top: 4rem;
  align-self: center;
}`, "",{"version":3,"sources":["webpack://./src/components/3-organisms/CreateTender/CreateTenderDetails/CreateTenderDetails.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,iBAAA;EACA,SAAA;AACF;;AAEA;EACE,UAAA;AACF;;AAEA;EACE,UAAA;AACF;;AAEA;EACE,mBAAA;AACF;;AAEA;EACE,WAAA;EACA,aAAA;AACF;;AAEA;EACE,gBAAA;EACA,kBAAA;AACF","sourcesContent":[".CreateTenderDetailsForm {\n  display: flex;\n  flex-flow: column;\n  gap: 4rem;\n}\n\n.CreateTenderDetailsForm__input {\n  width: 50%;\n}\n\n.CreateTenderDetailsForm__select {\n  width: 50%;\n}\n\n.CreateTenderDetailsForm__textAreaTitle {\n  margin-bottom: 1rem;\n}\n\n.CreateTenderDetailsForm__textArea {\n  width: 100%;\n  height: 25rem;\n}\n\n.CreateTenderDetailsForm__cta {\n  margin-top: 4rem;\n  align-self: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CreateTenderDetailsForm": `nJ_dZmW9eIfaDWoYYEsU`,
	"CreateTenderDetailsForm__input": `C7F98PBm0tOypgqEzcQl`,
	"CreateTenderDetailsForm__select": `vZ97tYUiqkppv1E8Dzj_`,
	"CreateTenderDetailsForm__textAreaTitle": `aDATd0y8khc0ZwYqldAc`,
	"CreateTenderDetailsForm__textArea": `t2BTgjQEsm1NLqrj_X1j`,
	"CreateTenderDetailsForm__cta": `vXyMW_yAzN6N8fqx_qQm`
};
export default ___CSS_LOADER_EXPORT___;
