// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ALR3KRmDomiPviyLQXgy {
  padding: 8rem 5rem 3rem 5rem;
}

.SCESiA7wzUN5fDWS3cOz {
  margin: 4.5rem 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

.Qxm2rTerQqYADaV_HzTD {
  display: flex;
  gap: 5rem;
}

.SXWQ87QltcNjEe4z9loT {
  width: 50%;
}`, "",{"version":3,"sources":["webpack://./src/components/5-pages/leads/LeadsOverviewPage/LeadsOverviewPage.module.scss"],"names":[],"mappings":"AAAA;EACE,4BAAA;AACF;;AAEA;EACE,gBAAA;EACA,WAAA;EACA,aAAA;EACA,uBAAA;AACF;;AAEA;EACE,aAAA;EACA,SAAA;AACF;;AAEA;EACE,UAAA;AACF","sourcesContent":[".LeadsOverviewPage {\n  padding: 8rem 5rem 3rem 5rem;\n}\n\n.LeadsOverviewPage__createTenderCta {\n  margin: 4.5rem 0;\n  width: 100%;\n  display: flex;\n  justify-content: center;\n}\n\n.LeadsOverviewPage__eventsSection {\n  display: flex;\n  gap: 5rem;\n}\n\n.LeadsOverviewPage__calendar {\n  width: 50%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"LeadsOverviewPage": `ALR3KRmDomiPviyLQXgy`,
	"LeadsOverviewPage__createTenderCta": `SCESiA7wzUN5fDWS3cOz`,
	"LeadsOverviewPage__eventsSection": `Qxm2rTerQqYADaV_HzTD`,
	"LeadsOverviewPage__calendar": `SXWQ87QltcNjEe4z9loT`
};
export default ___CSS_LOADER_EXPORT___;
