import classNames from 'classnames';
import styles from './IconButton.module.scss';

export interface IconButtonProps {
  children?: React.ReactNode;
  className?: string;
  onClick?: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  icon: JSX.Element;
  iconOnRightSide?: boolean;
  IconClass?: string;
}

export const IconButton = ({
  children,
  className,
  icon,
  onClick,
  iconOnRightSide,
  IconClass,
}: IconButtonProps) => {
  return (
    <button
      className={classNames(
        styles.IconButton,
        iconOnRightSide && styles.IconButton__IconRight,
        className,
      )}
      onClick={onClick}
    >
      <span className={IconClass}>{icon}</span>
      {children}
    </button>
  );
};
