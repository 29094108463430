import { useForm, SubmitHandler } from 'react-hook-form';
import styles from './ChallengeContractForm.module.scss';
import { Heading, TextArea } from '@/components/1-atoms';

import {
  FormFieldInput,
  FormFieldCta,
  TextInfo,
  Button,
  FormFieldSelect,
  FormFieldFileInput,
} from '@/components/2-molecules';
import { useState } from 'react';
import { CreateSupplierForm } from '../CreateSupplierForm';

type AccountDetailsForm = {
  contractName: string;
  contractType: number;
  currentSupplier: string;
  newSupplier?: string;
  expiryDate: string;
  daysToNotifyBeforeExpire: number;
  contract: string;
  notes: string;
};

export const ChallengeContractForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<AccountDetailsForm>();
  const onSubmit: SubmitHandler<AccountDetailsForm> = (data) => {
    console.log(data);
  };

  const [openSupplierForm, setOpenSupplierForm] = useState<boolean>(false);

  return (
    <>
      <form className={styles.ChallengeContractForm} onSubmit={handleSubmit(onSubmit)}>
        <TextInfo
          headingLevel='h3'
          title='Contract challenge setup'
          description={`Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
          sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`}
          className={styles.ChallengeContractForm__heading}
        />

        <FormFieldInput
          id={'contractName'}
          label={'Contract name'}
          type={'text'}
          name={'contract Name'}
          register={register}
          // TODO: Add Disabled State and Error State
        />

        <FormFieldSelect
          id='contractType'
          label={'Contract type'}
          onChange={() => console.log('changed')}
          placeholder='Select contract type'
          options={[
            { value: 'customTenderType1', label: 'Custom tender type1' },
            { value: 'customTenderType2', label: 'Custom tender type2' },
          ]}
          // register={register}
        />

        <FormFieldSelect
          id='currentSupplier'
          label={'Current Supplier'}
          onChange={() => console.log('changed')}
          placeholder='Select an existing supplier or add a new one'
          options={[
            { value: 'supplier1', label: 'supplier 1' },
            { value: 'supplier2', label: 'supplier 2' },
          ]}
          // register={register}
        />

        <Button
          className={styles.ChallengeContractForm__addSupplierButton}
          type='primaryColor'
          // register={register}
          // TODO: Add Disabled State and Error State
          onClick={() => setOpenSupplierForm(true)}
        >
          Add new supplier
        </Button>

        <FormFieldInput
          id={'expiryDate'}
          label={'When does the contract expire?'}
          type={'date'}
          name={'expiryDate'}
          register={register}
          // TODO: Add Disabled State and Error State
        />

        <FormFieldInput
          id={'daysToNotifyBeforeExpire'}
          label={'How many days before expiry do you want to challenge it?'}
          type={'number'}
          name={'daysToNotifyBeforeExpire'}
          register={register}
          placeholder='Enter number of days'
          // TODO: Add Disabled State and Error State
        />

        <FormFieldFileInput
          id={'contract'}
          label={'Upload contract'}
          name={'Upload contract'}
          register={register}
        />

        <div className={styles.ChallengeContractForm__notesTextArea}>
          <Heading headingLevel='h3' id={'notesHeading'}>
            Add note about this contract
          </Heading>
          <TextArea id={'notes'} placeholder={'Enter your notes here'} name={'notes'} />
        </div>

        <FormFieldCta
          className={styles.ChallengeContractForm__submitButton}
          type='submit'
          id={'save'}
          label={'Challenge contract'}
        />
      </form>

      {openSupplierForm && <CreateSupplierForm setOpenPopup={() => setOpenSupplierForm(false)} />}
    </>
  );
};
