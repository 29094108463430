// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.L3vG8cxmiP3aoc9aW0hr {
  display: flex;
  flex-flow: row;
  width: 100%;
  align-items: flex-start;
  gap: 2rem;
}`, "",{"version":3,"sources":["webpack://./src/components/3-organisms/SettingsOverview/CompanyOverview/CompanyOverview.module.scss"],"names":[],"mappings":"AAGA;EACE,aAAA;EACA,cAAA;EACA,WAAA;EACA,uBAAA;EACA,SAAA;AAFF","sourcesContent":[".CompanyOverview {\n}\n\n.CompanyOverview__actionButtons {\n  display: flex;\n  flex-flow: row;\n  width: 100%;\n  align-items: flex-start;\n  gap: 2rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CompanyOverview__actionButtons": `L3vG8cxmiP3aoc9aW0hr`
};
export default ___CSS_LOADER_EXPORT___;
