import { AnyAction, combineReducers } from 'redux';
import headerSlice, { HeaderState } from './slices/headerSlice';
import userLoginSlice, { UserLoginState } from './slices/userLoginSlice';
import companiesSlice, { CompaniesState } from './slices/companiesSlice';
import contractsSlice, { ContractsState } from './slices/contractsSlice';
import responsesSlice, { ResponsesState } from './slices/responsesSlice';
import suppliersSlice, { SuppliersState } from './slices/suppliersSlice';
import createTenderSlice from './slices/createTenderSlice';
import buyerProfileSlice from './slices/buyerProfileSlice';
import pageStatusSlice, { PageState } from './slices/PageStatusSlice';
import supplierProfileSlice from './slices/supplierProfileSlice';

export interface State {
  companies: CompaniesState;
  header: HeaderState;
  user: UserLoginState;
  profile: BuyerProfileOverview;
  pageStatus: PageState;
  supplierProfile: SupplierProfileOverview;
  createTender: TenderForm;
  responses: ResponsesState;
  contracts: ContractsState;
  suppliers: SuppliersState;
}

const rootReducer = combineReducers({
  companies: companiesSlice,
  header: headerSlice,
  user: userLoginSlice,
  buyerProfile: buyerProfileSlice,
  pageStatus: pageStatusSlice,
  supplierProfile: supplierProfileSlice,
  createTender: createTenderSlice,
  responses: responsesSlice,
  contracts: contractsSlice,
  suppliers: suppliersSlice,
});

export default rootReducer;
