import styles from './FormFieldCheckbox.module.scss';
import classNames from 'classnames';

export interface FormFieldCheckboxProps {
  className?: string;
  checked: boolean;
  disabled?: boolean;
  onChange: (event: unknown) => void;
}

export const FormFieldCheckbox = ({
  className,
  checked,
  disabled,
  onChange,
}: FormFieldCheckboxProps) => {
  return (
    <label className={classNames(styles.FormFieldCheckbox, className)}>
      <input
        disabled={disabled}
        onChange={(e) => onChange(e)}
        type='checkbox'
        className={styles.FormFieldCheckbox__input}
        checked={checked}
      />
      <span className={styles.FormFieldCheckbox__checkMark} />
    </label>
  );
};
