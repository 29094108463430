import classNames from 'classnames';
import styles from './ChatBubble.module.scss';
import { Image, Paragraph } from '@/components/1-atoms';

export interface ChatBubbleProps {
  className?: string;
  message: string;
  date: string;
  name: string;
  userIsOwner: boolean;
}

export const ChatBubble = ({ className, message, userIsOwner, date, name }: ChatBubbleProps) => {
  // const { owner, messageContent, role, timeStamp, avatar, userIsOwner } = message;

  const imageSource = userIsOwner
    ? 'https://randomuser.me/api/portraits/lego/6.jpg'
    : 'https://randomuser.me/api/portraits/lego/1.jpg';

  return (
    <div
      className={classNames(styles.ChatBubble, userIsOwner && styles.ChatBubble__owner, className)}
    >
      <div className={styles.ChatBubble__contentWrapper}>
        <Image className={styles.ChatBubble__avatar} src={imageSource} alt='avatar' />
        <Paragraph className={styles.ChatBubble__messageContent}>{message}</Paragraph>
      </div>
      <Paragraph>{`${name} - ${date}`}</Paragraph>
    </div>
  );
};
