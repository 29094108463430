import classNames from 'classnames';
import { Button, TextInfo } from '@/components/2-molecules';
import styles from './CreateTenderReview.module.scss';
import { Heading, Paragraph } from '@/components/1-atoms';
import { PhaseAccordion } from './PhaseAccordion';
import { useAppDispatch } from '@/store';
import { clearTenderForm, submitTenderQuestions } from '@/store/slices/createTenderSlice';
import { useNavigate } from 'react-router-dom';
import { setError, setLoading, setSuccess } from '@/store/slices/PageStatusSlice';

export interface CreateTenderReviewProps {
  moveToPreviousPage: () => void;
  tender?: TenderForm;
  className?: string;
}

export const CreateTenderReview = ({
  moveToPreviousPage,
  tender,
  className,
}: CreateTenderReviewProps) => {
  const {
    tenderName,
    tenderDescription,
    isAnonymous,
    isUnderCurrentContract,
    tenderTypeId,
    tenderPhases,
  } = tender ?? {};

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const onSubmit = async () => {
    dispatch(setLoading(true));

    const response = await dispatch(submitTenderQuestions());

    const responseStatus = response.meta?.requestStatus === 'fulfilled';

    responseStatus ? dispatch(setSuccess(true)) : dispatch(setError(true));

    setTimeout(() => {
      dispatch(setLoading(false));
    }, 4000);

    if (responseStatus) {
      setTimeout(() => {
        dispatch(clearTenderForm());
        dispatch(setSuccess(false));
        navigate('/tenders/tendersOverview');
      }, 3000);
    }
  };

  return (
    <div className={classNames(styles.CreateTenderReview, className)}>
      <div className={styles.CreateTenderReviewHeading}>
        <TextInfo headingLevel={'h2'} title={'Review'} description={'description'} />
      </div>

      <div className={styles.CreateTenderReview__dataRow}>
        <Heading className={styles.CreateTenderReview__rowHeading} headingLevel={'h3'}>
          Type:
        </Heading>
        <Paragraph>{tenderTypeId}</Paragraph>
      </div>

      <div className={styles.CreateTenderReview__dataRow}>
        <Heading className={styles.CreateTenderReview__rowHeading} headingLevel={'h3'}>
          Name:
        </Heading>
        <Paragraph>{tenderName}</Paragraph>
      </div>

      <div className={styles.CreateTenderReview__dataRow}>
        <Heading className={styles.CreateTenderReview__rowHeading} headingLevel={'h3'}>
          Description:
        </Heading>
        <Paragraph>{tenderDescription}</Paragraph>
      </div>

      <div className={styles.CreateTenderReview__dataRow}>
        <Heading className={styles.CreateTenderReview__rowHeading} headingLevel={'h3'}>
          Anonymous:
        </Heading>
        <Paragraph>{isAnonymous ? 'Yes' : 'No'}</Paragraph>
      </div>

      <div className={styles.CreateTenderReview__dataRow}>
        <Heading className={styles.CreateTenderReview__rowHeading} headingLevel={'h3'}>
          Current contract:
        </Heading>
        <Paragraph>{isUnderCurrentContract ? 'Yes' : 'No'}</Paragraph>
      </div>

      <div className={styles.CreateTenderReview__dataRow}>
        <Heading className={styles.CreateTenderReview__rowHeading} headingLevel={'h3'}>
          Expires:
        </Heading>
        <Paragraph>{tenderPhases && tenderPhases[tenderPhases.length - 1]?.endDate}</Paragraph>
      </div>

      {tenderPhases && (
        <>
          <div className={styles.CreateTenderReview__dataRow}>
            <Heading className={styles.CreateTenderReview__rowHeading} headingLevel={'h2'}>
              Phases:
            </Heading>
          </div>

          <div className={styles.CreateTenderReview__phasesList}>
            {tenderPhases.map((phase, index) => (
              <PhaseAccordion key={index} phase={phase} />
            ))}
          </div>
        </>
      )}

      <div className={styles.CreateTenderReview__ctaWrapper}>
        <Button type={'secondary'} onClick={() => moveToPreviousPage()}>
          Back
        </Button>

        <Button type={'primary'} onClick={() => onSubmit()}>
          Next
        </Button>
      </div>
    </div>
  );
};
