export const mockedContractsList: Contract[] = [
  {
    id: '1',
    name: 'Contract_1',
    supplier: 'Supplier_1',
    type: 'Phone contracts',
    startDate: '08.02.2022.',
    endDate: '08.02.2023.',
    value: '4.300.000 SEK',
  },

  {
    id: '2',
    name: 'Contract_2',
    supplier: 'Supplier_2',
    type: 'Phone contracts',
    startDate: '08.02.2022.',
    endDate: '08.02.2023.',
    value: '30.000 SEK',
  },
  {
    id: '3',
    name: 'Contract_3',
    supplier: 'Supplier_3',
    type: 'Phone contracts',
    startDate: '08.02.2022.',
    endDate: '08.02.2023.',
    value: '630.000 SEK',
  },
  {
    id: '4',
    name: 'Contract_4',
    supplier: 'Supplier_4',
    type: 'Phone contracts',
    startDate: '08.02.2022.',
    endDate: '08.02.2023.',
    value: '410.000 SEK',
  },
  {
    id: '5',
    name: 'Contract_5',
    supplier: 'Supplier_5',
    type: 'Phone contracts',
    startDate: '08.02.2022.',
    endDate: '08.02.2023.',
    value: '90.000 SEK',
  },
];
