// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ORFr5C6tKPHc0VlngNBA {
  width: 100%;
}

.GpCjUmtG0BeWU0o3zLVo {
  width: 40rem;
  display: flex;
  align-items: center;
}

.t3eMmKdNczrirQbVWO4a {
  border: unset;
  color: var(--black);
  padding: 0.7rem 1.7rem;
  background-color: unset;
  border: unset;
  font-family: var(--font-primary);
  font-weight: var(--font-weight--bold);
}
.Tp9oJSTXT7SQjoIxLwtw {
  color: var(--secondary-color);
}

.UhotDRO2_x1oNodhW4zB {
  padding-left: 4rem;
}

.vVXhlrn_EuWM81DJ8on7 {
  min-height: 30rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
}

.GC3MDaJuAuvP79yFO41J {
  margin-bottom: 2rem;
}`, "",{"version":3,"sources":["webpack://./src/components/3-organisms/LeadsOverview/LeadsOverview.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;;AAEA;EACE,YAAA;EACA,aAAA;EACA,mBAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,sBAAA;EACA,uBAAA;EACA,aAAA;EACA,gCAAA;EACA,qCAAA;AACF;AACE;EACE,6BAAA;AACJ;;AAGA;EACE,kBAAA;AAAF;;AAGA;EACE,iBAAA;EACA,aAAA;EACA,mBAAA;EACA,sBAAA;EACA,uBAAA;EACA,SAAA;AAAF;;AAGA;EACE,mBAAA;AAAF","sourcesContent":[".LeadsOverview {\n  width: 100%;\n}\n\n.LeadsOverview__filters {\n  width: 40rem;\n  display: flex;\n  align-items: center;\n}\n\n.LeadsOverview__tendersFilter {\n  border: unset;\n  color: var(--black);\n  padding: 0.7rem 1.7rem;\n  background-color: unset;\n  border: unset;\n  font-family: var(--font-primary);\n  font-weight: var(--font-weight--bold);\n\n  &_active {\n    color: var(--secondary-color);\n  }\n}\n\n.LeadsOverview__actionsButton {\n  padding-left: 4rem;\n}\n\n.LeadsOverview__emptyList {\n  min-height: 30rem;\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n  justify-content: center;\n  gap: 2rem;\n}\n\n.LeadsOverview__emptyListHeading {\n  margin-bottom: 2rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"LeadsOverview": `ORFr5C6tKPHc0VlngNBA`,
	"LeadsOverview__filters": `GpCjUmtG0BeWU0o3zLVo`,
	"LeadsOverview__tendersFilter": `t3eMmKdNczrirQbVWO4a`,
	"LeadsOverview__tendersFilter_active": `Tp9oJSTXT7SQjoIxLwtw`,
	"LeadsOverview__actionsButton": `UhotDRO2_x1oNodhW4zB`,
	"LeadsOverview__emptyList": `vVXhlrn_EuWM81DJ8on7`,
	"LeadsOverview__emptyListHeading": `GC3MDaJuAuvP79yFO41J`
};
export default ___CSS_LOADER_EXPORT___;
