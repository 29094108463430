import { Logo } from '@/components/1-atoms';
import { NavLink, useLocation } from 'react-router-dom';
import styles from './SideNavigation.module.scss';
import classNames from 'classnames';
interface SideNavigationProps {
  className?: string;
  navigationLinks: SideNavigationLink[];
}

export const SideNavigation = ({ className, navigationLinks }: SideNavigationProps) => {
  const location = useLocation();

  return (
    <aside className={classNames(styles.SideNavigation, className)}>
      <NavLink to='/'>
        <Logo className={styles.SideNavigation__logo} />
      </NavLink>
      <ul className={styles.SideNavigation__navigationList}>
        {navigationLinks.map((navLink, index) => (
          <li className={styles.SideNavigation__navigationItem} key={index}>
            <NavLink className={styles.SideNavigation__navigationLink} to={navLink.href}>
              {navLink.Icon && navLink.Icon}
              {navLink.text}
            </NavLink>
            {navLink.subLinks && location.pathname.startsWith(navLink.href) && (
              <ul>
                {navLink.subLinks.map((subLink, index) => (
                  <li key={index}>
                    <NavLink className={styles.SideNavigation__subNavigationLink} to={subLink.href}>
                      {subLink.text}
                    </NavLink>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
    </aside>
  );
};
