const today = new Date();

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export const getDate = (year: number, month: number, day: number): Date => {
  return new Date(year, month - 1, day);
};

export const getYesterdayDate = (): Date => {
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);
  return yesterday;
};

export const getTomorrowDate = (): Date => {
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  return tomorrow;
};

// get current month
export const getCurrentMonth = (): number => {
  return today.getMonth() + 1; // January is 0
};

export const getRandomDateInCurrentMonth = (minDate?: Date): Date => {
  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth();

  let minDay = 1;
  if (minDate && minDate.getMonth() === month) {
    minDay = minDate.getDate();
  }

  // Get a random day in the current month that is greater than or equal to minDay
  const maxDay = new Date(year, month + 1, 0).getDate();
  const randomDay = Math.floor(Math.random() * (maxDay - minDay + 1)) + minDay;

  // Get a random hour between 0 and 23
  const randomHour = Math.floor(Math.random() * 24);

  // Return a new Date object with the random date and hour
  return new Date(year, month, randomDay, randomHour);
};

export const formatDateToDayAndMonth = (date: Date): string => {
  const day = date.getDate();
  const month = months[date.getMonth()];
  const dayOfWeek = days[date.getDay()];
  return `${day} ${month}, ${dayOfWeek}`;
};

export const formatDateToDayAndYear = (date: Date): string => {
  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();
  return `${day} ${month}, ${year}`;
};

export const formatDateAsDottedString = (date: Date): string => {
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear().toString();
  return `${day}.${month}.${year}`;
};

export const formatDateAndTime = (dateString: string): string => {
  const date = new Date(dateString);

  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');

  return `${year}/${month}/${day} - ${hours}:${minutes}`;
};
