import classNames from 'classnames';
import styles from './ContractItem.module.scss';
import { Heading, Paragraph } from '@/components/1-atoms';
import { IconButton } from '@/components/2-molecules';

import { ReactComponent as ContractIcon } from '@/assets/Icons/icon-contract.svg';
import { ReactComponent as CheckIcon } from '@/assets/Icons/icon-check.svg';
import { ReactComponent as RestoreIcon } from '@/assets/Icons/icon-restore.svg';
import { ReactComponent as ViewIcon } from '@/assets/Icons/icon-view.svg';
import { ReactComponent as ArrowIcon } from '@/assets/Icons/icon-arrow-accordion.svg';

export interface ContractItemProps {
  className?: string;
  tenderContract: TenderContract;
}

export const ContractItem = ({ className, tenderContract }: ContractItemProps) => {
  const { sender, name, id, date, url, status, active } = tenderContract;

  return (
    <div className={classNames(styles.ContractItem, className)}>
      <div className={styles.ContractItem__headingWrapper}>
        <ContractIcon className={styles.ContractItem__icon} />
        <Heading headingLevel={'h3'}>{name}</Heading>
        <Paragraph> by {sender} </Paragraph>
        <Paragraph className={styles.ContractItem__date}>{date}</Paragraph>
      </div>

      {active && (
        <div>
          <Heading headingLevel={'h3'}>Status:</Heading>
          <Paragraph className={styles.ContractItem__status}>{status}</Paragraph>
        </div>
      )}

      <div className={styles.ContractItem__actionsWrapper}>
        {active && (
          <IconButton
            className={classNames(
              styles.ContractItem__actionButton,
              styles.ContractItem__activeButton,
            )}
            icon={<CheckIcon />}
          >
            <Heading headingLevel={'h3'}>Active</Heading>
          </IconButton>
        )}
        {!active && (
          <IconButton className={styles.ContractItem__actionButton} icon={<RestoreIcon />}>
            <Heading headingLevel={'h3'}>Restore</Heading>
          </IconButton>
        )}
        <IconButton className={styles.ContractItem__actionButton} icon={<ViewIcon />}>
          <Heading headingLevel={'h3'}>View</Heading>
        </IconButton>
        {active && (
          <IconButton
            className={styles.ContractItem__actionButton}
            icon={<ArrowIcon />}
            iconOnRightSide
          >
            <Heading headingLevel={'h3'}>More actions</Heading>
          </IconButton>
        )}
      </div>
    </div>
  );
};
