import { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '@/store';
import { setTitle } from '@/store/slices/headerSlice';

import { TendersLayout } from '@/components/4-templates';
import {
  CreateTenderDetails,
  CreateTenderReview,
  CreateTenderPhasesList,
} from '@/components/3-organisms/CreateTender';
import { StepIndicator } from '@/components/2-molecules';

import styles from './CreateTenderPage.module.scss';

export const CreateTenderPage = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const tenderData = useAppSelector((state) => state.createTender);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setTitle('New tender'));
  }, []);

  const formSteps = ['Tender details', 'Phases', 'Review'];

  const moveToNextPage = () => {
    setCurrentStep(currentStep + 1);
  };

  const moveToPreviousPage = () => {
    setCurrentStep(currentStep - 1);
  };

  return (
    <TendersLayout>
      <section className={styles.CreateTenderPage}>
        <StepIndicator steps={formSteps} currentStep={currentStep} />

        {currentStep == 0 && (
          <CreateTenderDetails defaultTender={tenderData} moveToNextPage={moveToNextPage} />
        )}

        {currentStep == 1 && (
          <CreateTenderPhasesList
            moveToNextPage={moveToNextPage}
            moveToPreviousPage={moveToPreviousPage}
            defaultTender={tenderData}
          />
        )}

        {currentStep == 2 && (
          <CreateTenderReview moveToPreviousPage={moveToPreviousPage} tender={tenderData} />
        )}
      </section>
    </TendersLayout>
  );
};
