import { useMemo } from 'react';
import classNames from 'classnames';

import { ColumnDef } from '@tanstack/react-table';

import { AnchorLink, Heading, TextBox } from '@/components/1-atoms';
import { Button, ContentCard, DataTable, Dropdown } from '@/components/2-molecules';
import styles from './ContractsOverview.module.scss';
import { ReactComponent as MenuIcon } from '@/assets/Icons/icon-menu.svg';
import { useNavigate } from 'react-router-dom';

export interface ContractsOverviewProps {
  className?: string;
  contractsList?: Contract[];
}

export const ContractsOverview = ({ className, contractsList }: ContractsOverviewProps) => {
  const navigate = useNavigate();

  const handleOptionClicked = (value: string) => {
    console.log(value);
  };

  const columns = useMemo<ColumnDef<Contract>[]>(
    () => [
      {
        header: 'Name',
        accessorKey: 'name',
      },
      {
        header: 'SUPPLIER',
        accessorKey: 'supplier',
      },
      {
        header: 'TYPE',
        accessorKey: 'type',
      },
      {
        header: 'START DATE',
        accessorKey: 'startDate',
      },
      {
        header: 'END DATE',
        accessorKey: 'endDate',
      },
      {
        header: 'VALUE (ROI)',
        accessorKey: 'value',
      },
      {
        header: 'ACTIONS',
        cell: (table: any) => {
          const tender = table.row.original;

          return (
            <Dropdown
              className={styles.ContractsOverview__actionsButton}
              options={[
                { value: 'viewContract', label: 'View contract' },
                { value: 'editContract', label: 'Edit contract' },
              ]}
              onChange={handleOptionClicked}
            >
              <MenuIcon />
            </Dropdown>
          );
        },
      },
    ],
    [],
  );

  return (
    <div className={classNames(styles.ContractsOverview, className)}>
      <ContentCard
        heading={<Heading headingLevel={'h2'}>Contract overview</Heading>}
        actionButton={
          <Button onClick={() => navigate('challenge-contract')} type='primaryColor'>
            Challenge contract
          </Button>
        }
      >
        <DataTable columns={columns} data={contractsList || []} />

        {!contractsList?.length && (
          <div className={styles.ContractsOverview__emptyList}>
            <TextBox
              title={
                <Heading headingLevel={'h3'} className={styles.ContractsOverview__emptyListHeading}>
                  There are no ContractsOverview for this company.
                </Heading>
              }
              description={
                <>
                  Not sure how to get started? Try{' '}
                  <AnchorLink link={''}>
                    <>
                      watching our video <br /> guide
                    </>
                  </AnchorLink>{' '}
                  or <AnchorLink link={''}> send as a question. </AnchorLink>
                </>
              }
              alignment={'centre'}
            />
          </div>
        )}
      </ContentCard>
    </div>
  );
};
