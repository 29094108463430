import classNames from 'classnames';
import { Heading } from '@/components/1-atoms';
import styles from './ResponseItem.module.scss';
import { Accordion, Button, IconButton } from '@/components/2-molecules';
import { useState } from 'react';

import { ResponseAccordion } from './ResponseAccordion';
import { TabsNavigation } from '@/components/2-molecules/TabsNavigation';
import { ReactComponent as ArrowIcon } from '@/assets/Icons/icon-arrow-accordion.svg';
import { useNavigate } from 'react-router-dom';
import { categories } from '@/constants/categories';

export interface ResponseItemProps {
  className?: string;
  responsesDetails?: PhaseResponseDetails;
}

export const ResponseItem = ({ className, responsesDetails }: ResponseItemProps) => {
  const [activeTab, setActiveTab] = useState('Questions');

  const getTabContent = () => {
    return activeTab === 'Questions' ? responsesDetails?.questions : responsesDetails?.documents;
  };
  const navigate = useNavigate();

  return (
    <div className={classNames(styles.ResponseItem, className)}>
      <div className={styles.ResponseItem__actionButtons}>
        <IconButton
          icon={<ArrowIcon className={styles.ResponseItem__arrowIcon} />}
          onClick={() => {
            navigate(-1);
          }}
        >
          Back
        </IconButton>
        <Button type={'primary'}>Choose this supplier</Button>
      </div>

      <div className={styles.ResponseItem__card}>
        <div className={styles.ResponseItem__cardHeading}>
          <Heading headingLevel={'h3'}>{responsesDetails?.supplierName}</Heading>
          <div className={styles.ResponseItem__cardStatus}>
            <Heading headingLevel={'h3'}>Active phase: {responsesDetails?.phaseName}</Heading>
            <Heading headingLevel={'h3'}>Score: {responsesDetails?.score}</Heading>
          </div>
        </div>

        <TabsNavigation
          tabsHeaders={[
            { title: 'Documents', id: 'Documents' },
            { title: 'Questions', id: 'Questions' },
          ]}
          initialTabID={activeTab}
          onTabClick={(tabId: string) => setActiveTab(tabId)}
        />

        {categories.map((category) => {
          // Filter responses based on the current category
          const filteredResponses = getTabContent()?.responses?.filter(
            (response) => response.category === category.value,
          );

          // Check if there are any responses for the current category
          if (filteredResponses && filteredResponses.length > 0) {
            return (
              <div key={category.value}>
                <Accordion
                  className={styles.ResponseItem__accordion}
                  buttonClass={styles.ResponseItem__accordionButton}
                  startOpen
                  heading={
                    <Heading className={styles.ResponseItem__accordionTitle} headingLevel={'h3'}>
                      {category.label}
                    </Heading>
                  }
                >
                  {/* <span>{getTabContent()?.completed} completed</span> */}
                  {filteredResponses.map((response) => (
                    <ResponseAccordion key={response.id} startOpen responsesDetails={response} />
                  ))}
                </Accordion>
              </div>
            );
          }

          // Return null or an empty fragment if there are no responses for the category
          return null;
        })}
      </div>
    </div>
  );
};
