// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DhlF9_FFVF8WAFr4dG9q {
  display: flex;
  flex-direction: column;
  gap: 5rem;
  align-items: center;
  justify-content: center;
  margin: auto 0;
}

.iPDAbDz9No0c_p6r_1RL {
  color: var(--medium-purple);
  width: 10rem;
  height: 10rem;
}`, "",{"version":3,"sources":["webpack://./src/components/2-molecules/Cards/ErrorCard/ErrorCard.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,mBAAA;EACA,uBAAA;EACA,cAAA;AACF;;AAEA;EACE,2BAAA;EACA,YAAA;EACA,aAAA;AACF","sourcesContent":[".ErrorCard {\n  display: flex;\n  flex-direction: column;\n  gap: 5rem;\n  align-items: center;\n  justify-content: center;\n  margin: auto 0;\n}\n\n.ErrorCard__iconWrapper {\n  color: var(--medium-purple);\n  width: 10rem;\n  height: 10rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ErrorCard": `DhlF9_FFVF8WAFr4dG9q`,
	"ErrorCard__iconWrapper": `iPDAbDz9No0c_p6r_1RL`
};
export default ___CSS_LOADER_EXPORT___;
