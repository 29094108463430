import { NavLink } from 'react-router-dom';
import styles from './TopNavigation.module.scss';
import classNames from 'classnames';
import { Heading } from '@/components/1-atoms';

export interface TopNavigationProps {
  navigationList: { label: string; url: string }[];
  className?: string;
}

export const TopNavigation = ({ navigationList, className }: TopNavigationProps) => {
  return (
    <ul className={classNames(styles.TopNavigation, className)}>
      {navigationList.map((navItem, index) => (
        <li key={index} className={styles.TopNavigation__listItem}>
          <NavLink key={index} to={navItem.url} className={styles.TopNavigation__link}>
            <Heading className={styles.TopNavigation__linkHeading} headingLevel='h3'>
              {navItem.label}
            </Heading>
          </NavLink>
        </li>
      ))}
    </ul>
  );
};
