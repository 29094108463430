import { Heading, Paragraph } from '@/components/1-atoms';
import styles from './TaskCard.module.scss';
import { formatDateToDayAndYear } from '@/utilities/dateHandler';
import { ReactComponent as CalendarIcon } from '@/assets/Icons/icon-calendar.svg';
import { ReactComponent as BulletListIcon } from '@/assets/Icons/icon-bullet-list.svg';

type TaskCardProps = {
  task: TenderTasks;
};

export const TaskCard = ({ task }: TaskCardProps) => {
  const { title, end, eventType } = task;

  return (
    <div className={styles.TaskCard}>
      <div className={styles.TaskCard__row}>
        <Heading headingLevel='h3'>{title}</Heading>
        <div className={styles.TaskCard__statusWrapper}>
          <Paragraph className={styles.TaskCard__statusHeading}> Status </Paragraph>
          <Paragraph className={styles.TaskCard__status}> Need to respond </Paragraph>
        </div>
      </div>

      <div className={styles.TaskCard__row}>
        <div className={styles.TaskCard__taskDetails}>
          <div className={styles.TaskCard__detailWrapper}>
            <CalendarIcon />
            <Paragraph className={styles.TaskCard__detailHeading}>
              <strong>End Date: </strong>
            </Paragraph>
            <Paragraph className={styles.TaskCard__detail}>
              {formatDateToDayAndYear(new Date(end))}
            </Paragraph>
          </div>

          <div className={styles.TaskCard__detailWrapper}>
            <BulletListIcon />
            <Paragraph className={styles.TaskCard__detailHeading}>
              <span className={styles.TaskCard__detailIcon}></span>
              <strong>Tender Type:</strong>
            </Paragraph>
            <Paragraph className={styles.TaskCard__detail}>Phone Contracts</Paragraph>
          </div>
        </div>
        <Paragraph className={styles.TaskCard__eventType}>{eventType + 's'}</Paragraph>
      </div>
    </div>
  );
};
