import { createSlice } from '@reduxjs/toolkit';
import { mockedSuppliersList } from './constants/suppliers';

export interface SuppliersState {
  suppliersList: Supplier[];
}

const initialState: SuppliersState = {
  suppliersList: [],
};

export const suppliersSlice = createSlice({
  name: 'suppliers',
  initialState,
  reducers: {
    getSuppliersList: (state: SuppliersState) => {
      state.suppliersList = mockedSuppliersList;
    },
  },
});

export const { getSuppliersList } = suppliersSlice.actions;
export default suppliersSlice.reducer;
