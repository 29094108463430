// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hip2AzToYNn_FnOt_mRl {
  padding: 8rem 5rem 3rem 5rem;
}

.Sxo2s_3WJXXNmpTPlbeb {
  margin: 4.5rem 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

.OMS2TCJeKTpCoF4zOwal {
  display: flex;
  gap: 5rem;
}

.WJPwjvxV_xnr1lb0HeZ5 {
  width: 50%;
}`, "",{"version":3,"sources":["webpack://./src/components/5-pages/leads/ContractsOverviewPage/ContractsOverviewPage.module.scss"],"names":[],"mappings":"AAAA;EACE,4BAAA;AACF;;AAEA;EACE,gBAAA;EACA,WAAA;EACA,aAAA;EACA,uBAAA;AACF;;AAEA;EACE,aAAA;EACA,SAAA;AACF;;AAEA;EACE,UAAA;AACF","sourcesContent":[".ContractsOverviewPage {\n  padding: 8rem 5rem 3rem 5rem;\n}\n\n.ContractsOverviewPage__createTenderCta {\n  margin: 4.5rem 0;\n  width: 100%;\n  display: flex;\n  justify-content: center;\n}\n\n.ContractsOverviewPage__eventsSection {\n  display: flex;\n  gap: 5rem;\n}\n\n.ContractsOverviewPage__calendar {\n  width: 50%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ContractsOverviewPage": `hip2AzToYNn_FnOt_mRl`,
	"ContractsOverviewPage__createTenderCta": `Sxo2s_3WJXXNmpTPlbeb`,
	"ContractsOverviewPage__eventsSection": `OMS2TCJeKTpCoF4zOwal`,
	"ContractsOverviewPage__calendar": `WJPwjvxV_xnr1lb0HeZ5`
};
export default ___CSS_LOADER_EXPORT___;
