import { Middleware, configureStore } from '@reduxjs/toolkit';
import rootReducer from './rootReducer';
import { loadStateFromLocalStorage, saveStateToLocalStorage } from '@/utilities/localStorageUtils';
import { loadStateFromCookie, saveStateToCookie } from '@/utilities/cookieUtils';

const preloadedState = {
  user: { ...loadStateFromCookie() },
  ...loadStateFromLocalStorage(),
};

const persistenceMiddleware: Middleware = (store) => (next) => (action) => {
  const result = next(action);
  const state = store.getState() as RootState;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { user, pageStatus, header, ...restState } = state;

  saveStateToCookie(user, new Date(user.tokenExpirationDate));
  saveStateToLocalStorage(restState);
  return result;
};

export const store = configureStore({
  reducer: rootReducer,
  preloadedState,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(persistenceMiddleware),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
