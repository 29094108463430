// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LpywyHG1E46zPB5f07UK {
  padding: 13rem 5rem 3rem 5rem;
}

.ORxmsXAsaE6S1edu0vnc {
  margin-top: 4.5rem;
  display: flex;
  gap: 5rem;
}

.lEK56Mmin0atMr163cvM {
  width: 50%;
}`, "",{"version":3,"sources":["webpack://./src/components/5-pages/leads/DashBoardPage/DashBoardPage.module.scss"],"names":[],"mappings":"AAAA;EACE,6BAAA;AACF;;AAEA;EACE,kBAAA;EACA,aAAA;EACA,SAAA;AACF;;AAEA;EACE,UAAA;AACF","sourcesContent":[".DashBoardPage {\n  padding: 13rem 5rem 3rem 5rem;\n}\n\n.DashBoardPage__eventsSection {\n  margin-top: 4.5rem;\n  display: flex;\n  gap: 5rem;\n}\n\n.DashBoardPage__calendar {\n  width: 50%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"DashBoardPage": `LpywyHG1E46zPB5f07UK`,
	"DashBoardPage__eventsSection": `ORxmsXAsaE6S1edu0vnc`,
	"DashBoardPage__calendar": `lEK56Mmin0atMr163cvM`
};
export default ___CSS_LOADER_EXPORT___;
