import classNames from 'classnames';
import { AnchorLink, Image, Heading, Paragraph, TextBox } from '@/components/1-atoms';
import styles from './ResponsesList.module.scss';
import { Accordion, Button, DataTable, IconButton } from '@/components/2-molecules';
import { useMemo, useState } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { DataTableCheckbox } from '@/components/2-molecules/DataTable';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as MoveForwardIcon } from '@/assets/Icons/icon-move-forward.svg';
import { ReactComponent as RemoveProfileIcon } from '@/assets/Icons/icon-remove-profile.svg';
import EmptyTasksImage from '@/assets/Images/image-empty-tasks.svg';

export interface ResponsesListProps {
  className?: string;
  phases?: Phase[];
}

export const ResponsesList = ({ className, phases = [] }: ResponsesListProps) => {
  const [selectedRows, setSelectedRows] = useState<PhaseResponse[]>([]);

  const navigate = useNavigate();

  const ResponsesListColumns = useMemo<ColumnDef<PhaseResponse>[]>(
    () => [
      {
        id: 'select',
        header: ({ table }) => (
          <div className={styles.ResponsesList__supplierWrapper}>
            <DataTableCheckbox
              checked={table.getIsAllRowsSelected()}
              onChange={table.getToggleAllRowsSelectedHandler()}
            />
            <p>SUPPLIER</p>
          </div>
        ),
        cell: ({ row }) => (
          <div className={styles.ResponsesList__supplierWrapper}>
            <DataTableCheckbox
              checked={row.getIsSelected()}
              disabled={!row.getCanSelect()}
              onChange={row.getToggleSelectedHandler()}
            />
            <p>{row.original.supplierName}</p>
          </div>
        ),
      },

      {
        header: 'STATUS',
        accessorKey: 'status',
      },

      {
        header: 'SCORE',
        accessorKey: 'score',
      },
      {
        header: 'ACTIONS',
        cell: ({ row }) => {
          return (
            <button
              className={styles.ResponsesList__viewOfferButton}
              onClick={() => navigate(row.original.id)}
            >
              <Paragraph>View offer</Paragraph>
            </button>
          );
        },
      },
    ],
    [],
  );

  const getSelectedRows = (selectRows: PhaseResponse[]) => setSelectedRows(selectRows);

  return (
    <div className={classNames(styles.ResponsesList, className)}>
      {phases && phases[0]?.responses ? (
        <>
          <div className={styles.ResponsesList__compareAnswersCta}>
            <Button type={'primary'} onClick={() => navigate('compareResponses')}>
              Compare answers
            </Button>
          </div>

          {phases?.map((phase, index) => (
            <Accordion
              key={index}
              clickable={phase.responses?.length ? true : false}
              startOpen={index === 0}
              heading={
                <div className={styles.ResponsesList__accordionHeadingWrapper}>
                  <Heading headingLevel={'h3'}>{phase.name}</Heading>

                  <div className={styles.ResponsesList__stats}>
                    <Heading headingLevel={'h3'} className={styles.ResponsesList__statsHeading}>
                      Total suppliers: {phase?.responses?.length ?? 0}
                    </Heading>
                    <Heading headingLevel={'h3'} className={styles.ResponsesList__statsHeading}>
                      Completed offers:{' '}
                      {phase?.responses?.filter((response) => response?.status === 'completed')
                        ?.length ?? 0}
                    </Heading>
                    <Heading headingLevel={'h3'} className={styles.ResponsesList__statsHeading}>
                      Incomplete offers:{' '}
                      {phase?.responses?.filter((response) => response?.status === 'incomplete')
                        ?.length ?? 0}
                    </Heading>
                  </div>
                </div>
              }
            >
              <>
                <div className={styles.ResponsesList__phaseActions}>
                  <Paragraph>
                    {selectedRows?.length ?? 0} of {phase?.responses?.length} selected
                  </Paragraph>
                  <div className={styles.ResponsesList__phaseActionsButtons}>
                    <IconButton icon={<MoveForwardIcon />}>Move supplier to next phase</IconButton>
                    <IconButton icon={<RemoveProfileIcon />}> Reject bid </IconButton>
                  </div>
                </div>
                <DataTable columns={ResponsesListColumns} data={phase?.responses || []} />
              </>
            </Accordion>
          ))}
        </>
      ) : (
        <div className={styles.ResponsesList__emptyList}>
          <Image src={EmptyTasksImage} alt={'Empty Tasks Image'} />
          <TextBox
            title={
              <Heading headingLevel={'h3'} className={styles.ResponsesList__emptyListHeading}>
                No responses yet
              </Heading>
            }
            description={
              <>
                Stay tuned! For every new response you will
                <br /> get a notification on the <AnchorLink link={''}>Dashboard.</AnchorLink>
              </>
            }
            alignment={'centre'}
          />
        </div>
      )}
    </div>
  );
};
