// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.F_up7bd4d33tl7hcyBCE {
  display: block;
  position: relative;
  padding-left: 3.5rem;
  margin-bottom: 1.2rem;
  cursor: pointer;
  font-size: 2.2rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.Mj6NZSzWqVruBzdsgwgj {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.Mj6NZSzWqVruBzdsgwgj:checked ~ .oNe_fyNacd4oaOlnHico {
  background-color: var(--accent-color);
  border-color: transparent;
}
.Mj6NZSzWqVruBzdsgwgj:checked ~ .oNe_fyNacd4oaOlnHico::after {
  display: block;
}

.oNe_fyNacd4oaOlnHico {
  position: absolute;
  top: 0;
  left: 0;
  height: 1.8rem;
  width: 1.8rem;
  background-color: transparent;
  border-radius: 0.5rem;
  border: 1px solid var(--dark-grey-600);
}
.oNe_fyNacd4oaOlnHico::after {
  content: "";
  position: absolute;
  left: 0.6rem;
  top: 0.3rem;
  width: 0.4rem;
  height: 0.8rem;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}`, "",{"version":3,"sources":["webpack://./src/components/2-molecules/DataTable/DataTableCheckbox/DataTableCheckbox.module.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,kBAAA;EACA,oBAAA;EACA,qBAAA;EACA,eAAA;EACA,iBAAA;EACA,yBAAA;EACA,sBAAA;EACA,qBAAA;EACA,iBAAA;AACF;;AAEA;EACE,kBAAA;EACA,UAAA;EACA,eAAA;EACA,SAAA;EACA,QAAA;AACF;AACE;EACE,qCAAA;EACA,yBAAA;AACJ;AAAI;EACE,cAAA;AAEN;;AAGA;EACE,kBAAA;EACA,MAAA;EACA,OAAA;EACA,cAAA;EACA,aAAA;EACA,6BAAA;EACA,qBAAA;EACA,sCAAA;AAAF;AAEE;EACE,WAAA;EACA,kBAAA;EACA,YAAA;EACA,WAAA;EACA,aAAA;EACA,cAAA;EACA,mBAAA;EACA,yBAAA;EACA,gCAAA;EACA,4BAAA;EACA,wBAAA;AAAJ","sourcesContent":[".DataTableCheckbox {\n  display: block;\n  position: relative;\n  padding-left: 3.5rem;\n  margin-bottom: 1.2rem;\n  cursor: pointer;\n  font-size: 2.2rem;\n  -webkit-user-select: none;\n  -moz-user-select: none;\n  -ms-user-select: none;\n  user-select: none;\n}\n\n.DataTableCheckbox__input {\n  position: absolute;\n  opacity: 0;\n  cursor: pointer;\n  height: 0;\n  width: 0;\n\n  &:checked ~ .DataTableCheckbox__checkMark {\n    background-color: var(--accent-color);\n    border-color: transparent;\n    &::after {\n      display: block;\n    }\n  }\n}\n\n.DataTableCheckbox__checkMark {\n  position: absolute;\n  top: 0;\n  left: 0;\n  height: 1.8rem;\n  width: 1.8rem;\n  background-color: transparent;\n  border-radius: 0.5rem;\n  border: 1px solid var(--dark-grey-600);\n\n  &::after {\n    content: '';\n    position: absolute;\n    left: 0.6rem;\n    top: 0.3rem;\n    width: 0.4rem;\n    height: 0.8rem;\n    border: solid white;\n    border-width: 0 2px 2px 0;\n    -webkit-transform: rotate(45deg);\n    -ms-transform: rotate(45deg);\n    transform: rotate(45deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"DataTableCheckbox": `F_up7bd4d33tl7hcyBCE`,
	"DataTableCheckbox__input": `Mj6NZSzWqVruBzdsgwgj`,
	"DataTableCheckbox__checkMark": `oNe_fyNacd4oaOlnHico`
};
export default ___CSS_LOADER_EXPORT___;
