import { Header, SideNavigation } from '@/components/3-organisms';
import styles from './TendersLayout.module.scss';

import { ReactComponent as DashboardIcon } from '@/assets/Icons/icon-dashboard.svg';
import { ReactComponent as TendersIcon } from '@/assets/Icons/icon-tenders.svg';
import { ReactComponent as ContractsIcon } from '@/assets/Icons/icon-contracts.svg';
import { ReactComponent as SuppliersIcon } from '@/assets/Icons/icon-suppliers.svg';
import { ReactComponent as SettingsIcon } from '@/assets/Icons/icon-settings.svg';
import { ErrorCard, LoadingCard, SuccessCard } from '@/components/2-molecules';
import { useAppSelector } from '@/store';
import { Heading, Paragraph } from '@/components/1-atoms';

interface TendersLayoutProps {
  children: React.ReactNode;
}

export const TendersLayout = ({ children }: TendersLayoutProps) => {
  const { isLoading, isError, isSuccess } = useAppSelector((state) => state.pageStatus);

  return (
    <div className={styles.Layout}>
      <SideNavigation
        navigationLinks={[
          { text: 'Dashboard', href: '/tenders/dashboard', Icon: <DashboardIcon /> },
          {
            text: 'Tenders',
            href: '/tenders/tendersOverview',
            Icon: <TendersIcon />,
            subLinks: [{ text: 'New tender', href: '/tenders/tendersOverview/new-tender' }],
          },
          {
            text: 'Contracts',
            href: '/tenders/contracts',
            Icon: <ContractsIcon />,
            subLinks: [
              { text: 'Add new contract', href: '/tenders/contracts/add-new-contract' },
              { text: 'Challenge contract', href: '/tenders/contracts/challenge-contract' },
            ],
          },
          {
            text: 'Suppliers',
            href: '/tenders/suppliers',
            Icon: <SuppliersIcon />,
            subLinks: [{ text: 'New supplier', href: '/tenders/suppliers/new-supplier' }],
          },
          { text: 'Settings', href: '/tenders/settings', Icon: <SettingsIcon /> },
        ]}
      />
      <main className={styles.Layout__contentWrapper}>
        <Header />
        {children}
        {isLoading && <LoadingCard />}

        {isError && (
          <ErrorCard>
            <Paragraph> Something went wrong</Paragraph>
          </ErrorCard>
        )}

        {isSuccess && (
          <SuccessCard>
            <Heading headingLevel='h3'>Success!</Heading>
          </SuccessCard>
        )}
      </main>
    </div>
  );
};
