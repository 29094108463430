// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Z4TsSbACf00MBDThnIhm {
  padding: 13rem 5rem 3rem 5rem;
}

.TXW0AcCzybw0IWpq6bgW {
  margin: 4.5rem 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

.bDhTPve1PQ6RQi9ZCzf9 {
  display: flex;
  gap: 5rem;
}

.DgoJ8vbUY7WVoBlhjQc9 {
  width: 50%;
}`, "",{"version":3,"sources":["webpack://./src/components/5-pages/tenders/DashBoardPage/DashBoardPage.module.scss"],"names":[],"mappings":"AAAA;EACE,6BAAA;AACF;;AAEA;EACE,gBAAA;EACA,WAAA;EACA,aAAA;EACA,uBAAA;AACF;;AAEA;EACE,aAAA;EACA,SAAA;AACF;;AAEA;EACE,UAAA;AACF","sourcesContent":[".DashBoardPage {\n  padding: 13rem 5rem 3rem 5rem;\n}\n\n.DashBoardPage__createTenderCta {\n  margin: 4.5rem 0;\n  width: 100%;\n  display: flex;\n  justify-content: center;\n}\n\n.DashBoardPage__eventsSection {\n  display: flex;\n  gap: 5rem;\n}\n\n.DashBoardPage__calendar {\n  width: 50%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"DashBoardPage": `Z4TsSbACf00MBDThnIhm`,
	"DashBoardPage__createTenderCta": `TXW0AcCzybw0IWpq6bgW`,
	"DashBoardPage__eventsSection": `bDhTPve1PQ6RQi9ZCzf9`,
	"DashBoardPage__calendar": `DgoJ8vbUY7WVoBlhjQc9`
};
export default ___CSS_LOADER_EXPORT___;
