// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.t0exvzb5gYgZQgGtsWj2 {
  height: 100%;
}

.hAhXBOtuW5v1_uKx439C {
  margin-bottom: 4rem;
}

.hKtO7mo5Vm6wIfCtM9Q8 {
  display: flex;
  flex-flow: column;
  gap: 4rem;
}

.x6OjBdafdFruV31M1k7x {
  margin-bottom: 4rem;
}

.iNwMDbg4v3BUvf2ssMgi {
  display: flex;
  width: 100%;
  gap: 4rem;
}

.xJF5Ulw9Jfp59WyhMZrQ {
  display: flex;
  width: calc(50% - 2rem);
}

.NESoQG4VvL0jIWVyn9Lu {
  margin: 7rem 0 auto 0;
  align-self: center;
  display: flex;
  gap: 2rem;
}`, "",{"version":3,"sources":["webpack://./src/components/3-organisms/SettingsOverview/CreateCompanyForm/CreateCompanyForm.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;;AAEA;EACE,mBAAA;AACF;;AAEA;EACE,aAAA;EACA,iBAAA;EACA,SAAA;AACF;;AAEA;EACE,mBAAA;AACF;;AAEA;EACE,aAAA;EACA,WAAA;EACA,SAAA;AACF;;AAEA;EACE,aAAA;EACA,uBAAA;AACF;;AAEA;EACE,qBAAA;EACA,kBAAA;EACA,aAAA;EACA,SAAA;AACF","sourcesContent":[".CreateCompanyForm {\n  height: 100%;\n}\n\n.CreateCompanyForm__pageTitle {\n  margin-bottom: 4rem;\n}\n\n.CreateCompanyForm__form {\n  display: flex;\n  flex-flow: column;\n  gap: 4rem;\n}\n\n.CreateCompanyForm__avatarInput {\n  margin-bottom: 4rem;\n}\n\n.CreateCompanyForm__row {\n  display: flex;\n  width: 100%;\n  gap: 4rem;\n}\n\n.CreateCompanyForm__lastRow {\n  display: flex;\n  width: calc(50% - 2rem);\n}\n\n.CreateCompanyForm__cta {\n  margin: 7rem 0 auto 0; // TODO: align on this value\n  align-self: center;\n  display: flex;\n  gap: 2rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CreateCompanyForm": `t0exvzb5gYgZQgGtsWj2`,
	"CreateCompanyForm__pageTitle": `hAhXBOtuW5v1_uKx439C`,
	"CreateCompanyForm__form": `hKtO7mo5Vm6wIfCtM9Q8`,
	"CreateCompanyForm__avatarInput": `x6OjBdafdFruV31M1k7x`,
	"CreateCompanyForm__row": `iNwMDbg4v3BUvf2ssMgi`,
	"CreateCompanyForm__lastRow": `xJF5Ulw9Jfp59WyhMZrQ`,
	"CreateCompanyForm__cta": `NESoQG4VvL0jIWVyn9Lu`
};
export default ___CSS_LOADER_EXPORT___;
