import classNames from 'classnames';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useEffect, useState } from 'react';

import { Heading, Paragraph } from '@/components/1-atoms';
import {
  Accordion,
  CategoriesSelect,
  ErrorCard,
  FormFieldSelect,
  IconButton,
  PopupCard,
} from '@/components/2-molecules';
import { ReactComponent as PlusIcon } from '@/assets/Icons/icon-plus.svg';
import { ReactComponent as TrashIcon } from '@/assets/Icons/icon-trash.svg';
import { ReactComponent as TrashIconGrey } from '@/assets/Icons/icon-trash-grey.svg';
import { ReactComponent as PreviewIcon } from '@/assets/Icons/icon-preview.svg';

import styles from './DocumentsForm.module.scss';
import { UploadPhaseDocument, fetchPhaseDocument } from '@/services/TenderServices';
import { categories as categoriesData } from '@/constants/categories';
import { useAppSelector } from '@/store';

export interface DocumentsFormProps {
  className?: string;
  currentPhaseName: string;
}

export const DocumentsForm = ({ className, currentPhaseName }: DocumentsFormProps) => {
  const nestedFormName = `${currentPhaseName}.documents`;
  const [filePreview, setFilePreview] = useState<string>();
  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const { control, getValues } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: nestedFormName,
  });

  const [categories, setCategories] = useState<Category[]>([...categoriesData]);

  const tenderId = useAppSelector((state) => state?.createTender?.tenderId);
  const contactId = useAppSelector((state) => state?.profile?.contact?.id);

  const options = [
    { value: 1, label: 'Sign' },
    { value: 2, label: 'Accept' },
    { value: 3, label: 'Read' },
  ];

  const submitCategories = (list: Category[]) => {
    fields.forEach((field, index) => {
      const category = list.find((category) => category.value === field.category);
      if (!category?.checked) remove(index);
    });

    setCategories(list);
  };

  useEffect(() => {
    fields.forEach((field) => {
      const category = categories.find((category) => category.value === field.category);

      if (!category?.checked)
        setCategories((prev) => {
          return prev.map((prevCategory) => {
            if (prevCategory.value === category?.value) {
              return { ...prevCategory, checked: true };
            }
            return prevCategory;
          });
        });
    });
  }, []);

  const filesSubmitHandler = async (
    e: React.ChangeEvent<HTMLInputElement>,
    categoryNumber: number,
  ) => {
    const file = e.target.files ?? null;

    console.log('before file', file);
    if (!file?.length || !file) {
      console.log('error file?.length', file?.length);
      console.log('error file', file);
      return;
    }
    const fileType = file[0].name.split('.').pop();

    if (
      fileType !== 'pdf' &&
      fileType !== 'doc' &&
      fileType !== 'docx' &&
      fileType !== 'xls' &&
      fileType !== 'xlsx'
    ) {
      console.error('error fileType', fileType);
      // setIsError(true);
      return;
    }
    // TODO: uploadFile the file get ID and maybe url
    // TODO: Default type

    const phaseId = getValues(`${currentPhaseName}.phaseId`);

    if (!tenderId || !phaseId || !contactId) {
      // setIsError(true);
      console.error('error missing data', tenderId, phaseId, contactId);

      return;
    }

    uploadDocument(
      file[0],
      categoryNumber.toString(),
      phaseId,
      tenderId.toString(),
      contactId.toString(),
    );

    // const docFile = file[0];
    // const formData = new FormData();
    // await formData.append('File', docFile);
    // await formData.append('AcceptanceOptionId', '1');
    // await formData.append('documentCategoryId', categoryNumber.toString());
    // await formData.append('tenderID', tenderId.toString());
    // await formData.append('phaseID', phaseId.toString());
    // await formData.append('contactId', contactId.toString());

    // console.log('formData', formData.get('File'));
    // console.log('formData', formData.get('AcceptanceOptionId'));
    // console.log('formData', formData.get('documentCategoryId'));
    // console.log('formData', formData.get('tenderID'));
    // console.log('formData', formData.get('phaseID'));
    // console.log('formData', formData.get('contactId'));

    // try {
    //   const uploadFile = await UploadPhaseDocument(formData);
    //   console.log('uploadFile', uploadFile);
    // } catch (error) {
    //   console.log('error', error);
    // }

    // append({
    //   id: randomId(),
    //   name: file[0].name,
    //   category: categoryNumber,
    //   type: '',
    // });
  };

  const uploadDocument = async (
    fileStream: File,
    categoryNumber: string,
    phaseId: string,
    tenderId: string,
    contactId: string,
  ) => {
    const formData = new FormData();
    formData.append('File', fileStream);
    await formData.append('AcceptanceOptionId', '1');
    await formData.append('documentCategoryId', categoryNumber);
    await formData.append('tenderID', tenderId);
    await formData.append('phaseID', phaseId);
    await formData.append('contactId', contactId);

    try {
      const documentId = await UploadPhaseDocument(formData);

      append({
        documentId: documentId,
        name: fileStream.name,
        category: categoryNumber,
        type: '',
      });
    } catch (error) {
      console.log('error', error);
    }
  };

  const previewFile = async (documentId: number) => {
    const documentLink = await fetchPhaseDocument(documentId);
    console.log('documentLink', documentLink);
    // console.log('documentLink.name', documentLink.name);

    // const blob = new Blob(documentLink)

    // const url = URL.createObjectURL(documentLink);

    // console.log('url', url);
    // await setFilePreview(documentLink);
    // await setOpenPopup(true);
  };

  return (
    <div className={classNames(styles.DocumentsForm, className)}>
      {categories &&
        categories
          .filter((category) => category.checked)
          .map((category, index) => (
            <Accordion
              key={index}
              startOpen
              className={styles.DocumentsForm__category}
              buttonClass={styles.DocumentsForm__accordionButton}
              heading={
                <div className={styles.DocumentsForm__headingWrapper}>
                  <div className={styles.DocumentsForm__heading}>
                    <Heading className={styles.DocumentsForm__title} headingLevel={'h3'}>
                      {index + 1}. {category.label}
                    </Heading>

                    <IconButton
                      icon={<TrashIconGrey />}
                      onClick={() => {
                        setCategories((prev) => {
                          return prev.map((item) => {
                            if (item.value === category.value) return { ...item, checked: false };
                            return item;
                          });
                        });
                      }}
                    ></IconButton>
                  </div>

                  <label
                    className={styles.DocumentsForm__addDocuments}
                    htmlFor={`${nestedFormName}_fileInput_${category.value}`}
                  >
                    <>
                      <PlusIcon />
                      <Paragraph>Add documents</Paragraph>
                    </>
                  </label>

                  <input
                    className={styles.DocumentsForm__fileInput}
                    type={'file'}
                    accept={'.doc, .docx, .pdf, .xls, .xlsx'}
                    onChange={(e) => filesSubmitHandler(e, category.value)}
                    id={`${nestedFormName}_fileInput_${category.value}`}
                  />
                </div>
              }
            >
              <ul className={styles.DocumentsForm__list} onClick={(e) => e.stopPropagation()}>
                {fields.map((field, index) => {
                  if (field.category == category.value)
                    return (
                      <li key={index} className={styles.DocumentsForm__listItem}>
                        <div className={styles.DocumentsForm__ItemInfo}>
                          <Paragraph>{field.name}</Paragraph>
                          <span
                            className={styles.DocumentsForm__preview}
                            onClick={() => previewFile(field.documentId)}
                          >
                            <PreviewIcon />
                          </span>
                        </div>

                        <div className={styles.DocumentsForm__ItemActions}>
                          <Controller
                            control={control}
                            name={`${nestedFormName}.${index}.type`}
                            render={({ field: { onChange, value } }) => (
                              <FormFieldSelect
                                className={styles.DocumentsForm__actionSelect}
                                id='documentAction'
                                placeholder='Choose action'
                                state={{ required: true }}
                                options={options}
                                onChange={(value) => onChange(value)}
                                defaultValue={value}
                              />
                            )}
                          />

                          <IconButton icon={<TrashIcon />} onClick={() => remove(index)} />
                        </div>
                      </li>
                    );
                })}
              </ul>
            </Accordion>
          ))}

      <CategoriesSelect
        className={styles.DocumentsForm__categoriesSelect}
        categoriesList={categories}
        submitCategories={(list) => submitCategories(list)}
      />

      {isError && (
        <ErrorCard>
          <Paragraph> Something went wrong</Paragraph>
        </ErrorCard>
      )}

      {openPopup && (
        <PopupCard onCloseClick={() => setOpenPopup(false)} className={styles.DocumentsForm__popup}>
          {filePreview && (
            <iframe
              // title={filePreview.fileName}
              className={styles.DocumentsForm__popupIframe}
              src={'https://view.officeapps.live.com/op/view.aspx?src=' + filePreview}
            />
          )}
        </PopupCard>
      )}
    </div>
  );
};

{
  /*


    <DocViewer
              documents={[
                {
                  uri: filePreview.filePath,
                  fileName: filePreview.fileName,
                  // fileType: filePreview.fileType,
                },
              ]}
              pluginRenderers={DocViewerRenderers}
            />



*/
}
