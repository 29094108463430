import { Heading, Image } from '@/components/1-atoms';
import classNames from 'classnames';
import styles from './WelcomeCard.module.scss';
import WelcomeImage from '@/assets/Images/image-welcome.svg';

export interface WelcomeCardProps {
  className?: string;
  title: string;
  children?: React.ReactNode;
}

export const WelcomeCard = ({ className, title, children }: WelcomeCardProps) => {
  return (
    <div className={classNames(styles.WelcomeCard, className)}>
      <Heading className={styles.WelcomeCard__heading} headingLevel='h2'>
        {title}
      </Heading>
      {children}
      <Image src={WelcomeImage} alt={'welcome Image'} className={styles.WelcomeCard__image} />
    </div>
  );
};
