import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import App from './App';
import Auth0Wrapper from './auth0';
import { store } from './store';
import ErrorBoundary from './errorBoundary';

ReactDOM.createRoot(document.getElementById('app') as HTMLElement).render(
  <ErrorBoundary fallback={'Error Page'}>
    <Provider store={store}>
      <Auth0Wrapper>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Auth0Wrapper>
    </Provider>
  </ErrorBoundary>,
);
