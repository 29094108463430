// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.z1CGDL8PPNfuRv_SL5uB {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: var(--primary-border);
  padding-bottom: 2rem;
}

.iXydgoRGWTtEWhWCJjEz {
  display: grid;
  grid-template-columns: 4.5rem repeat(2, fit-content(100%));
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 0.5rem;
  grid-row-gap: 1rem;
  align-items: center;
}

.HMY92aqbHiVZncnV2nok {
  grid-area: 1/1/3/2;
  align-self: flex-start;
}

.cOvpJupyWS81XrF5oorg {
  margin-top: 1rem;
}

.HcQa4SGomiBfUMhW5vH6 {
  grid-area: 2/2/3/4;
}

.OCdSwRcVAsqcXsH42nAo {
  display: flex;
  align-items: center;
  flex-flow: row;
  gap: 2rem;
}

.qdHu9cyZ7V1_4dLFTcxC {
  border: var(--primary-border);
  border-radius: 0.2rem;
  padding: 1rem 1.2rem 1rem 1rem;
}

.R3kUVXxDDOTA7RLMsHoZ {
  border-color: var(--accent-color);
  background: var(--accent-color);
}
.R3kUVXxDDOTA7RLMsHoZ h3 {
  color: var(--white);
}`, "",{"version":3,"sources":["webpack://./src/components/3-organisms/TenderDetails/TenderContract/ContractItem/ContractItem.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,8BAAA;EACA,oCAAA;EACA,oBAAA;AACF;;AAEA;EACE,aAAA;EACA,0DAAA;EACA,kCAAA;EACA,uBAAA;EACA,kBAAA;EACA,mBAAA;AACF;;AAEA;EACE,kBAAA;EACA,sBAAA;AACF;;AACA;EACE,gBAAA;AAEF;;AAAA;EACE,kBAAA;AAGF;;AAAA;EACE,aAAA;EACA,mBAAA;EACA,cAAA;EACA,SAAA;AAGF;;AAAA;EACE,6BAAA;EACA,qBAAA;EACA,8BAAA;AAGF;;AAAA;EACE,iCAAA;EACA,+BAAA;AAGF;AAFE;EACE,mBAAA;AAIJ","sourcesContent":[".ContractItem {\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n  border-bottom: var(--primary-border);\n  padding-bottom: 2rem;\n}\n\n.ContractItem__headingWrapper {\n  display: grid;\n  grid-template-columns: 4.5rem repeat(2, fit-content(100%));\n  grid-template-rows: repeat(2, 1fr);\n  grid-column-gap: 0.5rem;\n  grid-row-gap: 1rem;\n  align-items: center;\n}\n\n.ContractItem__icon {\n  grid-area: 1/1/3/2;\n  align-self: flex-start;\n}\n.ContractItem__status {\n  margin-top: 1rem;\n}\n.ContractItem__date {\n  grid-area: 2 / 2 / 3 / 4;\n}\n\n.ContractItem__actionsWrapper {\n  display: flex;\n  align-items: center;\n  flex-flow: row;\n  gap: 2rem;\n}\n\n.ContractItem__actionButton {\n  border: var(--primary-border);\n  border-radius: 0.2rem;\n  padding: 1rem 1.2rem 1rem 1rem;\n}\n\n.ContractItem__activeButton {\n  border-color: var(--accent-color);\n  background: var(--accent-color);\n  h3 {\n    color: var(--white);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ContractItem": `z1CGDL8PPNfuRv_SL5uB`,
	"ContractItem__headingWrapper": `iXydgoRGWTtEWhWCJjEz`,
	"ContractItem__icon": `HMY92aqbHiVZncnV2nok`,
	"ContractItem__status": `cOvpJupyWS81XrF5oorg`,
	"ContractItem__date": `HcQa4SGomiBfUMhW5vH6`,
	"ContractItem__actionsWrapper": `OCdSwRcVAsqcXsH42nAo`,
	"ContractItem__actionButton": `qdHu9cyZ7V1_4dLFTcxC`,
	"ContractItem__activeButton": `R3kUVXxDDOTA7RLMsHoZ`
};
export default ___CSS_LOADER_EXPORT___;
