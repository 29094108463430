import classNames from 'classnames';
import { Heading, Paragraph } from '@/components/1-atoms';
import styles from './ResponseCompareAccordion.module.scss';
import { Accordion, IconButton } from '@/components/2-molecules';
import { ReactComponent as AddNoteIcon } from '@/assets/Icons/icon-add-file.svg';
import { ReactComponent as DownloadIcon } from '@/assets/Icons/icon-download.svg';

export interface ResponseCompareAccordionProps {
  className?: string;
  heading?: React.ReactNode;
  startOpen?: boolean;
  question?: string;
  responsesList?: {
    id: string;
    supplierName: string;
    supplierId: string;
    answer?: string;
    score?: string;
    note?: string;
    url?: string;
    fileName?: string;
  }[];
}

export const ResponseCompareAccordion = ({
  className,
  startOpen = false,
  responsesList,
  question,
  heading,
}: ResponseCompareAccordionProps) => {
  return (
    <div className={classNames(styles.ResponseCompareAccordion, className)}>
      <Accordion
        className={styles.ResponseCompareAccordion}
        buttonClass={styles.ResponseCompareAccordion__button}
        startOpen={startOpen}
        clickable={responsesList?.length && responsesList?.length > 0 ? true : false}
        heading={<div className={styles.ResponseCompareAccordion__heading}>{heading}</div>}
      >
        {question && (
          <Heading className={styles.ResponseCompareAccordion__question} headingLevel={'h3'}>
            {question}
          </Heading>
        )}
        {responsesList?.map((response) => (
          <div key={response.id} className={styles.ResponseCompareAccordion__content}>
            <div className={styles.ResponseCompareAccordion__supplierInfo}>
              <Paragraph>{response.supplierName}</Paragraph>
              <Paragraph>Score: {response.score}</Paragraph>
            </div>

            <div className={styles.ResponseCompareAccordion__response}>
              <Paragraph>{response.answer}</Paragraph>
              <div className={styles.ResponseCompareAccordion__footer}>
                {response?.url ? (
                  <IconButton icon={<DownloadIcon />}> Download</IconButton>
                ) : (
                  <span />
                )}
                <div className={styles.ResponseCompareAccordion__scoreWrapper}>
                  <Paragraph>Score:</Paragraph>
                  <input className={styles.ResponseCompareAccordion__scoreInput} type='text' />
                  <IconButton icon={<AddNoteIcon />} />
                </div>
              </div>
            </div>
          </div>
        ))}
      </Accordion>
    </div>
  );
};
