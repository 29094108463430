import classNames from 'classnames';
import styles from './SuccessCard.module.scss';
import { PopupCard } from '../PopupCard';
import { ReactComponent as SuccessIcon } from '@/assets/Icons/icon-success.svg';
export interface SuccessCardProps {
  className?: string;
  children?: React.ReactNode;
}

export const SuccessCard = ({ className, children }: SuccessCardProps) => {
  return (
    <PopupCard smallSize className={classNames(className, styles.SuccessCard)}>
      <span className={styles.SuccessCard__iconWrapper}>
        <SuccessIcon />
      </span>
      {children}
    </PopupCard>
  );
};
