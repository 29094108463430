import classNames from 'classnames';
import { SettingsItem, SettingPage } from './SettingsItem';
import styles from './SettingsList.module.scss';

export interface SettingsListProps {
  settingsPages: SettingPage[];
  className?: string;
}

export const SettingsList = ({ settingsPages, className }: SettingsListProps) => {
  return (
    <ol className={classNames(styles.SettingsList, className)}>
      {settingsPages &&
        settingsPages.map((item, index) => {
          return (
            <SettingsItem
              key={index}
              title={item.title}
              href={item.href}
              subTitle={item.subTitle}
            />
          );
        })}
    </ol>
  );
};
