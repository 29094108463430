// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.W0y6XuPnntNPMPGB4FLP {
  display: flex;
  gap: 2rem;
  align-items: center;
}

.LSdCed2PwOEaKB6JhklM {
  flex: 1;
}

.uRQbc2fA1qpCNb1gRIMj {
  flex: 1;
}

.wS4Sf1yXI7s6s1II86Bb {
  margin-bottom: 1rem;
}

.siLfeWepB2l8B8ZzqPL4 {
  flex: 1;
}

.Cziq4fs3gA1UXVwjimHs {
  background-color: transparent;
  color: var(--accent-color);
  border: 2px solid var(--accent-color);
  padding: 1.2rem 4.5rem;
  margin-top: 3rem;
}`, "",{"version":3,"sources":["webpack://./src/components/3-organisms/CreateTender/PhaseForm/PhaseContent/HeadingForm/HeadingForm.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,SAAA;EACA,mBAAA;AACF;;AAEA;EACE,OAAA;AACF;;AAEA;EACE,OAAA;AACF;;AAEA;EACE,mBAAA;AACF;;AAEA;EACE,OAAA;AACF;;AAEA;EACE,6BAAA;EACA,0BAAA;EACA,qCAAA;EACA,sBAAA;EACA,gBAAA;AACF","sourcesContent":[".HeadingForm {\n  display: flex;\n  gap: 2rem;\n  align-items: center;\n}\n\n.HeadingForm__input {\n  flex: 1;\n}\n\n.HeadingForm__select {\n  flex: 1;\n}\n\n.HeadingForm__title {\n  margin-bottom: 1rem;\n}\n\n.HeadingForm__radio {\n  flex: 1;\n}\n\n.HeadingForm__submitBtn {\n  background-color: transparent;\n  color: var(--accent-color);\n  border: 2px solid var(--accent-color);\n  padding: 1.2rem 4.5rem;\n  margin-top: 3rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"HeadingForm": `W0y6XuPnntNPMPGB4FLP`,
	"HeadingForm__input": `LSdCed2PwOEaKB6JhklM`,
	"HeadingForm__select": `uRQbc2fA1qpCNb1gRIMj`,
	"HeadingForm__title": `wS4Sf1yXI7s6s1II86Bb`,
	"HeadingForm__radio": `siLfeWepB2l8B8ZzqPL4`,
	"HeadingForm__submitBtn": `Cziq4fs3gA1UXVwjimHs`
};
export default ___CSS_LOADER_EXPORT___;
