import classNames from 'classnames';
import styles from './ErrorCard.module.scss';
import { PopupCard } from '../PopupCard';
import { ReactComponent as ErrorIcon } from '@/assets/Icons/icon-error.svg';
export interface ErrorCardProps {
  className?: string;
  children?: React.ReactNode;
}

export const ErrorCard = ({ className, children }: ErrorCardProps) => {
  return (
    <PopupCard smallSize className={classNames(className, styles.ErrorCard)}>
      <span className={styles.ErrorCard__iconWrapper}>
        <ErrorIcon />
      </span>
      {children}
    </PopupCard>
  );
};
