import classNames from 'classnames';
import styles from './PopupCard.module.scss';

export interface PopupCardProps {
  children: React.ReactNode;
  className?: string;
  onCloseClick?: () => void;
  smallSize?: boolean;
}

export const PopupCard = ({
  children,
  onCloseClick,
  className,
  smallSize = false,
}: PopupCardProps) => {
  return (
    <div onClick={onCloseClick && onCloseClick} className={styles.PopupCard}>
      <div
        onClick={(e) => e.stopPropagation()}
        className={classNames(
          smallSize && styles.PopupCard__content_small,
          styles.PopupCard__content,
          className,
        )}
      >
        {children}
      </div>
    </div>
  );
};
