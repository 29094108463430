import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table';
import styles from './DataTable.module.scss';
import classNames from 'classnames';
import { useState } from 'react';

export interface DataTableProps {
  className?: string;
  columns: any[];
  data: any[];
  onRowClick?: (row: any) => void;
}

export const DataTable = ({ className, columns, data, onRowClick }: DataTableProps) => {
  const [rowSelection, setRowSelection] = useState({});

  const table = useReactTable({
    data,
    columns,
    state: {
      rowSelection,
    },
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    // getFilteredRowModel: getFilteredRowModel(),
    debugTable: true,
  });

  return (
    <table className={classNames(styles.DataTable, className)}>
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id} className={styles.DataTable__tableRow}>
            {headerGroup.headers.map((header) => {
              return (
                <th
                  key={header.id}
                  colSpan={header.colSpan}
                  className={styles.DataTable__tableHeader}
                >
                  {header.isPlaceholder ? null : (
                    <>{flexRender(header.column.columnDef.header, header.getContext())}</>
                  )}
                </th>
              );
            })}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row) => {
          return (
            <tr
              key={row.id}
              className={styles.DataTable__tableRow}
              onClick={onRowClick ? () => onRowClick(row.original) : undefined}
            >
              {row.getVisibleCells().map((cell) => {
                return (
                  <td
                    className={classNames(
                      styles.DataTable__tableCell,
                      onRowClick && styles.DataTable__tableCell_clickable,
                    )}
                    key={cell.id}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
