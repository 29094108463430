// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pOs3cvyzwLUfgrFilmdb {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
}

.Gtf732o6OHJ42Dgs7FIy {
  background-color: var(--white);
  border-radius: 1.2rem;
  box-shadow: var(--primary-shadow);
  min-width: 74rem;
  min-height: 40rem;
  z-index: 6;
}

.uZHAPCu9NHftMdZTpZGu {
  min-width: 50rem;
  min-height: 30rem;
}`, "",{"version":3,"sources":["webpack://./src/components/2-molecules/Cards/PopupCard/PopupCard.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,aAAA;EACA,oCAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,UAAA;AACF;;AAEA;EACE,8BAAA;EACA,qBAAA;EACA,iCAAA;EACA,gBAAA;EACA,iBAAA;EACA,UAAA;AACF;;AAEA;EACE,gBAAA;EACA,iBAAA;AACF","sourcesContent":[".PopupCard {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100vh;\n  background-color: rgba(0, 0, 0, 0.2);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 5;\n}\n\n.PopupCard__content {\n  background-color: var(--white);\n  border-radius: 1.2rem;\n  box-shadow: var(--primary-shadow);\n  min-width: 74rem;\n  min-height: 40rem;\n  z-index: 6;\n}\n\n.PopupCard__content_small {\n  min-width: 50rem;\n  min-height: 30rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"PopupCard": `pOs3cvyzwLUfgrFilmdb`,
	"PopupCard__content": `Gtf732o6OHJ42Dgs7FIy`,
	"PopupCard__content_small": `uZHAPCu9NHftMdZTpZGu`
};
export default ___CSS_LOADER_EXPORT___;
