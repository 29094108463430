// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LoirSdk4bmSlZcsI71du {
  display: flex;
  height: 100%;
}

.tppSkiviFJuRk4jBxb20 {
  width: 30%;
  max-width: 35rem;
  min-width: 25rem;
  height: 100%;
  border-right: var(--primary-border);
}

.OKeHMcG1glT4J19e4a_v {
  padding: 5rem;
  flex-grow: 1;
  background-color: var(--light-grey-100);
}`, "",{"version":3,"sources":["webpack://./src/components/5-pages/leads/SettingsOverviewPage/SettingsOverviewPage.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,YAAA;AACF;;AAEA;EACE,UAAA;EACA,gBAAA;EACA,gBAAA;EACA,YAAA;EACA,mCAAA;AACF;;AAEA;EACE,aAAA;EACA,YAAA;EACA,uCAAA;AACF","sourcesContent":[".SettingsOverviewPage {\n  display: flex;\n  height: 100%;\n}\n\n.SettingsOverviewPage__settingsList {\n  width: 30%;\n  max-width: 35rem;\n  min-width: 25rem;\n  height: 100%;\n  border-right: var(--primary-border);\n}\n\n.SettingsOverviewPage__settingPage {\n  padding: 5rem;\n  flex-grow: 1;\n  background-color: var(--light-grey-100);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SettingsOverviewPage": `LoirSdk4bmSlZcsI71du`,
	"SettingsOverviewPage__settingsList": `tppSkiviFJuRk4jBxb20`,
	"SettingsOverviewPage__settingPage": `OKeHMcG1glT4J19e4a_v`
};
export default ___CSS_LOADER_EXPORT___;
