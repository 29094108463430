import Api from '../axiosApi';

export const createChatRoom = (chatRoom: {
  tenderId: number;
  supplierOrgId: number;
  contactId?: number;
  chatMessage?: string;
}): Promise<number> => {
  return Api().put('/Chat/Create', {
    ...chatRoom,
  });
};

export const getChatRoom = (
  roomId: number,
): Promise<{
  chatRoom: ChatRoomDetails;
  participants: ChatRoomParticipant[];
}> => {
  return Api().get('/Chat/GetChatRoom', {
    params: {
      roomId,
    },
  });
};
