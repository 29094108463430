import { Auth0Provider } from '@auth0/auth0-react';

interface Auth0WrapperProps {
  children: React.ReactNode;
}

export const Auth0Wrapper = ({ children }: Auth0WrapperProps) => {
  const clientId = 'cJuKdL9PyQhNvdigLzN9zgboJV5B8nIq';
  const domain = 'b2bpricer.eu.auth0.com';
  const redirectUri = process.env.AUTH0_REDIRECTURI ?? '';
  const audience = 'https://api.b2bpricer.com';

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        // eslint-disable-next-line camelcase
        redirect_uri: redirectUri,
        audience: audience,
      }}
    >
      {children}
    </Auth0Provider>
  );
};
