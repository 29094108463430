// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EApr4g_AkC0oq_LitCgk {
  margin-bottom: 2rem;
}

.E4InSOa46g2xgghw2Mpa {
  align-self: center;
}`, "",{"version":3,"sources":["webpack://./src/components/3-organisms/DashBoard/CreateOrganisationForm/CreateOrganisationStep/CreateOrganisationStep.module.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;;AAEA;EACE,kBAAA;AACF","sourcesContent":[".CreateOrganisationStep__heading {\n  margin-bottom: 2rem;\n}\n\n.CreateOrganisationStep__submitBtn {\n  align-self: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CreateOrganisationStep__heading": `EApr4g_AkC0oq_LitCgk`,
	"CreateOrganisationStep__submitBtn": `E4InSOa46g2xgghw2Mpa`
};
export default ___CSS_LOADER_EXPORT___;
