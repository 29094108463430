// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.oL7z_Xb_EqeF3o02jrFn {
  margin-bottom: 6rem;
}

.BMm6nTZi2gXXcl4MTTXB {
  margin-bottom: 4rem;
  display: flex;
  gap: 4rem;
}

.OWZShd_gU_IvXbwQrgiW {
  margin-top: 5.5rem;
}`, "",{"version":3,"sources":["webpack://./src/components/3-organisms/SettingsOverview/IntegrationsOverview/IntegrationsOverview.module.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;;AAEA;EACE,mBAAA;EACA,aAAA;EACA,SAAA;AACF;;AAEA;EACE,kBAAA;AACF","sourcesContent":[".IntegrationsOverview__pageTitle {\n  margin-bottom: 6rem;\n}\n\n.IntegrationsOverview_company {\n  margin-bottom: 4rem;\n  display: flex;\n  gap: 4rem;\n}\n\n.IntegrationsOverview__cta {\n  margin-top: 5.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"IntegrationsOverview__pageTitle": `oL7z_Xb_EqeF3o02jrFn`,
	"IntegrationsOverview_company": `BMm6nTZi2gXXcl4MTTXB`,
	"IntegrationsOverview__cta": `OWZShd_gU_IvXbwQrgiW`
};
export default ___CSS_LOADER_EXPORT___;
