import classNames from 'classnames';
import styles from './LoadingSpinner.module.scss';
import { ReactComponent as LoadingSpinnerIcon } from '@/assets/Icons/icon-loading-spinner.svg';

export interface LoadingSpinnerProps {
  className?: string;
}

export const LoadingSpinner = ({ className }: LoadingSpinnerProps) => (
  <LoadingSpinnerIcon className={classNames(styles.LoadingSpinner, className)} />
);
