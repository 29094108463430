import { Heading, Image } from '@/components/1-atoms';
import classNames from 'classnames';
import { useRef } from 'react';
import { FormErrorMessage } from '../FormErrorMessage';
import { ReactComponent as PenIcon } from '@/assets/Icons/icon-pen.svg';
import { UseFormRegister } from 'react-hook-form';

import styles from './FormFieldImageInput.module.scss';

type State = {
  required?: boolean;
  disabled?: boolean;
  hasError?: boolean;
};

export interface FormFieldImageInputProps {
  ariaLabel?: string;
  className?: string;
  defaultImage: string;
  errorMessage?: string;
  id: string;
  label: string;
  multipleFiles?: boolean;
  name: string;
  onImageChange?: (e: React.FormEvent<HTMLInputElement>) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register?: UseFormRegister<any>;
  state?: State;
}

export const FormFieldImageInput = ({
  ariaLabel,
  className,
  defaultImage,
  errorMessage,
  id,
  label,
  name,
  multipleFiles = false,
  onImageChange,
  register,
  state,
}: FormFieldImageInputProps) => {
  const { required, disabled, hasError } = state ?? {};
  const type = 'file';

  const inputRef = useRef<HTMLInputElement | null>(null);

  const registerPropsHandler = () => {
    let props = {};

    if (register) {
      const { ref, onChange, ...rest } = register(name);
      props = {
        ref: (e: HTMLInputElement | null) => {
          ref(e);
          inputRef.current = e;
        },

        onChange: (e: React.FormEvent<HTMLInputElement>) => {
          onChange(e);
          onImageChange && onImageChange(e);
        },

        required: { required },
        ...rest,
      };
    }

    return props;
  };

  const focusInput = () => {
    inputRef?.current?.click();
  };

  return (
    <div
      className={classNames(
        styles.FormFieldImageInput,
        className,
        { [styles.hasError]: hasError },
        { [styles.isDisabled]: disabled },
      )}
    >
      <Heading headingLevel='h3' id={id} className={styles.FormFieldImageInput__heading}>
        {label}
      </Heading>

      <div className={styles.FormFieldImageInput__imagePreview}>
        <Image className={styles.FormFieldImageInput__image} src={defaultImage} alt={'avatar'} />

        <input
          className={styles.FormFieldImageInput__input}
          name={name}
          id={id}
          disabled={disabled}
          type={type}
          multiple={multipleFiles}
          accept='image/png , image/jpeg, image/webp'
          {...registerPropsHandler()}
        />

        <div
          className={styles.FormFieldImageInput__iconWrapper}
          onClick={focusInput}
          aria-label={ariaLabel}
        >
          <span className={styles.FormFieldImageInput__icon}>
            <PenIcon />
          </span>
        </div>
      </div>

      {hasError && <FormErrorMessage id={type + id}>{errorMessage}</FormErrorMessage>}
    </div>
  );
};
