// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nDzrV4u6ROgVFGnOctII {
  width: 100%;
}

.lODIvpOA1RBsB0FfZsL2 {
  min-height: 30rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
  border: var(--primary-border);
  border-top: 0;
}

.mw4PXnkyfMxu3SY7YAsw {
  margin-bottom: 2rem;
}`, "",{"version":3,"sources":["webpack://./src/components/3-organisms/SuppliersOverview/SuppliersOverview.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;;AAEA;EACE,iBAAA;EACA,aAAA;EACA,mBAAA;EACA,sBAAA;EACA,uBAAA;EACA,SAAA;EACA,6BAAA;EACA,aAAA;AACF;;AAEA;EACE,mBAAA;AACF","sourcesContent":[".SuppliersOverview {\n  width: 100%;\n}\n\n.SuppliersOverview__emptyList {\n  min-height: 30rem;\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n  justify-content: center;\n  gap: 2rem;\n  border: var(--primary-border);\n  border-top: 0;\n}\n\n.SuppliersOverview__emptyListHeading {\n  margin-bottom: 2rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SuppliersOverview": `nDzrV4u6ROgVFGnOctII`,
	"SuppliersOverview__emptyList": `lODIvpOA1RBsB0FfZsL2`,
	"SuppliersOverview__emptyListHeading": `mw4PXnkyfMxu3SY7YAsw`
};
export default ___CSS_LOADER_EXPORT___;
