import { Heading, Paragraph } from '@/components/1-atoms';
import classNames from 'classnames';
import { useRef } from 'react';
import { FormErrorMessage } from '../FormErrorMessage';
import { UseFormRegister } from 'react-hook-form';
import { ReactComponent as UploadIcon } from '@/assets/Icons/icon-upload.svg';

import styles from './FormFieldFileInput.module.scss';

type State = {
  required?: boolean;
  disabled?: boolean;
  hasError?: boolean;
};

export interface FormFieldFileInputProps {
  ariaLabel?: string;
  className?: string;
  errorMessage?: string;
  id: string;
  label: string;
  multipleFiles?: boolean;
  name: string;
  onFileChange?: (e: React.FormEvent<HTMLInputElement>) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register?: UseFormRegister<any>;
  state?: State;
}

export const FormFieldFileInput = ({
  ariaLabel,
  className,
  errorMessage,
  id,
  label,
  name,
  multipleFiles = false,
  onFileChange,
  register,
  state,
}: FormFieldFileInputProps) => {
  const { required, disabled, hasError } = state ?? {};
  const type = 'file';

  const inputRef = useRef<HTMLInputElement | null>(null);

  const registerPropsHandler = () => {
    let props = {};

    if (register) {
      const { ref, onChange, ...rest } = register(name);
      props = {
        ref: (e: HTMLInputElement | null) => {
          ref(e);
          inputRef.current = e;
        },

        onChange: (e: React.FormEvent<HTMLInputElement>) => {
          onChange(e);
          onFileChange && onFileChange(e);
        },

        required: { required },
        ...rest,
      };
    }

    return props;
  };

  const focusInput = () => {
    inputRef?.current?.click();
  };

  return (
    <div
      className={classNames(
        styles.FormFieldFileInput,
        className,
        { [styles.hasError]: hasError },
        { [styles.isDisabled]: disabled },
      )}
    >
      <Heading headingLevel='h3' id={id} className={styles.FormFieldFileInput__heading}>
        {label}
      </Heading>

      <input
        className={styles.FormFieldFileInput__input}
        name={name}
        id={id}
        disabled={disabled}
        type={type}
        multiple={multipleFiles}
        accept='.doc, .docx, .pdf'
        {...registerPropsHandler()}
      />

      <div
        className={styles.FormFieldFileInput__preview}
        onClick={focusInput}
        aria-label={ariaLabel}
      >
        <UploadIcon className={styles.FormFieldFileInput__icon} />
        <Paragraph className={styles.FormFieldFileInput__previewText}>
          Drag and drop files here or{' '}
          <span className={styles.FormFieldFileInput__browse}>browse</span> your files
        </Paragraph>
      </div>

      {hasError && <FormErrorMessage id={type + id}>{errorMessage}</FormErrorMessage>}
    </div>
  );
};
