// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CkEjWcCTZwKJou9jUUIj {
  padding: 8rem 5rem 3rem 5rem;
  height: 100%;
}

.iilTbwQ7UigAZFbk72L3 {
  margin: 4.5rem 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

.YwDIz08OSjvpezPQacjA {
  display: flex;
  gap: 5rem;
}

.SLAPFuCH3TJAagScKqFk {
  width: 50%;
}

.b5IkbAkKueOvgS7jhXK5 {
  margin-top: 2rem;
}`, "",{"version":3,"sources":["webpack://./src/components/5-pages/leads/TenderDetailsPage/TenderDetailsPage.module.scss"],"names":[],"mappings":"AAAA;EACE,4BAAA;EACA,YAAA;AACF;;AAEA;EACE,gBAAA;EACA,WAAA;EACA,aAAA;EACA,uBAAA;AACF;;AAEA;EACE,aAAA;EACA,SAAA;AACF;;AAEA;EACE,UAAA;AACF;;AAEA;EACE,gBAAA;AACF","sourcesContent":[".TenderDetailsPage {\n  padding: 8rem 5rem 3rem 5rem;\n  height: 100%;\n}\n\n.TenderDetailsPage__createTenderCta {\n  margin: 4.5rem 0;\n  width: 100%;\n  display: flex;\n  justify-content: center;\n}\n\n.TenderDetailsPage__eventsSection {\n  display: flex;\n  gap: 5rem;\n}\n\n.TenderDetailsPage__calendar {\n  width: 50%;\n}\n\n.TenderDetailsPage__spinner {\n  margin-top: 2rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TenderDetailsPage": `CkEjWcCTZwKJou9jUUIj`,
	"TenderDetailsPage__createTenderCta": `iilTbwQ7UigAZFbk72L3`,
	"TenderDetailsPage__eventsSection": `YwDIz08OSjvpezPQacjA`,
	"TenderDetailsPage__calendar": `SLAPFuCH3TJAagScKqFk`,
	"TenderDetailsPage__spinner": `b5IkbAkKueOvgS7jhXK5`
};
export default ___CSS_LOADER_EXPORT___;
