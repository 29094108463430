// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ola3SRHB3F0RhwahftR2 {
  width: 100%;
}

.J0mlE_YYZ3TLD6GGLXRM {
  margin-bottom: 1rem;
}

.nAfJKyo7iEmaZ7sLgFSo {
  box-sizing: border-box;
  font-family: var(--font-secondary);
  display: flex;
  padding: 2.1rem 3rem;
  width: 100%;
  height: 6rem;
  background: var(--white);
  border: var(--primary-border);
  border-radius: 0.6rem;
  gap: 3rem;
  align-items: center;
}

.DREX9tUBl92UISlWIdJ7 {
  color: var(--dark-grey-900);
  font-size: var(--font-size--md);
  font-weight: var(--font-weight--normal);
  background: inherit;
  border: none;
  width: 100%;
}
.DREX9tUBl92UISlWIdJ7:focus {
  outline: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/2-molecules/Form/FormFieldInput/FormFieldInput.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;;AAEA;EACE,mBAAA;AACF;;AAEA;EACE,sBAAA;EACA,kCAAA;EACA,aAAA;EAEA,oBAAA;EACA,WAAA;EACA,YAAA;EACA,wBAAA;EACA,6BAAA;EACA,qBAAA;EACA,SAAA;EACA,mBAAA;AAAF;;AAGA;EACE,2BAAA;EACA,+BAAA;EACA,uCAAA;EACA,mBAAA;EACA,YAAA;EACA,WAAA;AAAF;AAEE;EACE,UAAA;AAAJ","sourcesContent":[".FormFieldInput {\n  width: 100%;\n}\n\n.FormFieldInput__heading {\n  margin-bottom: 1rem;\n}\n\n.FormFieldInput__inputWrapper {\n  box-sizing: border-box;\n  font-family: var(--font-secondary);\n  display: flex;\n\n  padding: 2.1rem 3rem;\n  width: 100%;\n  height: 6rem;\n  background: var(--white);\n  border: var(--primary-border);\n  border-radius: 0.6rem;\n  gap: 3rem;\n  align-items: center;\n}\n\n.FormFieldInput__input {\n  color: var(--dark-grey-900);\n  font-size: var(--font-size--md);\n  font-weight: var(--font-weight--normal);\n  background: inherit;\n  border: none;\n  width: 100%;\n\n  &:focus {\n    outline: 0;\n  }\n}\n\n// TODO: Add Disabled State and Error State\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"FormFieldInput": `ola3SRHB3F0RhwahftR2`,
	"FormFieldInput__heading": `J0mlE_YYZ3TLD6GGLXRM`,
	"FormFieldInput__inputWrapper": `nAfJKyo7iEmaZ7sLgFSo`,
	"FormFieldInput__input": `DREX9tUBl92UISlWIdJ7`
};
export default ___CSS_LOADER_EXPORT___;
