// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.xeH4p5E8Y9kCCDamfdMz {
  border-radius: 1.6rem;
  background: var(--light-grey-150);
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 4rem 8rem;
}

.xkZH_RoSGV5SyK6WaOHD {
  margin-bottom: 1.8rem;
  color: var(--primary-color);
}

.MXV2qj_Ray18we2Znoe2 {
  white-space: pre;
}

.hjLsZF35shfT_hPLCfu1 {
  width: 43rem;
  height: 20rem;
  position: absolute;
  right: 14rem;
  bottom: 2rem;
}`, "",{"version":3,"sources":["webpack://./src/components/2-molecules/Cards/WelcomeCard/WelcomeCard.module.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;EACA,iCAAA;EACA,kBAAA;EACA,aAAA;EACA,uBAAA;EACA,sBAAA;EACA,kBAAA;AACF;;AAEA;EACE,qBAAA;EACA,2BAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE,YAAA;EACA,aAAA;EACA,kBAAA;EACA,YAAA;EACA,YAAA;AACF","sourcesContent":[".WelcomeCard {\n  border-radius: 1.6rem;\n  background: var(--light-grey-150);\n  position: relative;\n  display: flex;\n  justify-content: center;\n  flex-direction: column;\n  padding: 4rem 8rem;\n}\n\n.WelcomeCard__heading {\n  margin-bottom: 1.8rem;\n  color: var(--primary-color);\n}\n\n.WelcomeCard__content {\n  white-space: pre;\n}\n\n.WelcomeCard__image {\n  width: 43rem;\n  height: 20rem;\n  position: absolute;\n  right: 14rem;\n  bottom: 2rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"WelcomeCard": `xeH4p5E8Y9kCCDamfdMz`,
	"WelcomeCard__heading": `xkZH_RoSGV5SyK6WaOHD`,
	"WelcomeCard__content": `MXV2qj_Ray18we2Znoe2`,
	"WelcomeCard__image": `hjLsZF35shfT_hPLCfu1`
};
export default ___CSS_LOADER_EXPORT___;
