// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.xQJwQKgTVdOLMcXBe8S2 {
  padding: 6rem 5rem 3rem 5rem;
}

.woNwnT5_cawZ7yVTiXWH {
  display: flex;
  justify-content: center;
  gap: 4rem;
  margin-top: 4rem;
}`, "",{"version":3,"sources":["webpack://./src/components/5-pages/tenders/CreateTenderPage/CreateTenderPage.module.scss"],"names":[],"mappings":"AAAA;EACE,4BAAA;AACF;;AAEA;EACE,aAAA;EACA,uBAAA;EACA,SAAA;EACA,gBAAA;AACF","sourcesContent":[".CreateTenderPage {\n  padding: 6rem 5rem 3rem 5rem;\n}\n\n.CreateTenderPage__buttons {\n  display: flex;\n  justify-content: center;\n  gap: 4rem;\n  margin-top: 4rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CreateTenderPage": `xQJwQKgTVdOLMcXBe8S2`,
	"CreateTenderPage__buttons": `woNwnT5_cawZ7yVTiXWH`
};
export default ___CSS_LOADER_EXPORT___;
