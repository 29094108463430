// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.KsB8qQTo5L19rDPXpbRb {
  font-size: var(--font-size--lg);
  line-height: 2.2rem;
  font-weight: var(--font-weight--light);
  border-radius: 4rem;
  cursor: pointer;
  border: 1px solid var(--accent-color);
  display: flex;
  align-items: center;
  width: 4rem;
  height: 4rem;
  overflow: hidden;
  justify-content: center;
  background-color: var(--white);
  transition: all 0.3s ease-in-out;
  gap: 1rem;
}
.KsB8qQTo5L19rDPXpbRb:hover {
  width: 13rem;
  padding: 0 2rem;
}
.KsB8qQTo5L19rDPXpbRb:hover .Hwbnqnggtg4rO9pbklnz {
  display: block;
}

.Hwbnqnggtg4rO9pbklnz {
  display: none;
  color: var(--accent-color);
}

.PnW4H9IE9BTs9RRJrZmi {
  transform: rotateY("45deg");
}`, "",{"version":3,"sources":["webpack://./src/components/2-molecules/AnimatedButton/AnimatedButton.module.scss"],"names":[],"mappings":"AAAA;EACE,+BAAA;EACA,mBAAA;EAEA,sCAAA;EACA,mBAAA;EACA,eAAA;EACA,qCAAA;EACA,aAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;EACA,gBAAA;EACA,uBAAA;EACA,8BAAA;EACA,gCAAA;EACA,SAAA;AAAF;AAEE;EACE,YAAA;EACA,eAAA;AAAJ;AAGE;EACE,cAAA;AADJ;;AAKA;EACE,aAAA;EACA,0BAAA;AAFF;;AAKA;EACE,2BAAA;AAFF","sourcesContent":[".AnimatedButton {\n  font-size: var(--font-size--lg);\n  line-height: 2.2rem;\n\n  font-weight: var(--font-weight--light);\n  border-radius: 4rem;\n  cursor: pointer;\n  border: 1px solid var(--accent-color);\n  display: flex;\n  align-items: center;\n  width: 4rem;\n  height: 4rem;\n  overflow: hidden;\n  justify-content: center;\n  background-color: var(--white);\n  transition: all 0.3s ease-in-out;\n  gap: 1rem;\n\n  &:hover {\n    width: 13rem;\n    padding: 0 2rem;\n  }\n\n  &:hover &__title {\n    display: block;\n  }\n}\n\n.AnimatedButton__title {\n  display: none;\n  color: var(--accent-color);\n}\n\n.AnimatedButton__icon {\n  transform: rotateY('45deg');\n}\n\n.AnimatedButton__activate {\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AnimatedButton": `KsB8qQTo5L19rDPXpbRb`,
	"AnimatedButton__title": `Hwbnqnggtg4rO9pbklnz`,
	"AnimatedButton__icon": `PnW4H9IE9BTs9RRJrZmi`
};
export default ___CSS_LOADER_EXPORT___;
