// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RHx9Ux6z_DpmE743qmCw {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  margin-top: 4rem;
}

.g1InV0Bt1gHpyMs9HpJn {
  display: flex;
  flex-direction: row;
  gap: 5rem;
}

.fMJGfa_lCDzNx9hfvudv {
  min-width: 17rem;
}

.jYDpmjmxY7gxnQN8O_QP {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}`, "",{"version":3,"sources":["webpack://./src/components/3-organisms/TenderDetails/Details/Details.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;EACA,gBAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EAEA,SAAA;AAAF;;AAGA;EACE,gBAAA;AAAF;;AAGA;EACE,WAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;AAAF","sourcesContent":[".Details {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  gap: 4rem;\n  margin-top: 4rem;\n}\n\n.Details__dataRow {\n  display: flex;\n  flex-direction: row;\n  // align-items: center;\n  gap: 5rem;\n}\n\n.Details__rowHeading {\n  min-width: 17rem;\n}\n\n.Details__phasesList {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  gap: 2rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Details": `RHx9Ux6z_DpmE743qmCw`,
	"Details__dataRow": `g1InV0Bt1gHpyMs9HpJn`,
	"Details__rowHeading": `fMJGfa_lCDzNx9hfvudv`,
	"Details__phasesList": `jYDpmjmxY7gxnQN8O_QP`
};
export default ___CSS_LOADER_EXPORT___;
