import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { DashBoardPage } from '../DashBoardPage';
import { LeadsOverviewPage } from '../LeadsOverviewPage';
import { LeadDetailsPage } from '../LeadDetailsPage';
import { TendersOverviewPage } from '../TendersOverviewPage';
import { ContractsOverviewPage } from '../ContractsOverviewPage';
import { SettingsOverviewPage } from '../SettingsOverviewPage';

import { TenderDetailsPage } from '../TenderDetailsPage';
import { LoginType } from '@/constants/loginTypes';
import { useEffect } from 'react';
import { useAppSelector } from '@/store';

export const LeadsRoutes = () => {
  const user = useAppSelector((state) => state?.user?.loginProfile);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user || user.version != LoginType.Supplier) {
      navigate('/');
    }
  }, [user]);
  return (
    <Routes>
      <Route path='/' element={<Navigate to={'dashboard'} />} />
      <Route path='dashboard' element={<DashBoardPage />} />
      <Route path='leadsOverview' element={<LeadsOverviewPage />} />
      <Route path='leadsOverview/:leadId/*' element={<LeadDetailsPage />} />
      <Route path='tendersOverview' element={<TendersOverviewPage />} />
      <Route path='tendersOverview/:tenderId/*' element={<TenderDetailsPage />} />
      <Route path='contracts/*' element={<ContractsOverviewPage />} />
      <Route path='settings/*' element={<SettingsOverviewPage />} />
    </Routes>
  );
};
