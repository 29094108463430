import styles from './TasksList.module.scss';
import { Button, TaskCard } from '@/components/2-molecules';

interface TasksListProps {
  events?: TenderTasks[];
  handleLoadMore: () => void;
  shouldRenderLoadMore?: boolean;
}

export const TasksList = ({ events, handleLoadMore, shouldRenderLoadMore }: TasksListProps) => {
  return (
    <div className={styles.TasksList}>
      <div className={styles.TasksList__list}>
        {events && events.map((event) => <TaskCard key={event.id} task={event} />)}
      </div>
      {shouldRenderLoadMore && (
        <Button
          className={styles.TasksList__loadMore}
          onClick={handleLoadMore}
          type={'secondaryColor'}
        >
          Show more
        </Button>
      )}
    </div>
  );
};
