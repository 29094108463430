// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.srbhclFfWkd0fHd_YMOW {
  height: 100%;
}

.LK93ekBkxTdZ_RUr7ZIy {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  justify-content: center;
  height: 100%;
}

.i2_WeOhh7gG8y3Lvhfpg {
  align-self: baseline;
}

.ylvTz7SgEB8Mm8UDjKgU {
  margin-top: auto;
  display: flex;
  justify-content: center;
  gap: 4rem;
  margin-bottom: 2rem;
}`, "",{"version":3,"sources":["webpack://./src/components/3-organisms/CreateTender/CreateTenderPhasesList/CreateTenderPhasesList.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,uBAAA;EACA,YAAA;AACF;;AAEA;EACE,oBAAA;AACF;;AAEA;EACE,gBAAA;EACA,aAAA;EACA,uBAAA;EACA,SAAA;EACA,mBAAA;AACF","sourcesContent":[".PhasesList {\n  height: 100%;\n}\n\n.PhasesList__form {\n  display: flex;\n  flex-direction: column;\n  gap: 3rem;\n  justify-content: center;\n  height: 100%;\n}\n\n.PhasesList__addPhase {\n  align-self: baseline;\n}\n\n.PhasesList__ctaWrapper {\n  margin-top: auto;\n  display: flex;\n  justify-content: center;\n  gap: 4rem;\n  margin-bottom: 2rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"PhasesList": `srbhclFfWkd0fHd_YMOW`,
	"PhasesList__form": `LK93ekBkxTdZ_RUr7ZIy`,
	"PhasesList__addPhase": `i2_WeOhh7gG8y3Lvhfpg`,
	"PhasesList__ctaWrapper": `ylvTz7SgEB8Mm8UDjKgU`
};
export default ___CSS_LOADER_EXPORT___;
