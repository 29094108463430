import { createSlice } from '@reduxjs/toolkit';
import { mockedContractsList } from './constants/contracts';

export interface ContractsState {
  contractsList: Contract[];
}

const initialState: ContractsState = {
  contractsList: [],
};

export const contractsSlice = createSlice({
  name: 'contracts',
  initialState,
  reducers: {
    getContractsList: (state: ContractsState) => {
      state.contractsList = mockedContractsList;
    },
  },
});

export const { getContractsList } = contractsSlice.actions;
export default contractsSlice.reducer;
