// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PjCaXBesoz_2foh_JYb7 {
  width: 100%;
  display: flex;
  gap: 4rem;
  flex-direction: column;
  padding: 4rem 0;
}

.kDXsoKbuihluzJruT9Dz {
  display: flex;
  justify-content: flex-end;
}

.hiznknvLhcXYUXb4V5wx {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.aPhEPsM0HTvQuGfKggKX {
  display: flex;
  gap: 8rem;
  flex-flow: row;
  margin-right: 8rem;
}

.doWpfYz9aBvZVnZilnEZ {
  color: var(--dark-grey-600);
}

.t8Zo2wZGP4DqOlvrck6p {
  display: flex;
  gap: 4rem;
  flex-direction: row;
  align-items: center;
}

.Bo6nwHOHTjRM0N2pmYLa {
  border: unset;
  background: unset;
  cursor: pointer;
}

.wGFQ8A6gZhzlKZYJKcEC {
  padding: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.gxFCZUEiWkwMxU5amb37 {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.MnXXem7M14u_Jt7wyK_z {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  justify-content: center;
  min-height: 54.2rem;
  margin: 5rem 0 2rem 0;
}

.fHokvhBiTpwbsp9I1W7p {
  margin-bottom: 2rem;
}`, "",{"version":3,"sources":["webpack://./src/components/3-organisms/TenderDetails/Responses/ResponsesList/ResponsesList.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,SAAA;EACA,sBAAA;EACA,eAAA;AACF;;AAEA;EACE,aAAA;EACA,yBAAA;AACF;;AAEA;EACE,aAAA;EACA,WAAA;EACA,8BAAA;AACF;;AAEA;EACE,aAAA;EACA,SAAA;EACA,cAAA;EACA,kBAAA;AACF;;AAEA;EACE,2BAAA;AACF;;AAEA;EACE,aAAA;EACA,SAAA;EACA,mBAAA;EACA,mBAAA;AACF;;AAEA;EACE,aAAA;EACA,iBAAA;EACA,eAAA;AACF;;AAEA;EACE,aAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,SAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,mBAAA;EACA,uBAAA;EACA,mBAAA;EACA,qBAAA;AACF;;AAEA;EACE,mBAAA;AACF","sourcesContent":[".ResponsesList {\n  width: 100%;\n  display: flex;\n  gap: 4rem;\n  flex-direction: column;\n  padding: 4rem 0;\n}\n\n.ResponsesList__compareAnswersCta {\n  display: flex;\n  justify-content: flex-end;\n}\n\n.ResponsesList__accordionHeadingWrapper {\n  display: flex;\n  width: 100%;\n  justify-content: space-between;\n}\n\n.ResponsesList__stats {\n  display: flex;\n  gap: 8rem;\n  flex-flow: row;\n  margin-right: 8rem;\n}\n\n.ResponsesList__statsHeading {\n  color: var(--dark-grey-600);\n}\n\n.ResponsesList__supplierWrapper {\n  display: flex;\n  gap: 4rem;\n  flex-direction: row;\n  align-items: center;\n}\n\n.ResponsesList__viewOfferButton {\n  border: unset;\n  background: unset;\n  cursor: pointer;\n}\n\n.ResponsesList__phaseActions {\n  padding: 2rem;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.ResponsesList__phaseActionsButtons {\n  display: flex;\n  align-items: center;\n  gap: 2rem;\n}\n\n.ResponsesList__emptyList {\n  display: flex;\n  flex-direction: column;\n  gap: 2rem;\n  align-items: center;\n  justify-content: center;\n  min-height: 54.2rem;\n  margin: 5rem 0 2rem 0;\n}\n\n.ResponsesList__emptyListHeading {\n  margin-bottom: 2rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ResponsesList": `PjCaXBesoz_2foh_JYb7`,
	"ResponsesList__compareAnswersCta": `kDXsoKbuihluzJruT9Dz`,
	"ResponsesList__accordionHeadingWrapper": `hiznknvLhcXYUXb4V5wx`,
	"ResponsesList__stats": `aPhEPsM0HTvQuGfKggKX`,
	"ResponsesList__statsHeading": `doWpfYz9aBvZVnZilnEZ`,
	"ResponsesList__supplierWrapper": `t8Zo2wZGP4DqOlvrck6p`,
	"ResponsesList__viewOfferButton": `Bo6nwHOHTjRM0N2pmYLa`,
	"ResponsesList__phaseActions": `wGFQ8A6gZhzlKZYJKcEC`,
	"ResponsesList__phaseActionsButtons": `gxFCZUEiWkwMxU5amb37`,
	"ResponsesList__emptyList": `MnXXem7M14u_Jt7wyK_z`,
	"ResponsesList__emptyListHeading": `fHokvhBiTpwbsp9I1W7p`
};
export default ___CSS_LOADER_EXPORT___;
