import { useEffect, useRef, useState } from 'react';
import styles from './CategoriesSelect.module.scss';
import classNames from 'classnames';
import { ReactComponent as ArrowIcon } from '@/assets/Icons/icon-add-triangle.svg';
import { Paragraph } from '@/components/1-atoms';
import { FormFieldCheckbox } from '@/components/2-molecules';

export interface CategoriesSelectProps {
  className?: string;
  categoriesList: Category[];
  submitCategories: (list: Category[]) => void;
}

export const CategoriesSelect = ({
  className,
  categoriesList,
  submitCategories,
}: CategoriesSelectProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const inputRef = useRef<HTMLInputElement>(null);
  const categoriesContainerRef = useRef<HTMLDivElement>(null);

  const [categories, setCategories] = useState<Category[]>(categoriesList);

  useEffect(() => {
    setCategories(categoriesList);
  }, [categoriesList]);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        categoriesContainerRef.current &&
        !categoriesContainerRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const onCategoryChange = (value: number) => {
    return setCategories((prev) => {
      return prev.map((category) => {
        if (category.value === value) {
          return { ...category, checked: !category.checked };
        }
        return category;
      });
    });
  };

  const onSubmitCategories = () => {
    submitCategories(categories);
    setIsOpen(false);
  };

  const onClearCategories = () => {
    setCategories((prev) => {
      return prev.map((category) => {
        return { ...category, checked: false };
      });
    });
    submitCategories(categories);
    setIsOpen(false);
  };

  const searchFilter = () => {
    if (!searchValue || searchValue === '') {
      return categories;
    }
    const filteredCategories = categories.filter((category) => {
      return category.label.toLowerCase().includes(searchValue.toLowerCase());
    });

    return filteredCategories;
  };

  return (
    <div
      className={classNames(styles.CategoriesSelect, className)}
      ref={categoriesContainerRef}
      onClick={(e) => {
        e.preventDefault();
        setIsOpen(true);
        inputRef && inputRef.current?.focus();
      }}
    >
      <div className={styles.CategoriesSelect__heading}>
        <input
          type='text'
          placeholder='Start typing to select a category'
          ref={inputRef}
          className={styles.CategoriesSelect__input}
          onChange={(e) => {
            setSearchValue(e.target.value);
          }}
        />
        {!isOpen && <ArrowIcon />}
      </div>

      {isOpen && (
        <div
          className={styles.CategoriesSelect__content}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <ul className={styles.CategoriesSelect__list}>
            {searchFilter().map((category) => {
              return (
                <li
                  className={styles.CategoriesSelect__listItem}
                  key={category.value}
                  onClick={() => onCategoryChange(category.value)}
                >
                  <FormFieldCheckbox
                    checked={category.checked}
                    onChange={() => onCategoryChange(category.value)}
                  />
                  <Paragraph>{category.label}</Paragraph>
                </li>
              );
            })}
          </ul>
          <div className={styles.CategoriesSelect__actions}>
            <button
              className={classNames(
                styles.CategoriesSelect__button,
                styles.CategoriesSelect__clearButton,
              )}
              onClick={(e) => {
                e.preventDefault();
                onClearCategories();
              }}
            >
              Clear
            </button>
            <button
              className={classNames(
                styles.CategoriesSelect__button,
                styles.CategoriesSelect__addButton,
              )}
              onClick={(e) => {
                e.preventDefault();
                onSubmitCategories();
              }}
            >
              Add selected
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
