import classNames from 'classnames';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Heading } from '@/components/1-atoms';
import { IconButton, TabsNavigation } from '@/components/2-molecules';

import styles from './ResponsesCompare.module.scss';
import { ResponseCompareAccordion } from './ResponseCompareAccordion';
import { ReactComponent as ArrowIcon } from '@/assets/Icons/icon-arrow-accordion.svg';
import { categories } from '@/constants/categories';

export interface ResponsesCompareProps {
  className?: string;
  responsesCompare?: PhaseResponsesCompare;
}

export const ResponsesCompare = ({ className, responsesCompare }: ResponsesCompareProps) => {
  const [activeTab, setActiveTab] = useState('Questions');
  const navigate = useNavigate();

  const getTabContent = () => {
    return activeTab === 'Questions' ? responsesCompare?.questions : responsesCompare?.documents;
  };

  return (
    <div className={classNames(styles.ResponsesCompare, className)}>
      <div className={styles.ResponsesCompare__backButton}>
        <IconButton
          icon={<ArrowIcon className={styles.ResponsesCompare__arrowIcon} />}
          onClick={() => {
            navigate(-1);
          }}
        >
          Back
        </IconButton>
      </div>

      <div className={styles.ResponsesCompare__card}>
        <div className={styles.ResponsesCompare__cardHeading}>
          <Heading headingLevel={'h3'}>Comparing supplier responses</Heading>
          <div className={styles.ResponsesCompare__cardStatus}>
            <Heading headingLevel={'h3'}>Active phase: {responsesCompare?.phaseName}</Heading>
          </div>
        </div>

        <TabsNavigation
          tabsHeaders={[
            { title: 'Documents', id: 'Documents' },
            { title: 'Questions', id: 'Questions' },
          ]}
          initialTabID={activeTab}
          onTabClick={(tabId: string) => setActiveTab(tabId)}
        />

        {categories.map((category) => {
          // Filter responses based on the current category
          const filteredResponses = getTabContent()?.filter(
            (response) => response.category === category.value,
          );
          if (filteredResponses && filteredResponses.length > 0) {
            return filteredResponses.map((response, index) => (
              <ResponseCompareAccordion
                startOpen={true}
                key={index}
                className={styles.ResponsesCompare__accordion}
                heading={
                  <>
                    <Heading
                      className={styles.ResponsesCompare__accordionTitle}
                      headingLevel={'h3'}
                    >
                      {category.label}
                    </Heading>
                    <span>{response.completed} completed</span>
                  </>
                }
                question={response.question}
                responsesList={response.responses}
              />
            ));
          }

          return null;
        })}
      </div>
    </div>
  );
};
