import classNames from 'classnames';
import styles from './StatusText.module.scss';
import { Paragraph } from '../Paragraph';

export interface StatusTextProps {
  className?: string;
  status: 'inactive' | 'active' | 'finished';
  text: string | JSX.Element;
}

export const StatusText = ({ className, status, text }: StatusTextProps) => {
  let statusColor: string;

  switch (status) {
    case 'inactive':
      statusColor = styles.StatusText_inactive;
      break;
    case 'active':
      statusColor = styles.StatusText_active;
      break;
    case 'finished':
      statusColor = styles.StatusText_finished;
      break;
    default:
      statusColor = '';
  }

  return (
    <div className={classNames(styles.StatusText, statusColor, className)}>
      <Paragraph>{text}</Paragraph>
    </div>
  );
};
