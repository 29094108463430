import { createSlice } from '@reduxjs/toolkit';
import { mockedCompaniesList } from './constants/companies';

export interface CompaniesState {
  companiesList: Organisation[];
}

const initialState: CompaniesState = {
  companiesList: [],
};

export const companiesSlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    getCompaniesList: (state: CompaniesState) => {
      state.companiesList = mockedCompaniesList;
    },
  },
});

export const { getCompaniesList } = companiesSlice.actions;
export default companiesSlice.reducer;
