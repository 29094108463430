import { Heading } from '@/components/1-atoms';
import classNames from 'classnames';
import { useRef } from 'react';
import { FormErrorMessage } from '../FormErrorMessage';
import styles from './FormFieldSelect.module.scss';
import Select, { ActionMeta, SingleValue } from 'react-select';

type State = {
  required?: boolean;
  disabled?: boolean;
  hasError?: boolean;
};

type Option = {
  value: string | number;
  label: string;
};

export interface FormFieldSelectProps {
  className?: string;
  defaultValue?: string | number;
  options: Option[];
  errorMessage?: string;
  id: string;
  onChange?: (id: string | number) => void;
  label?: string;
  state?: State;
  placeholder?: string;
  labelBottomMargin?: string;
}

export const FormFieldSelect = ({
  className,
  defaultValue,
  errorMessage,
  id,
  onChange,
  label,
  options,
  state,
  placeholder,
  labelBottomMargin = '1rem',
}: FormFieldSelectProps) => {
  const { required, disabled, hasError } = state ?? {};

  const inputRef = useRef<HTMLInputElement | null>(null);

  const onChangeHandler = (newValue: SingleValue<Option>, actionMeta: ActionMeta<Option>) => {
    if (newValue) {
      onChange && onChange(newValue.value);
    }
  };

  const focusInput = () => {
    inputRef.current?.focus();
  };

  return (
    <div
      className={classNames(
        styles.FormFieldSelect,
        className,
        { [styles.hasError]: hasError },
        { [styles.isDisabled]: disabled },
      )}
      onClick={focusInput}
      style={{
        gap: labelBottomMargin,
      }}
    >
      {label && (
        <Heading headingLevel='h3' id={id}>
          {label}
        </Heading>
      )}

      <Select
        defaultValue={options.filter((option) => option.value == defaultValue)}
        options={options}
        isDisabled={disabled}
        onChange={onChangeHandler}
        placeholder={placeholder}
        styles={{
          control: (base) => ({
            ...base,
            border: 'none',
            // This line disable the blue border
            boxShadow: 'none',
          }),
        }}
        classNames={{
          container: () =>
            classNames(styles.FormFieldSelect__container, {
              [styles.hasError]: hasError,
            }),
          control: () => styles.FormFieldSelect__controller,
          option: ({ isFocused, isSelected }) =>
            classNames((isSelected || isFocused) && styles.FormFieldSelect__options),
        }}
        required={required}
      />
      {hasError && <FormErrorMessage id={'Select' + id}>{errorMessage}</FormErrorMessage>}
    </div>
  );
};
