import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { DashBoardPage } from '../DashBoardPage';
import { ContractsOverviewPage } from '../ContractsOverviewPage';
import { CreateTenderPage } from '../CreateTenderPage';
import { SettingsOverviewPage } from '../SettingsOverviewPage';
import { SuppliersOverviewPage } from '../SuppliersOverviewPage';
import { TenderDetailsPage } from '../TenderDetailsPage';
import { TendersOverviewPage } from '../TendersOverviewPage';
import { useAppSelector } from '@/store';
import { useEffect } from 'react';
import { LoginType } from '@/constants/loginTypes';

export const TendersRoutes = () => {
  const user = useAppSelector((state) => state?.user?.loginProfile);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user || user?.version != LoginType.Buyer) {
      navigate('/');
    }
  }, []);

  return (
    <Routes>
      <Route path='/' element={<Navigate to={'dashboard'} />} />
      <Route path='dashboard' element={<DashBoardPage />} />
      <Route path='tendersOverview' element={<TendersOverviewPage />} />
      <Route path='tendersOverview/new-tender' element={<CreateTenderPage />} />
      <Route path='tendersOverview/:tenderId/*' element={<TenderDetailsPage />} />
      <Route path='contracts/*' element={<ContractsOverviewPage />} />
      <Route path='suppliers/*' element={<SuppliersOverviewPage />} />
      <Route path='settings/*' element={<SettingsOverviewPage />} />
    </Routes>
  );
};
