// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.XLCULHVrrjCMV2kTWY7H {
  padding: 2rem;
  font-family: var(--font-secondary);
  font-size: var(--font-size--md);
  border-radius: 0.6rem;
  border: var(--primary-border);
  resize: none;
  flex: 2 1 auto;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}
.XLCULHVrrjCMV2kTWY7H:focus {
  outline: none;
}

.Xk1H6wfFvE9AhUNr76Hm {
  border: 1px solid var(--error-color);
}`, "",{"version":3,"sources":["webpack://./src/components/1-atoms/TextArea/TextArea.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,kCAAA;EACA,+BAAA;EAEA,qBAAA;EACA,6BAAA;EACA,YAAA;EACA,cAAA;EAMA,8BAAA,EAAA,gCAAA;EACA,2BAAA,EAAA,yBAAA;EACA,sBAAA,EAAA,gBAAA;AALF;AADE;EACE,aAAA;AAGJ;;AAKA;EACE,oCAAA;AAFF","sourcesContent":[".TextArea {\n  padding: 2rem;\n  font-family: var(--font-secondary);\n  font-size: var(--font-size--md);\n\n  border-radius: 0.6rem;\n  border: var(--primary-border);\n  resize: none;\n  flex: 2 1 auto;\n\n  &:focus {\n    outline: none;\n  }\n\n  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */\n  -moz-box-sizing: border-box; /* Firefox, other Gecko */\n  box-sizing: border-box; /* Opera/IE 8+ */\n}\n\n.TextArea__hasError {\n  border: 1px solid var(--error-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TextArea": `XLCULHVrrjCMV2kTWY7H`,
	"TextArea__hasError": `Xk1H6wfFvE9AhUNr76Hm`
};
export default ___CSS_LOADER_EXPORT___;
