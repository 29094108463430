// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PO8YW3nP9fi8t3pmiQUz {
  position: relative;
  display: inline-block;
  width: 100%;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.c3nSuvuruixv1UsLHXUX {
  background-color: var(--white);
  border: 0.2px solid var(--light-grey-150);
  box-shadow: 0rem 0.1rem 1.2rem rgba(0, 0, 0, 0.15);
  border-radius: 0.6rem;
  display: none;
  flex-direction: column;
  gap: 1rem;
  list-style: none;
  padding: 2rem;
  position: absolute;
  top: 100%;
  right: 0;
  width: max-content;
  z-index: 1;
  animation: IDeXxsHBBgjFqiSVDs3e 0.3s ease-in-out;
}

.kNQPpSSMwWgow81oX1NZ {
  display: flex;
}

.Vga2dINtAIZeGhtY5Hj6 {
  cursor: pointer;
  padding: 0 1rem;
}

@keyframes IDeXxsHBBgjFqiSVDs3e {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/2-molecules/Dropdown/Dropdown.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,qBAAA;EACA,WAAA;EACA,eAAA;EACA,6CAAA;AACF;;AAEA;EACE,8BAAA;EACA,yCAAA;EACA,kDAAA;EACA,qBAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;EACA,gBAAA;EACA,aAAA;EACA,kBAAA;EACA,SAAA;EACA,QAAA;EACA,kBAAA;EACA,UAAA;EACA,gDAAA;AACF;;AAEA;EACE,aAAA;AACF;;AAEA;EACE,eAAA;EACA,eAAA;AACF;;AAEA;EACE;IACE,UAAA;EACF;EACA;IACE,UAAA;EACF;AACF","sourcesContent":[".Dropdown {\n  position: relative;\n  display: inline-block;\n  width: 100%;\n  cursor: pointer;\n  transition: background-color 0.3s ease-in-out;\n}\n\n.Dropdown__menu {\n  background-color: var(--white);\n  border: 0.2px solid var(--light-grey-150);\n  box-shadow: 0rem 0.1rem 1.2rem rgba(0, 0, 0, 0.15);\n  border-radius: 0.6rem;\n  display: none;\n  flex-direction: column;\n  gap: 1rem;\n  list-style: none;\n  padding: 2rem;\n  position: absolute;\n  top: 100%;\n  right: 0;\n  width: max-content;\n  z-index: 1;\n  animation: fade-in 0.3s ease-in-out;\n}\n\n.Dropdown__menu_open {\n  display: flex;\n}\n\n.Dropdown__menuItem {\n  cursor: pointer;\n  padding: 0 1rem;\n}\n\n@keyframes fade-in {\n  from {\n    opacity: 0;\n  }\n  to {\n    opacity: 1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Dropdown": `PO8YW3nP9fi8t3pmiQUz`,
	"Dropdown__menu": `c3nSuvuruixv1UsLHXUX`,
	"fade-in": `IDeXxsHBBgjFqiSVDs3e`,
	"Dropdown__menu_open": `kNQPpSSMwWgow81oX1NZ`,
	"Dropdown__menuItem": `Vga2dINtAIZeGhtY5Hj6`
};
export default ___CSS_LOADER_EXPORT___;
