// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.XD08cCDG1r0HEM0tor_v {
  color: var(--dark-grey-900);
  font-weight: var(--font-weight--bold);
}

h2.XD08cCDG1r0HEM0tor_v {
  font-size: var(--font-size--3xl);
  line-height: 2.9rem;
}

h3.XD08cCDG1r0HEM0tor_v {
  font-size: var(--font-size--lg);
  line-height: 2.2rem;
}`, "",{"version":3,"sources":["webpack://./src/components/1-atoms/Heading/Heading.module.scss"],"names":[],"mappings":"AAAA;EACE,2BAAA;EACA,qCAAA;AACF;;AAEA;EACE,gCAAA;EACA,mBAAA;AACF;;AAEA;EACE,+BAAA;EACA,mBAAA;AACF","sourcesContent":[".Heading {\n  color: var(--dark-grey-900);\n  font-weight: var(--font-weight--bold);\n}\n\nh2.Heading {\n  font-size: var(--font-size--3xl);\n  line-height: 2.9rem;\n}\n\nh3.Heading {\n  font-size: var(--font-size--lg);\n  line-height: 2.2rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Heading": `XD08cCDG1r0HEM0tor_v`
};
export default ___CSS_LOADER_EXPORT___;
