// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wv8b_1TOuOVFKVvZ307x {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  background-color: var(--white);
  box-shadow: var(--primary-shadow);
  border: var(--secondary-border);
  padding: 3rem;
  border-radius: 1.2rem;
}

.uuD6tmJ2LEI1PLoIqana {
  margin-top: 8rem;
  margin-bottom: 4rem;
}

.sKqKo7zT8dZHO01AUOnc {
  rotate: 90deg;
}

.Iab2NGCYcWsenYUTHtYD {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mUxFvzooLkLNGclEQfFc {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.irtHwYxjHvEZ6dsvtqAo {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  max-height: 60rem;
  height: 100%;
  overflow-y: auto;
}`, "",{"version":3,"sources":["webpack://./src/components/3-organisms/TenderDetails/ChatOverview/ChatRoomDetails/ChatRoomDetails.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,8BAAA;EACA,iCAAA;EACA,+BAAA;EACA,aAAA;EACA,qBAAA;AACF;;AAEA;EACE,gBAAA;EACA,mBAAA;AACF;;AAEA;EACE,aAAA;AACF;;AAEA;EACE,WAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,SAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,iBAAA;EACA,YAAA;EACA,gBAAA;AACF","sourcesContent":[".ChatRoomDetails {\n  display: flex;\n  flex-direction: column;\n  gap: 3rem;\n  background-color: var(--white);\n  box-shadow: var(--primary-shadow);\n  border: var(--secondary-border);\n  padding: 3rem;\n  border-radius: 1.2rem;\n}\n\n.ChatRoomDetails__backButton {\n  margin-top: 8rem;\n  margin-bottom: 4rem;\n}\n\n.ChatRoomDetails__arrowIcon {\n  rotate: 90deg;\n}\n\n.ChatRoomDetails__header {\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.ChatRoomDetails__title {\n  display: flex;\n  align-items: center;\n  gap: 1rem;\n}\n\n.ChatRoomDetails__MessagesList {\n  display: flex;\n  flex-direction: column;\n  gap: 4rem;\n  max-height: 60rem;\n  height: 100%;\n  overflow-y: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ChatRoomDetails": `wv8b_1TOuOVFKVvZ307x`,
	"ChatRoomDetails__backButton": `uuD6tmJ2LEI1PLoIqana`,
	"ChatRoomDetails__arrowIcon": `sKqKo7zT8dZHO01AUOnc`,
	"ChatRoomDetails__header": `Iab2NGCYcWsenYUTHtYD`,
	"ChatRoomDetails__title": `mUxFvzooLkLNGclEQfFc`,
	"ChatRoomDetails__MessagesList": `irtHwYxjHvEZ6dsvtqAo`
};
export default ___CSS_LOADER_EXPORT___;
