// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.abI263knVzuv5OxsrC2Y {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  background-color: var(--white);
  box-shadow: var(--primary-shadow);
  border: var(--secondary-border);
  padding: 3rem;
  cursor: pointer;
}

.EJzgCQYRlmRJh7Sy4ABZ {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.oSb9KJZTqBXUKAiuxyQQ {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.CyaS8kzpZK9PiQGEA7B3 {
  display: flex;
  align-items: center;
  gap: 2rem;
  justify-content: flex-end;
}

.tocFRf3MFNTirslB91cL {
  display: flex;
  align-items: center;
  gap: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/components/3-organisms/TenderDetails/ChatOverview/ChatList/ChatRoomItem/ChatRoomItem.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,8BAAA;EACA,iCAAA;EACA,+BAAA;EACA,aAAA;EACA,eAAA;AACF;;AAEA;EACE,WAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,SAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,SAAA;EACA,yBAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,SAAA;AACF","sourcesContent":[".ChatRoomItem {\n  display: flex;\n  flex-direction: column;\n  gap: 3rem;\n  background-color: var(--white);\n  box-shadow: var(--primary-shadow);\n  border: var(--secondary-border);\n  padding: 3rem;\n  cursor: pointer;\n}\n\n.ChatRoomItem__header {\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.ChatRoomItem__title {\n  display: flex;\n  align-items: center;\n  gap: 1rem;\n}\n\n.ChatRoomItem__footer {\n  display: flex;\n  align-items: center;\n  gap: 2rem;\n  justify-content: flex-end;\n}\n\n.ChatRoomItem__footerItem {\n  display: flex;\n  align-items: center;\n  gap: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ChatRoomItem": `abI263knVzuv5OxsrC2Y`,
	"ChatRoomItem__header": `EJzgCQYRlmRJh7Sy4ABZ`,
	"ChatRoomItem__title": `oSb9KJZTqBXUKAiuxyQQ`,
	"ChatRoomItem__footer": `CyaS8kzpZK9PiQGEA7B3`,
	"ChatRoomItem__footerItem": `tocFRf3MFNTirslB91cL`
};
export default ___CSS_LOADER_EXPORT___;
