// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UhrvUpM2wreg7EJRjNN9 {
  display: flex;
  gap: 1.5rem;
  align-items: center;
}

.q7o0ab4WBaZ0_q8n5QNv {
  position: relative;
  cursor: pointer;
}
.q7o0ab4WBaZ0_q8n5QNv:hover .sAj7W2wtD0cVJEI6xZcu {
  display: block;
}

.sAj7W2wtD0cVJEI6xZcu {
  position: absolute;
  left: 3rem;
  bottom: -3rem;
  display: none;
}

.DXjTIy6r1i_ztq3WIr47 {
  width: 100%;
  height: 100%;
  padding: 2rem;
  box-shadow: var(--primary-shadow);
  background-color: var(--white);
}`, "",{"version":3,"sources":["webpack://./src/components/2-molecules/TextInfo/TextInfo.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,WAAA;EACA,mBAAA;AACF;;AAEA;EACE,kBAAA;EACA,eAAA;AACF;AAEI;EACE,cAAA;AAAN;;AAKA;EACE,kBAAA;EACA,UAAA;EACA,aAAA;EACA,aAAA;AAFF;;AAKA;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,iCAAA;EACA,8BAAA;AAFF","sourcesContent":[".TextInfo {\n  display: flex;\n  gap: 1.5rem;\n  align-items: center;\n}\n\n.TextInfo__descriptionIcon {\n  position: relative;\n  cursor: pointer;\n\n  &:hover {\n    .TextInfo__descriptionText {\n      display: block;\n    }\n  }\n}\n\n.TextInfo__descriptionText {\n  position: absolute;\n  left: 3rem;\n  bottom: -3rem;\n  display: none;\n}\n\n.TextInfo__descriptionBox {\n  width: 100%;\n  height: 100%;\n  padding: 2rem;\n  box-shadow: var(--primary-shadow);\n  background-color: var(--white);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TextInfo": `UhrvUpM2wreg7EJRjNN9`,
	"TextInfo__descriptionIcon": `q7o0ab4WBaZ0_q8n5QNv`,
	"TextInfo__descriptionText": `sAj7W2wtD0cVJEI6xZcu`,
	"TextInfo__descriptionBox": `DXjTIy6r1i_ztq3WIr47`
};
export default ___CSS_LOADER_EXPORT___;
