import { useEffect } from 'react';
import { LeadDetails } from '@/components/3-organisms';
import { LeadsLayout } from '@/components/4-templates';
import { useAppDispatch, useAppSelector } from '@/store';
import { setTitle } from '@/store/slices/headerSlice';
import { useParams, useNavigate } from 'react-router-dom';
import { selectLeadsFromSupplierProfile } from '@/store/slices/supplierProfileSlice';
import { participateInTender } from '@/services/TenderServices';
import styles from './LeadDetailsPage.module.scss';

export const LeadDetailsPage = () => {
  const { leadId } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const leadsOverview = useAppSelector(selectLeadsFromSupplierProfile);
  const lead = leadsOverview.leads.find((lead) => lead.id === parseInt(leadId ?? '0'));
  // || leadsOverview.purchasableLeads.find(lead => lead.id === parseInt(leadId ?? '0'));
  const organisationId = useAppSelector((state) => state.user?.activeOrg?.id);

  useEffect(() => {
    dispatch(setTitle(lead?.name || 'Lead details'));
  }, [dispatch, lead]);

  const handleActionTriggered = async () => {
    if (!lead?.tenderId || !organisationId) {
      console.error('Required information missing to participate in tender.');
      return;
    }

    try {
      const response = await participateInTender(organisationId, lead.tenderId);
      if (response.successfulOperation) {
        navigate(`/leads/tendersOverview/${lead.tenderId}/tenderDetails`);
      } else {
        throw new Error('Failed to participate in tender.');
      }
    } catch (error) {
      console.error('Failed to participate in tender:', error);
    }
  };

  return (
    <LeadsLayout>
      <section className={styles.LeadDetailsPage}>
        <LeadDetails
          className={styles.LeadDetails}
          lead={lead}
          onActionTriggered={handleActionTriggered}
        />
      </section>
    </LeadsLayout>
  );
};
