import classNames from 'classnames';
import styles from './FormErrorMessage.module.scss';

export interface FormErrorMessageProps {
  className?: string;
  id: string;
  children?: React.ReactNode;
}

export const FormErrorMessage = ({ id, className, children }: FormErrorMessageProps) => {
  return (
    <div id={id} className={classNames(styles.FormErrorMessage, className)}>
      {children}
    </div>
  );
};
