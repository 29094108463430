import classNames from 'classnames';
import { Heading, Paragraph } from '@/components/1-atoms';
import styles from './Details.module.scss';
import { TextInfo } from '@/components/2-molecules';
import { PhaseAccordion } from './PhaseAccordion';

export interface DetailsProps {
  className?: string;
  tender?: TenderDetails;
}

export const Details = ({ className, tender }: DetailsProps) => {
  const { name, description, isAnonymous, underCurrentContract, expiresAt, type, phases } =
    tender?.details ?? {};

  return (
    <div className={classNames(styles.Details, className)}>
      <div className={styles.DetailsHeading}>
        <TextInfo headingLevel={'h2'} title={'Tender details'} description={'description'} />
      </div>

      <div className={styles.Details__dataRow}>
        <Heading className={styles.Details__rowHeading} headingLevel={'h3'}>
          Type:
        </Heading>
        <Paragraph>{type}</Paragraph>
      </div>

      <div className={styles.Details__dataRow}>
        <Heading className={styles.Details__rowHeading} headingLevel={'h3'}>
          Name:
        </Heading>
        <Paragraph>{name}</Paragraph>
      </div>

      <div className={styles.Details__dataRow}>
        <Heading className={styles.Details__rowHeading} headingLevel={'h3'}>
          Description:
        </Heading>
        <Paragraph>{description}</Paragraph>
      </div>

      <div className={styles.Details__dataRow}>
        <Heading className={styles.Details__rowHeading} headingLevel={'h3'}>
          Anonymous:
        </Heading>
        <Paragraph>{isAnonymous ? 'Yes' : 'No'}</Paragraph>
      </div>

      <div className={styles.Details__dataRow}>
        <Heading className={styles.Details__rowHeading} headingLevel={'h3'}>
          Current contract:
        </Heading>
        <Paragraph>{underCurrentContract ? 'Yes' : 'No'}</Paragraph>
      </div>

      <div className={styles.Details__dataRow}>
        <Heading className={styles.Details__rowHeading} headingLevel={'h3'}>
          Expires:
        </Heading>
        <Paragraph>{expiresAt}</Paragraph>
      </div>

      {phases && (
        <>
          <div className={styles.Details__dataRow}>
            <Heading className={styles.Details__rowHeading} headingLevel={'h2'}>
              Phases:
            </Heading>
          </div>

          <div className={styles.Details__phasesList}>
            {phases.map((phase, index) => (
              <PhaseAccordion key={index} phase={phase} />
            ))}
          </div>
        </>
      )}
    </div>
  );
};
