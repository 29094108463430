import classNames from 'classnames';
import styles from './StepIndicator.module.scss';

interface StepIndicatorProps {
  steps: string[];
  currentStep: number;
}

export const StepIndicator = ({ steps, currentStep }: StepIndicatorProps) => {
  return (
    <div className={styles.StepIndicator}>
      <ol className={styles.StepIndicator__list}>
        {steps.map((step, index) => (
          <li key={index} className={styles.StepIndicator__listItem}>
            <span
              className={classNames(
                styles.StepIndicator__listCircle,
                currentStep === index && styles.StepIndicator__listCircle__current,
              )}
            >
              {index + 1}
            </span>
            <p className={styles.StepIndicator__listText}>{step}</p>
          </li>
        ))}
      </ol>
    </div>
  );
};
