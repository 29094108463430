import React, { useState } from 'react';
import styles from './Accordion.module.scss';
import classNames from 'classnames';
import { ReactComponent as ArrowIcon } from '@/assets/Icons/icon-arrow-accordion.svg';

export interface AccordionProps {
  heading: React.ReactNode;
  children: React.ReactNode;
  clickable?: boolean;
  className?: string;
  buttonClass?: string;
  startOpen?: boolean;
}

export const Accordion = ({
  heading,
  children,
  clickable = true,
  startOpen = false,
  className,
  buttonClass,
}: AccordionProps) => {
  const [isOpen, setIsOpen] = useState(startOpen);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div
      role='button'
      className={classNames(styles.Accordion, className, isOpen && styles.Accordion_open)}
    >
      <div
        className={classNames(styles.Accordion__button, buttonClass)}
        onClick={clickable ? toggleAccordion : undefined}
      >
        {heading}
        <span className={classNames(styles.Accordion__icon, isOpen && styles.Accordion__icon_open)}>
          <ArrowIcon />
        </span>
      </div>

      {isOpen && (
        <div className={styles.Accordion__contentWrapper}>
          <div className={styles.Accordion__content}>{children}</div>
        </div>
      )}
    </div>
  );
};
