// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vaDjbufrutni8g2MLCBr {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(4, 1fr) 2fr 1fr;
  grid-column-gap: 4rem;
  grid-row-gap: 4rem;
}

.UOOOXGQGQl1jopoezmph {
  grid-area: 1/1/2/3;
}

.BeDv6oTBMNR4l9miWx2S {
  align-self: center;
  justify-self: baseline;
}
@media screen and (min-width: 1490px) {
  .BeDv6oTBMNR4l9miWx2S {
    margin-top: 2.5rem;
  }
}

.mYk94h2zrzQg7JMcPSUM {
  align-self: center;
  justify-self: center;
  grid-area: 6/1/7/3;
}

.uXfDBgG2MRdvSWK6jvce {
  display: flex;
  flex-flow: column;
  gap: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/components/3-organisms/ContractsOverview/ChallengeContractForm/ChallengeContractForm.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,qCAAA;EACA,0CAAA;EACA,qBAAA;EACA,kBAAA;AACF;;AAEA;EACE,kBAAA;AACF;;AAEA;EACE,kBAAA;EACA,sBAAA;AACF;AACE;EAJF;IAKI,kBAAA;EAEF;AACF;;AACA;EACE,kBAAA;EACA,oBAAA;EACA,kBAAA;AAEF;;AACA;EACE,aAAA;EACA,iBAAA;EACA,SAAA;AAEF","sourcesContent":[".ChallengeContractForm {\n  display: grid;\n  grid-template-columns: repeat(2, 1fr);\n  grid-template-rows: repeat(4, 1fr) 2fr 1fr;\n  grid-column-gap: 4rem;\n  grid-row-gap: 4rem;\n}\n\n.ChallengeContractForm__heading {\n  grid-area: 1 / 1 / 2 / 3;\n}\n\n.ChallengeContractForm__addSupplierButton {\n  align-self: center;\n  justify-self: baseline;\n\n  @media screen and (min-width: 1490px) {\n    margin-top: 2.5rem;\n  }\n}\n\n.ChallengeContractForm__submitButton {\n  align-self: center;\n  justify-self: center;\n  grid-area: 6 / 1 / 7 / 3;\n}\n\n.ChallengeContractForm__notesTextArea {\n  display: flex;\n  flex-flow: column;\n  gap: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ChallengeContractForm": `vaDjbufrutni8g2MLCBr`,
	"ChallengeContractForm__heading": `UOOOXGQGQl1jopoezmph`,
	"ChallengeContractForm__addSupplierButton": `BeDv6oTBMNR4l9miWx2S`,
	"ChallengeContractForm__submitButton": `mYk94h2zrzQg7JMcPSUM`,
	"ChallengeContractForm__notesTextArea": `uXfDBgG2MRdvSWK6jvce`
};
export default ___CSS_LOADER_EXPORT___;
