import classNames from 'classnames';
import styles from './Image.module.scss';

export interface ImageProps {
  className?: string;
  src: string;
  alt: string;
}

export const Image = ({ alt, className, src }: ImageProps) => (
  <img className={classNames(styles.Image, className)} src={src} alt={alt} />
);
