// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NbFgfeWGT_xqBd9X2gjw {
  border: var(--primary-border);
  border-collapse: collapse;
  width: 100%;
}

.E9nFR6ZkT8uWrqOqxvnE {
  border-bottom: var(--primary-border);
  display: flex;
  padding: 2rem;
}

.HKEi1V1L0tC1wEa8cqbI {
  text-align: left;
  font-weight: var(--font-weight--bold);
  font-size: var(--font-size--lg);
  flex: 1 1 0;
}

.Sg07TkXdNLN722xTNSxp {
  font-family: var(--font-secondary);
  font-weight: var(--font-weight--normal);
  color: var(--dark-grey-900);
  font-size: var(--font-size--md);
  display: flex;
  align-items: center;
  flex: 1 1 0;
}

.EPdaA1xSVtPKJPoqp7wF {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/2-molecules/DataTable/DataTable.module.scss"],"names":[],"mappings":"AAAA;EACE,6BAAA;EACA,yBAAA;EACA,WAAA;AACF;;AAEA;EACE,oCAAA;EACA,aAAA;EACA,aAAA;AACF;;AAEA;EACE,gBAAA;EACA,qCAAA;EACA,+BAAA;EACA,WAAA;AACF;;AAEA;EACE,kCAAA;EACA,uCAAA;EACA,2BAAA;EACA,+BAAA;EAEA,aAAA;EACA,mBAAA;EACA,WAAA;AAAF;;AAGA;EACE,eAAA;AAAF","sourcesContent":[".DataTable {\n  border: var(--primary-border);\n  border-collapse: collapse;\n  width: 100%;\n}\n\n.DataTable__tableRow {\n  border-bottom: var(--primary-border);\n  display: flex;\n  padding: 2rem;\n}\n\n.DataTable__tableHeader {\n  text-align: left;\n  font-weight: var(--font-weight--bold);\n  font-size: var(--font-size--lg);\n  flex: 1 1 0;\n}\n\n.DataTable__tableCell {\n  font-family: var(--font-secondary);\n  font-weight: var(--font-weight--normal);\n  color: var(--dark-grey-900);\n  font-size: var(--font-size--md);\n\n  display: flex;\n  align-items: center;\n  flex: 1 1 0;\n}\n\n.DataTable__tableCell_clickable {\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"DataTable": `NbFgfeWGT_xqBd9X2gjw`,
	"DataTable__tableRow": `E9nFR6ZkT8uWrqOqxvnE`,
	"DataTable__tableHeader": `HKEi1V1L0tC1wEa8cqbI`,
	"DataTable__tableCell": `Sg07TkXdNLN722xTNSxp`,
	"DataTable__tableCell_clickable": `EPdaA1xSVtPKJPoqp7wF`
};
export default ___CSS_LOADER_EXPORT___;
