// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.KgzgVlOmncgSuiznUDC8 {
  color: var(--dark-grey-900);
  background-color: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  cursor: pointer;
  border: none;
}

.muLK32Zkp6kt0GDsbVrA {
  flex-flow: row-reverse;
}`, "",{"version":3,"sources":["webpack://./src/components/2-molecules/IconButton/IconButton.module.scss"],"names":[],"mappings":"AAAA;EACE,2BAAA;EACA,uBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,SAAA;EACA,eAAA;EACA,YAAA;AACF;;AAEA;EACE,sBAAA;AACF","sourcesContent":[".IconButton {\n  color: var(--dark-grey-900);\n  background-color: unset;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  gap: 1rem;\n  cursor: pointer;\n  border: none;\n}\n\n.IconButton__IconRight {\n  flex-flow: row-reverse;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"IconButton": `KgzgVlOmncgSuiznUDC8`,
	"IconButton__IconRight": `muLK32Zkp6kt0GDsbVrA`
};
export default ___CSS_LOADER_EXPORT___;
