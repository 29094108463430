import { useState, useCallback, useEffect } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { WebSocketMessage } from 'react-use-websocket/dist/lib/types';

const useChatWebSocket = (roomId: string, contactId: string, startConnection?: boolean) => {
  const apiBaseUrl = process.env.API_BASEURL;

  // Check if the environment variable is defined
  if (!apiBaseUrl) {
    throw new Error('API_BASEURL environment variable is not defined');
  }

  const url = new URL(apiBaseUrl);
  url.protocol = url.protocol.replace('https', 'wss');
  url.port = '443';

  const [socketUrl, setSocketUrl] = useState(
    `${url}/chat/getwebsocket?roomId=${roomId}&contactId=${contactId}`,
  );

  const [messageHistory, setMessageHistory] = useState<MessageEvent<MessageType>[]>([]);

  const { sendMessage, lastMessage, readyState } = useWebSocket(
    socketUrl,
    {
      onOpen: () => console.log('Connected!'),
      onClose: (event: WebSocketEventMap['close']) => console.log('Disconnected!', event),
      onMessage: (message) => console.log('Received message:', message),
      shouldReconnect: (closeEvent) => true,
      reconnectAttempts: 10,
      reconnectInterval: 3000,
      onError: (error) => console.error('WebSocket Error:', error),
    },
    startConnection,
  );

  useEffect(() => {
    if (lastMessage !== null) {
      const parsedData = JSON.parse(lastMessage.data);
      const mappedMessage: MessageType = {
        content: parsedData.Content,
        isRead: parsedData.IsRead,
        sentAt: parsedData.SentAt,
        senderContactId: parsedData.SenderContactId,
      };

      setMessageHistory((prev) => [
        ...prev,
        new MessageEvent<MessageType>('message', {
          data: mappedMessage,
        }),
      ]);
    }
  }, [lastMessage]);

  const handleClickChangeSocketUrl = useCallback((newUrl: string) => setSocketUrl(newUrl), []);

  const sendMessageToServer = useCallback(
    (message: WebSocketMessage) => {
      sendMessage(message);

      const newMessage: MessageEvent<MessageType> = new MessageEvent('message', {
        data: {
          content: message as string,
          isRead: false,
          sentAt: new Date().toISOString(),
          senderContactId: contactId,
        },
      });

      setMessageHistory((prev) => [...prev, newMessage]);
    },
    [sendMessage, contactId],
  );

  return {
    sendMessage: sendMessageToServer,
    lastMessage,
    readyState,
    messageHistory,
    handleClickChangeSocketUrl,
  };
};

export default useChatWebSocket;
