// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.uv6NIWfigaMjr3sPeHEA {
  padding: 0.5rem 1.5rem;
  text-transform: capitalize;
  border-radius: 8.5rem;
}

.xGe_XXjZFKK1oktrUEzq {
  background: rgba(10, 110, 209, 0.2509803922);
  color: var(--information-color);
}

.JAewTkFZSMyNk6VefvnS {
  background: rgba(119, 121, 137, 0.2509803922);
  color: var(--storm-grey);
}

.VMrsivczRjyFlPpf_C4W {
  background: rgba(53, 179, 44, 0.2509803922);
  color: var(--success-color);
}`, "",{"version":3,"sources":["webpack://./src/components/1-atoms/StatusText/StatusText.module.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;EACA,0BAAA;EACA,qBAAA;AACF;;AAEA;EACE,4CAAA;EACA,+BAAA;AACF;;AAEA;EACE,6CAAA;EACA,wBAAA;AACF;;AAEA;EACE,2CAAA;EACA,2BAAA;AACF","sourcesContent":[".StatusText {\n  padding: 0.5rem 1.5rem;\n  text-transform: capitalize;\n  border-radius: 8.5rem;\n}\n\n.StatusText_active {\n  background: #0a6ed140; // TODO: add color\n  color: var(--information-color);\n}\n\n.StatusText_inactive {\n  background: #77798940; // TODO: add color\n  color: var(--storm-grey);\n}\n\n.StatusText_finished {\n  background: #35b32c40; // TODO: add color\n  color: var(--success-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"StatusText": `uv6NIWfigaMjr3sPeHEA`,
	"StatusText_active": `xGe_XXjZFKK1oktrUEzq`,
	"StatusText_inactive": `JAewTkFZSMyNk6VefvnS`,
	"StatusText_finished": `VMrsivczRjyFlPpf_C4W`
};
export default ___CSS_LOADER_EXPORT___;
