// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BoKOsx0lUMxgs7Ajg24f {
  padding: 8rem 5rem 3rem 5rem;
}

.PdQPmGno376pag18w8KT {
  background-color: var(--light-grey-100);
}

.J8AMgxKTZANcp8CJgwYM {
  margin: 4.5rem 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

.dsTRvDWZ0YgO4rv60DCU {
  display: flex;
  gap: 5rem;
}

.O7YSx16keWGV6iZgHB3x {
  width: 50%;
}`, "",{"version":3,"sources":["webpack://./src/components/5-pages/tenders/SuppliersOverviewPage/SuppliersOverviewPage.module.scss"],"names":[],"mappings":"AAAA;EACE,4BAAA;AACF;;AAEA;EACE,uCAAA;AACF;;AAEA;EACE,gBAAA;EACA,WAAA;EACA,aAAA;EACA,uBAAA;AACF;;AAEA;EACE,aAAA;EACA,SAAA;AACF;;AAEA;EACE,UAAA;AACF","sourcesContent":[".SuppliersOverviewPage {\n  padding: 8rem 5rem 3rem 5rem;\n}\n\n.SuppliersOverviewPage__formContainer {\n  background-color: var(--light-grey-100);\n}\n\n.SuppliersOverviewPage__createTenderCta {\n  margin: 4.5rem 0;\n  width: 100%;\n  display: flex;\n  justify-content: center;\n}\n\n.SuppliersOverviewPage__eventsSection {\n  display: flex;\n  gap: 5rem;\n}\n\n.SuppliersOverviewPage__calendar {\n  width: 50%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SuppliersOverviewPage": `BoKOsx0lUMxgs7Ajg24f`,
	"SuppliersOverviewPage__formContainer": `PdQPmGno376pag18w8KT`,
	"SuppliersOverviewPage__createTenderCta": `J8AMgxKTZANcp8CJgwYM`,
	"SuppliersOverviewPage__eventsSection": `dsTRvDWZ0YgO4rv60DCU`,
	"SuppliersOverviewPage__calendar": `O7YSx16keWGV6iZgHB3x`
};
export default ___CSS_LOADER_EXPORT___;
