import classNames from 'classnames';
import styles from './ChatInput.module.scss';
import { Paragraph, TextArea } from '@/components/1-atoms';
import { useRef, useState } from 'react';
import { Button, IconButton } from '@/components/2-molecules';
import { ReactComponent as UploadIcon } from '@/assets/Icons/icon-upload.svg';

export interface ChatInputProps {
  className?: string;
  onMessageSubmit: (message: string) => void;
}

export const ChatInput = ({ className, onMessageSubmit }: ChatInputProps) => {
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const handleChange = () => {
    if (inputRef?.current?.value) setIsButtonDisabled(false);
    else setIsButtonDisabled(true);
  };
  const onSubmit = () => {
    const input = inputRef?.current;
    if (!input) return;
    onMessageSubmit(input?.value);
    input.value = '';
    setIsButtonDisabled(true);
  };

  return (
    <div className={classNames(styles.ChatInput, className)}>
      <TextArea
        className={styles.ChatInput__input}
        rows={2}
        placeholder='Write a response'
        reference={inputRef}
        onChange={handleChange}
      />

      <div className={styles.ChatInput__actionsWrapper}>
        <IconButton icon={<UploadIcon />} IconClass={styles.ChatInput__icon}>
          <Paragraph>Upload files</Paragraph>
        </IconButton>
        <Button
          className={styles.ChatInput__submitButton}
          type='primary'
          isDisabled={isButtonDisabled}
          onClick={() => onSubmit()}
        >
          Send
        </Button>
      </div>
    </div>
  );
};
