import { useEffect } from 'react';
import classNames from 'classnames';
import { Route, Routes, useLocation } from 'react-router-dom';

import { SuppliersOverview, AddSupplierForm } from '@/components/3-organisms';
import { TendersLayout } from '@/components/4-templates';
import { useAppDispatch, useAppSelector } from '@/store';
import { setTitle } from '@/store/slices/headerSlice';
import { getSuppliersList } from '@/store/slices/suppliersSlice';

import styles from './SuppliersOverviewPage.module.scss';

export const SuppliersOverviewPage = () => {
  const dispatch = useAppDispatch();
  const suppliersList = useAppSelector((state) => state.suppliers?.suppliersList);
  const location = useLocation();

  const isOnNewSupplierPage = location.pathname.endsWith('/new-supplier');

  useEffect(() => {
    dispatch(setTitle(isOnNewSupplierPage ? 'Add new supplier' : 'suppliers'));
    dispatch(getSuppliersList());
  }, [isOnNewSupplierPage]);

  return (
    <TendersLayout>
      <section
        className={classNames(
          styles.SuppliersOverviewPage,
          isOnNewSupplierPage && styles.SuppliersOverviewPage__formContainer,
        )}
      >
        <Routes>
          <Route path='/' element={<SuppliersOverview suppliersList={suppliersList} />} />
          <Route path='new-supplier' element={<AddSupplierForm />} />
        </Routes>
      </section>
    </TendersLayout>
  );
};
