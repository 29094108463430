// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Blm7cB6T05Hq_xQ_8Vbc {
  border-radius: 4rem;
  padding: 1.4rem 4.5rem;
  cursor: pointer;
  font-weight: var(--font-weight--bold);
  font-size: var(--font-size--lg);
  line-height: 2.2rem;
  min-width: 15rem;
  transition: all 0.2s ease;
}
.Blm7cB6T05Hq_xQ_8Vbc:active {
  transform: translateY(0.2rem);
}
.Blm7cB6T05Hq_xQ_8Vbc:disabled {
  cursor: not-allowed;
  background: rgba(0, 0, 0, 0.08);
  color: rgba(0, 0, 0, 0.3);
}

.Ty9z_c6Ne6sNjQT79ea_ {
  background-color: var(--accent-color);
  color: var(--white);
  border: none;
}

.mrIfvavoPde8pnJfDxLx {
  background-color: transparent;
  color: var(--accent-color);
  border: 2px solid var(--accent-color);
}

.aHRKuRlmuZIgCxKWYZ9u {
  background-color: var(--secondary-color);
  color: var(--white);
  border: none;
}

.UZ1ZV9L0kfQDfxMtepHG {
  background-color: var(--light-grey-100);
  border: 2px solid var(--light-grey-300);
  color: var(--dark-grey-600);
}

.p0P1j93XC3cI1LVXzjld {
  border: unset;
  border-radius: unset;
  padding: unset;
  min-width: unset;
  color: var(--accent-color);
  background-color: unset;
}

.GYNyPB0uFSo7W1QP9cPm {
  border: unset;
  border-radius: unset;
  padding: unset;
  min-width: unset;
  color: var(--light-grey-500);
  background-color: unset;
}

._h8iBnWIBySQ_dsR2NdM {
  text-decoration: none;
}`, "",{"version":3,"sources":["webpack://./src/components/2-molecules/Button/Button.module.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,sBAAA;EACA,eAAA;EACA,qCAAA;EACA,+BAAA;EACA,mBAAA;EACA,gBAAA;EACA,yBAAA;AACF;AAAE;EACE,6BAAA;AAEJ;AACE;EACE,mBAAA;EACA,+BAAA;EACA,yBAAA;AACJ;;AAGA;EACE,qCAAA;EACA,mBAAA;EACA,YAAA;AAAF;;AAGA;EACE,6BAAA;EACA,0BAAA;EACA,qCAAA;AAAF;;AAGA;EACE,wCAAA;EACA,mBAAA;EACA,YAAA;AAAF;;AAGA;EACE,uCAAA;EACA,uCAAA;EACA,2BAAA;AAAF;;AAGA;EACE,aAAA;EACA,oBAAA;EACA,cAAA;EACA,gBAAA;EACA,0BAAA;EACA,uBAAA;AAAF;;AAGA;EACE,aAAA;EACA,oBAAA;EACA,cAAA;EACA,gBAAA;EACA,4BAAA;EACA,uBAAA;AAAF;;AAGA;EACE,qBAAA;AAAF","sourcesContent":[".Button {\n  border-radius: 4rem;\n  padding: 1.4rem 4.5rem;\n  cursor: pointer;\n  font-weight: var(--font-weight--bold);\n  font-size: var(--font-size--lg);\n  line-height: 2.2rem;\n  min-width: 15rem;\n  transition: all 0.2s ease;\n  &:active {\n    transform: translateY(0.2rem);\n  }\n\n  &:disabled {\n    cursor: not-allowed;\n    background: rgba(0, 0, 0, 0.08);\n    color: rgba(0, 0, 0, 0.3);\n  }\n}\n\n.Button__primary {\n  background-color: var(--accent-color);\n  color: var(--white);\n  border: none;\n}\n\n.Button__primaryColor {\n  background-color: transparent;\n  color: var(--accent-color);\n  border: 2px solid var(--accent-color);\n}\n\n.Button__secondaryColor {\n  background-color: var(--secondary-color);\n  color: var(--white);\n  border: none;\n}\n\n.Button__secondary {\n  background-color: var(--light-grey-100);\n  border: 2px solid var(--light-grey-300);\n  color: var(--dark-grey-600);\n}\n\n.Button__borderlessPrimary {\n  border: unset;\n  border-radius: unset;\n  padding: unset;\n  min-width: unset;\n  color: var(--accent-color);\n  background-color: unset;\n}\n\n.Button__borderlessSecondary {\n  border: unset;\n  border-radius: unset;\n  padding: unset;\n  min-width: unset;\n  color: var(--light-grey-500);\n  background-color: unset;\n}\n\n.Button__link {\n  text-decoration: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Button": `Blm7cB6T05Hq_xQ_8Vbc`,
	"Button__primary": `Ty9z_c6Ne6sNjQT79ea_`,
	"Button__primaryColor": `mrIfvavoPde8pnJfDxLx`,
	"Button__secondaryColor": `aHRKuRlmuZIgCxKWYZ9u`,
	"Button__secondary": `UZ1ZV9L0kfQDfxMtepHG`,
	"Button__borderlessPrimary": `p0P1j93XC3cI1LVXzjld`,
	"Button__borderlessSecondary": `GYNyPB0uFSo7W1QP9cPm`,
	"Button__link": `_h8iBnWIBySQ_dsR2NdM`
};
export default ___CSS_LOADER_EXPORT___;
