import classNames from 'classnames';
import { Heading, Paragraph } from '@/components/1-atoms';
import styles from './ResponseAccordion.module.scss';
import { Accordion, IconButton } from '@/components/2-molecules';
import { ReactComponent as AddNoteIcon } from '@/assets/Icons/icon-add-file.svg';
import { ReactComponent as DownloadIcon } from '@/assets/Icons/icon-download.svg';

export interface ResponseAccordionProps {
  className?: string;
  heading?: React.ReactNode;
  startOpen?: boolean;
  responsesDetails?: {
    id: string;
    question?: string;
    answer?: string;
    score?: string;
    note?: string;
    url?: string;
  };
}

export const ResponseAccordion = ({
  className,
  responsesDetails,
  heading,
}: ResponseAccordionProps) => {
  const { question, answer, url } = responsesDetails || {};

  return (
    <div className={classNames(styles.ResponseAccordion, className)}>
      <div className={styles.ResponseItem__heading}>
        <Heading headingLevel={'h3'}>{question}</Heading>
        {/* {heading} */}
      </div>
      <div className={styles.ResponseAccordion__content}>
        <Paragraph>{answer}</Paragraph>
        <div className={styles.ResponseAccordion__footer}>
          {url ? <IconButton icon={<DownloadIcon />}>Download</IconButton> : <span />}
          <div className={styles.ResponseAccordion__scoreWrapper}>
            <Paragraph>Score:</Paragraph>
            <input className={styles.ResponseAccordion__scoreInput} type='text' />
            <IconButton icon={<AddNoteIcon />} />
          </div>
        </div>
      </div>
    </div>
  );
};
