import styles from './DataTableCheckbox.module.scss';
import classNames from 'classnames';

export interface DataTableCheckboxProps {
  className?: string;
  checked: boolean;
  disabled?: boolean;
  onChange: (event: unknown) => void;
  label?: string;
}

export const DataTableCheckbox = ({
  className,
  checked,
  disabled,
  onChange,
}: DataTableCheckboxProps) => {
  return (
    <label className={classNames(styles.DataTableCheckbox, className)}>
      <input
        disabled={disabled}
        onChange={(e) => onChange(e)}
        type='checkbox'
        className={styles.DataTableCheckbox__input}
        checked={checked}
      />
      <span className={styles.DataTableCheckbox__checkMark} />
    </label>
  );
};
