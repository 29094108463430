// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.jhBWd_s9MlraSUvS0TRA {
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  align-items: flex-start;
}

.rr_AhTfKRBCUH5vB5rZf {
  padding: 2rem;
  gap: 2rem;
  margin-top: 3rem;
  border-radius: 0.6rem;
  background: var(--white);
  box-shadow: var(--primary-shadow);
}

.peolRjCy9Hbllxuvf7FP {
  width: 100%;
  height: 90%;
  gap: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.XhIJi9GvYmidDc1CUeQs {
  width: 60rem;
  display: flex;
  flex-direction: column;
  padding: 5rem;
  gap: 2rem;
  align-items: center;
}

.wFPvK6XEgfU3sM8bZ7iy {
  margin-bottom: 2rem;
}

.t63zSsGtT7niyXhUnAjn {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 4rem;
}

.upk6sxX6o4QbMXYpk1_K {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.YAJhZzmBK6spEViEAwxK {
  display: flex;
  align-items: center;
  gap: 3rem;
}

.HaxXCopZAV8S1rUaB3Wb {
  width: 2rem;
  height: 2rem;
}`, "",{"version":3,"sources":["webpack://./src/components/3-organisms/TenderDetails/NoticeBoard/NoticeBoard.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,WAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;EACA,uBAAA;AACF;;AAEA;EACE,aAAA;EACA,SAAA;EACA,gBAAA;EACA,qBAAA;EACA,wBAAA;EACA,iCAAA;AACF;;AAEA;EACE,WAAA;EACA,WAAA;EACA,SAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;AACF;;AAEA;EACE,YAAA;EACA,aAAA;EACA,sBAAA;EACA,aAAA;EACA,SAAA;EACA,mBAAA;AACF;;AAEA;EACE,mBAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,aAAA;AACF;;AAEA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,SAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;AACF","sourcesContent":[".NoticeBoard {\n  width: 100%;\n  height: 90%;\n  display: flex;\n  flex-direction: column;\n  gap: 4rem;\n  align-items: flex-start;\n}\n\n.NoticeBoard__addNoticeButton {\n  padding: 2rem;\n  gap: 2rem;\n  margin-top: 3rem;\n  border-radius: 0.6rem;\n  background: var(--white);\n  box-shadow: var(--primary-shadow);\n}\n\n.NoticeBoard__emptyList {\n  width: 100%;\n  height: 90%;\n  gap: 2rem;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n}\n\n.NoticeBoard__addNoticeCard {\n  width: 60rem;\n  display: flex;\n  flex-direction: column;\n  padding: 5rem;\n  gap: 2rem;\n  align-items: center;\n}\n\n.NoticeBoard__addNoticeHeading {\n  margin-bottom: 2rem;\n}\n\n.NoticeBoard__popup {\n  display: flex;\n  flex-direction: column;\n  gap: 2rem;\n  padding: 4rem;\n}\n\n.NoticeBoard__popupFooter {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.NoticeBoard__actionButtonsWrapper {\n  display: flex;\n  align-items: center;\n  gap: 3rem;\n}\n\n.NoticeBoard__uploadIcon {\n  width: 2rem;\n  height: 2rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"NoticeBoard": `jhBWd_s9MlraSUvS0TRA`,
	"NoticeBoard__addNoticeButton": `rr_AhTfKRBCUH5vB5rZf`,
	"NoticeBoard__emptyList": `peolRjCy9Hbllxuvf7FP`,
	"NoticeBoard__addNoticeCard": `XhIJi9GvYmidDc1CUeQs`,
	"NoticeBoard__addNoticeHeading": `wFPvK6XEgfU3sM8bZ7iy`,
	"NoticeBoard__popup": `t63zSsGtT7niyXhUnAjn`,
	"NoticeBoard__popupFooter": `upk6sxX6o4QbMXYpk1_K`,
	"NoticeBoard__actionButtonsWrapper": `YAJhZzmBK6spEViEAwxK`,
	"NoticeBoard__uploadIcon": `HaxXCopZAV8S1rUaB3Wb`
};
export default ___CSS_LOADER_EXPORT___;
