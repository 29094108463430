// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.xloihhZcSwuO2XbpLbyI {
  display: flex;
  list-style-type: none;
  border-bottom: solid 1px var(--primary-color);
  width: fit-content;
}

.xTRHO88tKYQJnv4TWV4L {
  padding: 1.5rem 2.2rem;
  border-radius: 0.6rem 0.6rem 0rem 0rem;
  border: solid 1px transparent;
  cursor: pointer;
}
.vSdIv5V1uN8HGFNxSlwP {
  background-color: var(--primary-color);
}
.vSdIv5V1uN8HGFNxSlwP h3 {
  color: var(--white);
}`, "",{"version":3,"sources":["webpack://./src/components/2-molecules/TabsNavigation/TabsNavigation.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,qBAAA;EACA,6CAAA;EACA,kBAAA;AACF;;AAEA;EACE,sBAAA;EACA,sCAAA;EACA,6BAAA;EACA,eAAA;AACF;AACE;EACE,sCAAA;AACJ;AAAI;EACE,mBAAA;AAEN","sourcesContent":[".TabsNavigation {\n  display: flex;\n  list-style-type: none;\n  border-bottom: solid 1px var(--primary-color);\n  width: fit-content;\n}\n\n.TabsNavigation__item {\n  padding: 1.5rem 2.2rem;\n  border-radius: 0.6rem 0.6rem 0rem 0rem;\n  border: solid 1px transparent;\n  cursor: pointer;\n\n  &_active {\n    background-color: var(--primary-color);\n    h3 {\n      color: var(--white);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TabsNavigation": `xloihhZcSwuO2XbpLbyI`,
	"TabsNavigation__item": `xTRHO88tKYQJnv4TWV4L`,
	"TabsNavigation__item_active": `vSdIv5V1uN8HGFNxSlwP`
};
export default ___CSS_LOADER_EXPORT___;
