
import classNames from 'classnames';
import styles from './ContentCard.module.scss';

export interface ContentCardProps {
  actionButton?: React.ReactNode;
  children: React.ReactNode;
  className?: string;
  heading?: React.ReactNode;
}

export const ContentCard = ({ actionButton, children, className, heading }: ContentCardProps) => {
  return (
    <div className={classNames(styles.ContentCard, className)}>
      {heading && (
        <div className={styles.ContentCard__heading}>
          {heading}
          {actionButton && actionButton}
        </div>
      )}
      <div className={styles.ContentCard__body}>{children}</div>
    </div>
  );
};
