import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { LoginUser } from '@/services/LoginServices';
import { jwtDecode } from 'jwt-decode'; // Add this line to use jwt-decode

export interface UserLoginState {
  loginProfile: LoginModel | null;
  token: string | unknown;
  activeOrg: Organisation | undefined;
  tokenExpirationDate: string;
}

const initialState: UserLoginState = {
  loginProfile: null,
  token: null,
  activeOrg: undefined,
  tokenExpirationDate: new Date(new Date().getTime() + 30 * 60 * 1000).toISOString(), // 30 minutes from now
};

export const getUserLogin = createAsyncThunk('user/getUserLogin', async () => {
  return await LoginUser();
});

export const userLoginSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setAccessToken: (state, action: PayloadAction<string>) => {
      const decodedToken = jwtDecode(action.payload) ?? null;

      if (decodedToken?.exp) {
        state.tokenExpirationDate = new Date(decodedToken?.exp * 1000).toISOString();
      }

      state.token = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserLogin.fulfilled, (state, action) => {
      const activeOrg = action.payload.availableOrganisations?.find((org) => org?.active);
      state.activeOrg = activeOrg;
      state.loginProfile = action.payload;
    });

    builder.addCase(getUserLogin.rejected, (state, action) => {
      state.loginProfile = null;
    });
  },
});

export const { setAccessToken } = userLoginSlice.actions;

export default userLoginSlice.reducer;
