import { Controller, useFormContext } from 'react-hook-form';

import { Heading } from '@/components/1-atoms';
import { FormFieldCta, FormFieldInput, FormFieldRadio } from '@/components/2-molecules';

import styles from './CreateOrganisationStep.module.scss';

export const CreateOrganisationStep = () => {
  const radioOptions = [
    { value: 'Buyer', label: 'Buyer' },
    { value: 'Supplier', label: 'Supplier' },
  ];

  const { register, control } = useFormContext();
  return (
    <>
      <Heading className={styles.CreateOrganisationStep__heading} headingLevel={'h3'}>
        We need to ask you just a few questions before you <br /> start using the system so we can
        set up your account
      </Heading>

      <Controller
        control={control}
        name={'organisationType'}
        render={({ field: { onChange, value } }) => (
          <FormFieldRadio
            title='How do you want to use the system?'
            name={'organisationType'}
            options={radioOptions}
            optionsGap='20rem'
            defaultSelectedValue={value}
            onChange={(value) => onChange(value)}
            state={{ required: true }}
          />
        )}
      />

      <FormFieldInput
        id={'organisationNo'}
        name={'organisationNo'}
        label={'Please enter your organisation number:'}
        type={'text'}
        register={register}
        state={{ required: true }}
      />

      <FormFieldCta
        className={styles.CreateOrganisationStep__submitBtn}
        type='submit'
        id={'save'}
        label={'Submit'}
      />
    </>
  );
};
