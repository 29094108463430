import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { GetSupplierProfile } from '@/services/ProfileServices';
import { RootState } from '../store';

const initialState: SupplierProfileOverview = {
  userId: undefined,
  contact: undefined,
  availableOrganisations: [],
  dashboardOverview: undefined,
  leadOverview: undefined,
  tenderOverview: undefined,
  contractOverview: {
    contracts: [],
  },
  settingsOverview: {
    contact: undefined,
    organisations: [],
  },
};

export const getProfile = createAsyncThunk('profile/getProfile', async () => {
  return await GetSupplierProfile();
});

export const profileSlice = createSlice({
  name: 'supplierProfile',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getProfile.fulfilled, (state, action) => {
      return { ...action.payload };
    });
  },
});

export const selectLeadsFromSupplierProfile = (state: RootState) => ({
  accessibleLeads: state.supplierProfile.leadOverview?.accessibleLeads || [],
  purchasableLeads: state.supplierProfile.leadOverview?.purchasableLeads || [],
  leads: state.supplierProfile.leadOverview?.leads || [],
});

export default profileSlice.reducer;
