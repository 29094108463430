// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.u8iCQiptMqkFqONsvvK0 {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  margin-top: 4rem;
}

.nQEK5ptxdglzr3HBKEvz {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.croHefTyf8_42eAYBPE_ {
  min-width: 25rem;
}

.QN6LGAGRzxiPupxEF9nG {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.S83Hc2ZKSfTHDjXMWmo5 {
  margin-top: auto;
  display: flex;
  justify-content: center;
  gap: 4rem;
  margin-bottom: 2rem;
}`, "",{"version":3,"sources":["webpack://./src/components/3-organisms/CreateTender/CreateTenderReview/CreateTenderReview.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;EACA,gBAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AACA;EACE,WAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;AAEF;;AACA;EACE,gBAAA;EACA,aAAA;EACA,uBAAA;EACA,SAAA;EACA,mBAAA;AAEF","sourcesContent":[".CreateTenderReview {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  gap: 4rem;\n  margin-top: 4rem;\n}\n\n.CreateTenderReview__dataRow {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n}\n\n.CreateTenderReview__rowHeading {\n  min-width: 25rem;\n}\n.CreateTenderReview__phasesList {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  gap: 2rem;\n}\n\n.CreateTenderReview__ctaWrapper {\n  margin-top: auto;\n  display: flex;\n  justify-content: center;\n  gap: 4rem;\n  margin-bottom: 2rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CreateTenderReview": `u8iCQiptMqkFqONsvvK0`,
	"CreateTenderReview__dataRow": `nQEK5ptxdglzr3HBKEvz`,
	"CreateTenderReview__rowHeading": `croHefTyf8_42eAYBPE_`,
	"CreateTenderReview__phasesList": `QN6LGAGRzxiPupxEF9nG`,
	"CreateTenderReview__ctaWrapper": `S83Hc2ZKSfTHDjXMWmo5`
};
export default ___CSS_LOADER_EXPORT___;
