// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rdT1qV5ioaCUuIbU_C8A {
  display: flex;
  flex-direction: column;
  gap: 5rem;
  align-items: center;
  justify-content: center;
  margin: auto 0;
}`, "",{"version":3,"sources":["webpack://./src/components/2-molecules/Cards/LoadingCard/LoadingCard.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,mBAAA;EACA,uBAAA;EACA,cAAA;AACF","sourcesContent":[".LoadingCard {\n  display: flex;\n  flex-direction: column;\n  gap: 5rem;\n  align-items: center;\n  justify-content: center;\n  margin: auto 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"LoadingCard": `rdT1qV5ioaCUuIbU_C8A`
};
export default ___CSS_LOADER_EXPORT___;
