// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.owaeHlDwbt8HRd7Xqkq_ {
  text-align: center;
}

.lo9Z7LxJB_e2fuH1zHBt {
  text-align: left;
}

.AxijYDrw0Fcey0plmRIV {
  text-align: right;
}

.ov2nJIe9WmpbnlJtwTWa {
  white-space: pre;
}`, "",{"version":3,"sources":["webpack://./src/components/1-atoms/TextBox/TextBox.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE,iBAAA;AACF;;AAEA;EACE,gBAAA;AACF","sourcesContent":[".TextBox__centre {\n  text-align: center;\n}\n\n.TextBox__left {\n  text-align: left;\n}\n\n.TextBox__right {\n  text-align: right;\n}\n\n.TextBox__description {\n  white-space: pre;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TextBox__centre": `owaeHlDwbt8HRd7Xqkq_`,
	"TextBox__left": `lo9Z7LxJB_e2fuH1zHBt`,
	"TextBox__right": `AxijYDrw0Fcey0plmRIV`,
	"TextBox__description": `ov2nJIe9WmpbnlJtwTWa`
};
export default ___CSS_LOADER_EXPORT___;
