import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '@/store';
import { setTitle } from '@/store/slices/headerSlice';

import { TendersOverview } from '@/components/3-organisms';
import { LeadsLayout } from '@/components/4-templates';

import styles from './TendersOverviewPage.module.scss';

export const TendersOverviewPage = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setTitle('tenders'));
  }, []);

  const tendersList = useAppSelector((state) => state.supplierProfile.tenderOverview?.tenders);
  return (
    <LeadsLayout>
      <section className={styles.TendersOverviewPage}>
        <TendersOverview tendersList={tendersList} />
      </section>
    </LeadsLayout>
  );
};
