import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface PageState {
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
}
const initialState: PageState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
};

export const pageStatusSlice = createSlice({
  name: 'pageStatus',
  initialState,
  reducers: {
    setLoading: (state: PageState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setError: (state: PageState, action: PayloadAction<boolean>) => {
      state.isError = action.payload;
    },

    setSuccess: (state: PageState, action: PayloadAction<boolean>) => {
      state.isSuccess = action.payload;
    },
  },
});

export const { setLoading, setError, setSuccess } = pageStatusSlice.actions;
export default pageStatusSlice.reducer;
