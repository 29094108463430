// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DwUl_WALtM1kgwwnr8h2 {
  display: flex;
  min-height: 12rem;
  align-items: center;
  background-color: var(--white);
  padding: 0 5rem;
  box-shadow: 0rem 0.2rem 3rem rgba(0, 0, 0, 0.1);
  z-index: 2;
  justify-content: space-between;
}

.y6zZ586N10lb7SbzTzhc {
  text-transform: uppercase;
}

.w7Bf9BvOICBjaEbcfgdN {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.uC4sTocreJTbuDIegpwY {
  display: flex;
  gap: 1rem;
  color: var(--accent-color);
}

.wutmwwlVrDeARMlRjs6y {
  color: var(--black);
}

.kek5pJUArejqdI_8t_dp {
  border: var(--secondary-border);
  height: 3rem;
}

.s9E9OUud2C_jp3MebgvZ {
  border: var(--secondary-border);
  border-radius: 4rem;
  padding: 1rem;
  cursor: pointer;
  background-color: var(--light-grey-100);
  display: flex;
  align-items: center;
  gap: 1rem;
  width: auto;
}

.S7o32_9O3Mo4o_pbimUS {
  width: auto;
  display: flex;
  align-items: center;
  gap: 2rem;
}`, "",{"version":3,"sources":["webpack://./src/components/3-organisms/Header/Header.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,iBAAA;EACA,mBAAA;EACA,8BAAA;EACA,eAAA;EACA,+CAAA;EACA,UAAA;EACA,8BAAA;AACF;;AAEA;EACE,yBAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,SAAA;AACF;;AAEA;EACE,aAAA;EACA,SAAA;EACA,0BAAA;AACF;;AAEA;EACE,mBAAA;AACF;;AAEA;EACE,+BAAA;EACA,YAAA;AACF;;AAEA;EACE,+BAAA;EACA,mBAAA;EACA,aAAA;EACA,eAAA;EACA,uCAAA;EACA,aAAA;EACA,mBAAA;EACA,SAAA;EACA,WAAA;AACF;;AAEA;EACE,WAAA;EACA,aAAA;EACA,mBAAA;EACA,SAAA;AACF","sourcesContent":[".Header {\n  display: flex;\n  min-height: 12rem;\n  align-items: center;\n  background-color: var(--white);\n  padding: 0 5rem;\n  box-shadow: 0rem 0.2rem 3rem rgba(0, 0, 0, 0.1);\n  z-index: 2;\n  justify-content: space-between;\n}\n\n.Header__title {\n  text-transform: uppercase;\n}\n\n.Header__details {\n  display: flex;\n  align-items: center;\n  gap: 2rem;\n}\n\n.Header__activeCompany {\n  display: flex;\n  gap: 1rem;\n  color: var(--accent-color);\n}\n\n.Header__activeCompany__black {\n  color: var(--black);\n}\n\n.Header__details__divider {\n  border: var(--secondary-border);\n  height: 3rem;\n}\n\n.Header__details__profileBtn {\n  border: var(--secondary-border);\n  border-radius: 4rem;\n  padding: 1rem;\n  cursor: pointer;\n  background-color: var(--light-grey-100);\n  display: flex;\n  align-items: center;\n  gap: 1rem;\n  width: auto;\n}\n\n.Header__organisationDropdown {\n  width: auto;\n  display: flex;\n  align-items: center;\n  gap: 2rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Header": `DwUl_WALtM1kgwwnr8h2`,
	"Header__title": `y6zZ586N10lb7SbzTzhc`,
	"Header__details": `w7Bf9BvOICBjaEbcfgdN`,
	"Header__activeCompany": `uC4sTocreJTbuDIegpwY`,
	"Header__activeCompany__black": `wutmwwlVrDeARMlRjs6y`,
	"Header__details__divider": `kek5pJUArejqdI_8t_dp`,
	"Header__details__profileBtn": `s9E9OUud2C_jp3MebgvZ`,
	"Header__organisationDropdown": `S7o32_9O3Mo4o_pbimUS`
};
export default ___CSS_LOADER_EXPORT___;
