import classNames from 'classnames';
import styles from './TextInfo.module.scss';

import { ReactComponent as InfoIcon } from '@/assets/Icons/icon-info.svg';
import { Heading, TextBox } from '@/components/1-atoms';

export type HeadingLevel = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

export interface TextInfoProps {
  className?: string;
  description: string;
  headingLevel: HeadingLevel;
  title: string;
}

export const TextInfo = ({ className, description, headingLevel, title }: TextInfoProps) => {
  return (
    <div className={classNames(styles.TextInfo, className)}>
      <Heading headingLevel={headingLevel}>{title}</Heading>

      <div className={styles.TextInfo__descriptionIcon}>
        <InfoIcon aria-label='Info Icon' />

        <div className={styles.TextInfo__descriptionText}>
          <TextBox
            className={styles.TextInfo__descriptionBox}
            description={description}
            alignment={'left'}
            aria-label='Info Text'
          />
        </div>
      </div>
    </div>
  );
};
