// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.p7ZJirHJfOn3V6v6a_rC {
  width: 100%;
  min-height: 12rem;
  display: flex;
  flex-direction: column;
  border: var(--secondary-border);
  background: var(--white);
  box-shadow: var(--primary-shadow);
  padding: 2rem;
  gap: 2rem;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}

.baK__s3JqDyuC7VvRjIA {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.IeItGYPluygdd60qAYWh {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.utqrgVORC9PCDWifwv0D {
  color: var(--dark-grey-700);
  font-size: var(--font-size--xs);
}`, "",{"version":3,"sources":["webpack://./src/components/3-organisms/TenderDetails/NoticeBoard/NoticeItem/NoticeItem.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,iBAAA;EACA,aAAA;EACA,sBAAA;EACA,+BAAA;EACA,wBAAA;EACA,iCAAA;EACA,aAAA;EACA,SAAA;EAEA,8BAAA,EAAA,gCAAA;EACA,2BAAA,EAAA,yBAAA;EACA,sBAAA,EAAA,gBAAA;AAAF;;AAGA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;AAAF;;AAGA;EACE,aAAA;EACA,mBAAA;EACA,WAAA;AAAF;;AAGA;EACE,2BAAA;EACA,+BAAA;AAAF","sourcesContent":[".NoticeItem {\n  width: 100%;\n  min-height: 12rem;\n  display: flex;\n  flex-direction: column;\n  border: var(--secondary-border);\n  background: var(--white);\n  box-shadow: var(--primary-shadow);\n  padding: 2rem;\n  gap: 2rem;\n\n  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */\n  -moz-box-sizing: border-box; /* Firefox, other Gecko */\n  box-sizing: border-box; /* Opera/IE 8+ */\n}\n\n.NoticeItem__heading {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.NoticeItem__dateWrapper {\n  display: flex;\n  align-items: center;\n  gap: 0.5rem;\n}\n\n.NoticeItem__date {\n  color: var(--dark-grey-700);\n  font-size: var(--font-size--xs);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"NoticeItem": `p7ZJirHJfOn3V6v6a_rC`,
	"NoticeItem__heading": `baK__s3JqDyuC7VvRjIA`,
	"NoticeItem__dateWrapper": `IeItGYPluygdd60qAYWh`,
	"NoticeItem__date": `utqrgVORC9PCDWifwv0D`
};
export default ___CSS_LOADER_EXPORT___;
