import classNames from 'classnames';
import styles from './AnimatedButton.module.scss';

import { ReactComponent as ArrowIcon } from '@/assets/Icons/icon-arrow.svg';
import { ReactComponent as TrashIcon } from '@/assets/Icons/icon-trash-small.svg';
import { ReactComponent as PenIcon } from '@/assets/Icons/icon-pen-purple.svg';

export interface AnimatedButtonProps {
  title: string;
  className?: string;
  onClick?: () => void;
  type: 'activate' | 'delete' | 'edit';
}

export const AnimatedButton = ({ title, className, onClick, type }: AnimatedButtonProps) => {
  const getButtonIcon = (): JSX.Element => {
    switch (type) {
      case 'activate':
        return <ArrowIcon />;

      case 'delete':
        return <TrashIcon />;

      default:
        return <PenIcon />;
    }
  };

  return (
    <button
      className={classNames(styles.AnimatedButton, styles[`AnimatedButton__${type}`], className)}
      onClick={onClick}
    >
      <span>{getButtonIcon()}</span>

      <span className={styles.AnimatedButton__title}>{title}</span>
    </button>
  );
};
