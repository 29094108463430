import classNames from 'classnames';

import { Heading, Paragraph } from '@/components/1-atoms';
import styles from './ChatRoomItem.module.scss';

import { ReactComponent as MessageIcon } from '@/assets/Icons/icon-message.svg';
import { ReactComponent as MessagesIcon } from '@/assets/Icons/icon-messages.svg';
import { ReactComponent as AttachIcon } from '@/assets/Icons/icon-attach.svg';

export interface ChatRoomItemProps {
  className?: string;
  chatRoom: ChatRoomType;
  onItemClick?: () => void;
}

export const ChatRoomItem = ({ className, chatRoom, onItemClick }: ChatRoomItemProps) => {
  const { title, latestMessage } = chatRoom;

  return (
    <div
      className={classNames(styles.ChatRoomItem, className)}
      onClick={() => onItemClick && onItemClick()}
    >
      <div className={styles.ChatRoomItem__header}>
        <div className={styles.ChatRoomItem__title}>
          <MessageIcon />
          <Heading headingLevel={'h3'} className={styles.ChatRoomItem__heading}>
            {title}
          </Heading>
        </div>
        {latestMessage?.sentAt}
      </div>

      <Paragraph>{latestMessage?.content}</Paragraph>

      {/* <div className={styles.ChatRoomItem__footer}>
        <span className={styles.ChatRoomItem__footerItem}>
          <AttachIcon />
          {numberOfAttachments}
        </span>

        <span className={styles.ChatRoomItem__footerItem}>
          <MessagesIcon />
          {numberOfMessages}
        </span>
      </div> */}
    </div>
  );
};
