import classNames from 'classnames';
import styles from './LoadingCard.module.scss';
import { PopupCard } from '../PopupCard';
import { LoadingSpinner } from '@/components/1-atoms';

export interface LoadingCardProps {
  className?: string;
  children?: React.ReactNode;
}

export const LoadingCard = ({ className, children }: LoadingCardProps) => {
  return (
    <PopupCard smallSize className={classNames(className, styles.LoadingCard)}>
      <LoadingSpinner />
      {children}
    </PopupCard>
  );
};
