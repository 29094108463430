import { useRef } from 'react';

import { Heading } from '@/components/1-atoms';
import { FormFieldInput, Button, TextInfo } from '@/components/2-molecules';

import styles from './IntegrationsOverview.module.scss';

import { ReactComponent as KeyIcon } from '@/assets/Icons/icon-key.svg';
import { ReactComponent as BjornLundenLogo } from '@/assets/Logo/logo-BjornLunden.svg';

export const IntegrationsOverview = () => {
  const inputRef = useRef<HTMLInputElement>(null);

  const onSubmit = (key: string | undefined) => {
    key ? console.log(key) : console.log('key is undefined');
  };

  const description = `Lorem ipsum dolor sit amet,
consectetur adipiscing elit, sed do eiusmod tempor.
Ut enim ad minim veniam, quis nostrud exercitation`;

  return (
    <section className={styles.IntegrationsOverview}>
      <Heading headingLevel='h2' className={styles.IntegrationsOverview__pageTitle}>
        Integrations
      </Heading>

      <div className={styles.IntegrationsOverview_company}>
        <BjornLundenLogo />
        <TextInfo headingLevel={'h3'} title={'Björn lundén'} description={description} />
      </div>

      <FormFieldInput
        id={'apiKey'}
        label={'Enter your API key below:'}
        type={'text'}
        name={'apiKey'}
        defaultValue=''
        icon={<KeyIcon />}
        externalRef={inputRef}

        // TODO: Add Disabled State and Error State
      />

      <Button
        className={styles.IntegrationsOverview__cta}
        type='primary'
        onClick={() => onSubmit(inputRef.current?.value)}
      >
        Save
      </Button>
    </section>
  );
};
