import { useRef, useState } from 'react';
import classNames from 'classnames';

import { Heading, Paragraph, TextArea } from '@/components/1-atoms';
import {
  ActionCard,
  Button,
  FormFieldSelect,
  IconButton,
  PopupCard,
} from '@/components/2-molecules';

import styles from './ChatList.module.scss';

import { ReactComponent as AddMessageIcon } from '@/assets/Icons/icon-add-message.svg';

import { ReactComponent as UploadIcon } from '@/assets/Icons/icon-upload.svg';

import { ChatRoomItem } from './ChatRoomItem';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { createChatRoom as createChatRoomAPI } from '@/services/ChatServices';
import { useAppSelector } from '@/store';

export interface ChatListProps {
  className?: string;
  chatRooms: ChatRoomType[];
  userRole: 'buyer' | 'supplier';
  suppliersList?: TenderParticipants[];
  contactId?: number;
}

export const ChatList = ({
  className,
  chatRooms,
  userRole,
  suppliersList,
  contactId,
}: ChatListProps) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [openPopup, setOpenPopup] = useState(false);
  const [selectedSupplierId, setSelectedSupplierId] = useState<number>();

  const { tenderId } = useParams();
  const supplierOrgId = useAppSelector((state) => state.user.activeOrg?.id);

  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const options = suppliersList?.map((supplier) => ({
    value: supplier.orgId,
    label: supplier.orgName,
  }));

  const createChatRoom = async () => {
    if (!tenderId) return; // TODO: add error state for the select

    let orgID = supplierOrgId;

    if (userRole === 'buyer') {
      if (!selectedSupplierId) return; // TODO: add error state for the select
      orgID = selectedSupplierId;
    }

    const id = await createChatRoomAPI({
      supplierOrgId: orgID ?? 0,
      tenderId: Number(tenderId),
      ...getFirstMessage(),
    });

    navigateToChat(id);
    setOpenPopup(false);
  };

  const getFirstMessage = () => {
    if (!textAreaRef.current || !textAreaRef.current.value) return;

    return {
      contactId: contactId,
      chatMessage: textAreaRef.current.value,
    };
  };

  const navigateToChat = (roomId: number) => {
    navigate(location.pathname + '/' + roomId);
  };

  return (
    <>
      {chatRooms && chatRooms?.length > 0 ? (
        <div className={classNames(styles.ChatList, className)}>
          {userRole === 'buyer' && (
            <IconButton
              className={styles.ChatList__addMessageButton}
              icon={<AddMessageIcon />}
              onClick={() => setOpenPopup(true)}
            >
              <Heading headingLevel={'h3'}>New Chat</Heading>
            </IconButton>
          )}

          {chatRooms.map((room) => (
            <ChatRoomItem
              onItemClick={() => navigateToChat(room.id)}
              chatRoom={room}
              key={room.id}
            />
          ))}
        </div>
      ) : (
        <div className={classNames(styles.ChatList__emptyList, className)}>
          <ActionCard
            onClick={() => setOpenPopup(true)}
            className={styles.ChatList__addMessageCard}
          >
            <AddMessageIcon />
            <Heading headingLevel={'h3'} className={styles.ChatList__addMessageHeading}>
              {userRole === 'supplier'
                ? 'Start new chat with this tender'
                : 'Start new chat with one of your suppliers'}
            </Heading>
          </ActionCard>
        </div>
      )}

      {openPopup && (
        <PopupCard onCloseClick={() => setOpenPopup(false)} className={styles.ChatList__popup}>
          {userRole === 'buyer' && (
            <FormFieldSelect
              id='selectSupplier'
              label={'Starting chat with the supplier'}
              className={styles.ChatList__selectSupplier}
              onChange={(value) => setSelectedSupplierId(Number(value))}
              placeholder='Select a supplier'
              options={options || []}
              labelBottomMargin='2rem'
            />
          )}

          <TextArea
            name='Message text area'
            placeholder='Enter your message here'
            cols={30}
            rows={10}
            reference={textAreaRef}
            required
          />
          <div className={styles.ChatList__popupFooter}>
            <IconButton icon={<UploadIcon />} IconClass={styles.ChatList__uploadIcon}>
              <Paragraph>Upload files</Paragraph>{' '}
            </IconButton>
            <div className={styles.ChatList__actionButtonsWrapper}>
              <Button type={'borderlessPrimary'} onClick={() => createChatRoom()}>
                Send
              </Button>
              <Button
                type={'borderlessSecondary'}
                onClick={() => {
                  setOpenPopup(false);
                }}
              >
                Close
              </Button>
            </div>
          </div>
        </PopupCard>
      )}
    </>
  );
};
