// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.zpfQRj4fUY6E0H2ZJT1l {
  animation: NhAqqYEYf4_T0MPe09AQ 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}

@keyframes NhAqqYEYf4_T0MPe09AQ {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/1-atoms/LoadingSpinner/LoadingSpinner.module.scss"],"names":[],"mappings":"AAAA;EACE,0EAAA;AACF;;AAEA;EACE;IACE,uBAAA;EACF;EACA;IACE,yBAAA;EACF;AACF","sourcesContent":[".LoadingSpinner {\n  animation: loading-animation 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;\n}\n\n@keyframes loading-animation {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"LoadingSpinner": `zpfQRj4fUY6E0H2ZJT1l`,
	"loading-animation": `NhAqqYEYf4_T0MPe09AQ`
};
export default ___CSS_LOADER_EXPORT___;
