import classNames from 'classnames';
import styles from './TextArea.module.scss';

export interface TextAreaProps {
  className?: string;
  required?: boolean;
  hasError?: boolean;
  reference?: React.RefObject<HTMLTextAreaElement>;
  placeholder?: string;
  id?: string;
  name?: string;
  cols?: number;
  rows?: number;
  defaultValue?: string;
  onChange?: () => void;
}

export const TextArea = ({
  className,
  required,
  hasError,
  reference,
  placeholder,
  id,
  name,
  cols,
  rows,
  onChange,
  defaultValue,
}: TextAreaProps) => {
  return (
    <textarea
      name={name}
      id={id}
      placeholder={placeholder}
      cols={cols}
      rows={rows}
      ref={reference && reference}
      required={required}
      onChange={onChange && onChange}
      className={classNames(styles.TextArea, className, {
        [styles.TextArea__hasError]: required && hasError,
      })}
      defaultValue={defaultValue}
    />
  );
};
