import classNames from 'classnames';
import { useMemo } from 'react';
import styles from './PhaseAccordion.module.scss';
import { Heading } from '@/components/1-atoms';
import { Accordion, DataTable } from '@/components/2-molecules';
import { formatDateAsDottedString } from '@/utilities/dateHandler';
import { ColumnDef } from '@tanstack/react-table';

export interface PhaseAccordionProps {
  className?: string;
  phase: PhaseType; // TODO: Add Content
}

export const PhaseAccordion = ({ className, phase }: PhaseAccordionProps) => {
  const documentsColumns = useMemo<ColumnDef<PhaseType>[]>(
    () => [
      {
        header: 'Name',
        accessorKey: 'name',
      },
      {
        header: 'CATEGORY',
        accessorKey: 'category',
      },
      {
        header: 'TYPE',
        accessorKey: 'type',
      },
    ],
    [],
  );

  const questionsColumns = useMemo<ColumnDef<PhaseQuestion>[]>(
    () => [
      {
        header: 'Name',
        accessorKey: 'headline',
      },
      {
        header: 'CATEGORY',
        accessorKey: 'tenderPhaseQuestionCategoryId',
      },
    ],
    [],
  );

  return (
    <div className={classNames(styles.PhaseAccordion, className)}>
      <Accordion
        heading={
          <div className={styles.PhaseAccordion__headingWrapper}>
            <Heading className={styles.PhaseAccordion__phaseName} headingLevel={'h3'}>
              {phase.phaseName}
            </Heading>
            <Heading className={styles.PhaseAccordion__heading} headingLevel={'h3'}>
              Start date: {formatDateAsDottedString(new Date(phase.startDate))}.
            </Heading>
            <Heading className={styles.PhaseAccordion__heading} headingLevel={'h3'}>
              End date: {formatDateAsDottedString(new Date(phase.endDate))}.
            </Heading>
            <Heading className={styles.PhaseAccordion__heading} headingLevel={'h3'}>
              Mandatory: {phase.isMandatory ? 'Yes' : 'No'}
            </Heading>
            <Heading className={styles.PhaseAccordion__heading} headingLevel={'h3'}>
              Visibility: {phase.visability ? 'Visible to all' : 'Hidden'}
            </Heading>
          </div>
        }
      >
        <div className={styles.PhaseAccordion__contentWrapper}>
          <div>
            <Heading headingLevel={'h3'}>Documents</Heading>
            {phase?.documents && (
              <DataTable
                className={styles.PhaseAccordion__dataTable}
                columns={documentsColumns}
                data={phase?.documents}
              />
            )}
          </div>

          <div>
            <Heading headingLevel={'h3'}>Questions</Heading>
            {phase?.phaseQuestions && (
              <DataTable
                className={classNames(
                  styles.PhaseAccordion__dataTable,
                  styles.PhaseAccordion__dataTable_questions,
                )}
                columns={questionsColumns}
                data={phase?.phaseQuestions}
              />
            )}
          </div>
        </div>
      </Accordion>
    </div>
  );
};
