// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OH8iQGwmnPhfAY12HR6Z {
  padding: 8rem 5rem 3rem 5rem;
}

.ZKlJ_sOmlkWWQHB6DAjA {
  margin: 4.5rem 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

.fgkK76uxkvHEYY_OVZyw {
  display: flex;
  gap: 5rem;
}

._WSo7LTOfwCRO4Mlk140 {
  width: 50%;
}`, "",{"version":3,"sources":["webpack://./src/components/5-pages/tenders/TendersOverviewPage/TendersOverviewPage.module.scss"],"names":[],"mappings":"AAAA;EACE,4BAAA;AACF;;AAEA;EACE,gBAAA;EACA,WAAA;EACA,aAAA;EACA,uBAAA;AACF;;AAEA;EACE,aAAA;EACA,SAAA;AACF;;AAEA;EACE,UAAA;AACF","sourcesContent":[".TendersOverviewPage {\n  padding: 8rem 5rem 3rem 5rem;\n}\n\n.TendersOverviewPage__createTenderCta {\n  margin: 4.5rem 0;\n  width: 100%;\n  display: flex;\n  justify-content: center;\n}\n\n.TendersOverviewPage__eventsSection {\n  display: flex;\n  gap: 5rem;\n}\n\n.TendersOverviewPage__calendar {\n  width: 50%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TendersOverviewPage": `OH8iQGwmnPhfAY12HR6Z`,
	"TendersOverviewPage__createTenderCta": `ZKlJ_sOmlkWWQHB6DAjA`,
	"TendersOverviewPage__eventsSection": `fgkK76uxkvHEYY_OVZyw`,
	"TendersOverviewPage__calendar": `_WSo7LTOfwCRO4Mlk140`
};
export default ___CSS_LOADER_EXPORT___;
