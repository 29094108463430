import classNames from 'classnames';
import styles from './TextBox.module.scss';
import { Paragraph } from '../Paragraph';

export interface TextBoxProps {
  className?: string;
  description: string | JSX.Element;
  title?: JSX.Element;
  alignment: 'centre' | 'left' | 'right';
}

export const TextBox = ({ alignment, className, description, title }: TextBoxProps) => {
  return (
    <div className={classNames(styles.TextBox, styles[`TextBox__${alignment}`], className)}>
      {title && title}
      <Paragraph className={styles.TextBox__description}>{description}</Paragraph>
    </div>
  );
};
