// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Lr3ArC8ghT3iN3JV6mHM {
  padding: 2rem;
}

.qnwiFarwC7nCw805EtHl {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/components/3-organisms/CreateTender/PhaseForm/PhaseContent/PhaseContent.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;;AAEA;EACE,aAAA;AACF","sourcesContent":[".PhaseContent {\n  padding: 2rem;\n}\n\n.PhaseContent__Tab_hide {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"PhaseContent": `Lr3ArC8ghT3iN3JV6mHM`,
	"PhaseContent__Tab_hide": `qnwiFarwC7nCw805EtHl`
};
export default ___CSS_LOADER_EXPORT___;
