// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OJqlLtloYs5hpU0r0ttL {
  margin-bottom: 1rem;
}

.Gy8aEI6gGYSUDksCSM50 {
  display: flex;
  align-items: center;
  border: 0;
  height: 6rem;
}

.LynaVwC6aZJAjayWjOV4 {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.N53hIS5VcHRZQEhDbcnw {
  -webkit-appearance: none;
  appearance: none;
  background-color: var(--white);
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.6rem;
  height: 1.6rem;
  border: 1px solid var(--dark-grey-900);
  border-radius: 50%;
}
.N53hIS5VcHRZQEhDbcnw::before {
  content: "";
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  transform: scale(0);
  box-shadow: inset 1em 1em var(--accent-color);
}
.N53hIS5VcHRZQEhDbcnw:checked {
  border: 1px solid var(--accent-color);
}
.N53hIS5VcHRZQEhDbcnw:checked::before {
  transform: scale(1);
}
.N53hIS5VcHRZQEhDbcnw:hover {
  cursor: pointer;
}

.jrTKCcUKNdyMu8gC2zkZ {
  color: var(--dark-grey-900);
  font-size: var(--font-size--md);
  font-weight: var(--font-weight--normal);
  font-family: var(--font-secondary);
}`, "",{"version":3,"sources":["webpack://./src/components/2-molecules/Form/FormFieldRadio/FormFieldRadio.module.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,SAAA;EACA,YAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,SAAA;AACF;;AAEA;EAEE,wBAAA;EACA,gBAAA;EACA,8BAAA;EACA,SAAA;EAGA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,aAAA;EACA,cAAA;EACA,sCAAA;EACA,kBAAA;AAFF;AAIE;EACE,WAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,mBAAA;EACA,6CAAA;AAFJ;AAKE;EACE,qCAAA;AAHJ;AAKI;EACE,mBAAA;AAHN;AAOE;EACE,eAAA;AALJ;;AASA;EACE,2BAAA;EACA,+BAAA;EACA,uCAAA;EACA,kCAAA;AANF","sourcesContent":[".FormFieldRadio__title {\n  margin-bottom: 1rem;\n}\n\n.FormFieldRadio__groupContainer {\n  display: flex;\n  align-items: center;\n  border: 0;\n  height: 6rem;\n}\n\n.FormFieldRadio__btnContainer {\n  display: flex;\n  align-items: center;\n  gap: 1rem;\n}\n\n.FormFieldRadio__button {\n  // Hide default browser button\n  -webkit-appearance: none;\n  appearance: none;\n  background-color: var(--white);\n  margin: 0;\n\n  // Style for custom radio\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 1.6rem;\n  height: 1.6rem;\n  border: 1px solid var(--dark-grey-900);\n  border-radius: 50%;\n\n  &::before {\n    content: '';\n    width: 1rem;\n    height: 1rem;\n    border-radius: 50%;\n    transform: scale(0);\n    box-shadow: inset 1em 1em var(--accent-color);\n  }\n\n  &:checked {\n    border: 1px solid var(--accent-color);\n\n    &::before {\n      transform: scale(1);\n    }\n  }\n\n  &:hover {\n    cursor: pointer;\n  }\n}\n\n.FormFieldRadio__label {\n  color: var(--dark-grey-900);\n  font-size: var(--font-size--md);\n  font-weight: var(--font-weight--normal);\n  font-family: var(--font-secondary);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"FormFieldRadio__title": `OJqlLtloYs5hpU0r0ttL`,
	"FormFieldRadio__groupContainer": `Gy8aEI6gGYSUDksCSM50`,
	"FormFieldRadio__btnContainer": `LynaVwC6aZJAjayWjOV4`,
	"FormFieldRadio__button": `N53hIS5VcHRZQEhDbcnw`,
	"FormFieldRadio__label": `jrTKCcUKNdyMu8gC2zkZ`
};
export default ___CSS_LOADER_EXPORT___;
