import { Header, SideNavigation } from '@/components/3-organisms';
import styles from './LeadsLayout.module.scss';

import { ReactComponent as DashboardIcon } from '@/assets/Icons/icon-dashboard.svg';
import { ReactComponent as TendersIcon } from '@/assets/Icons/icon-tenders.svg';
import { ReactComponent as ContractsIcon } from '@/assets/Icons/icon-contracts.svg';
import { ReactComponent as LeadsIcon } from '@/assets/Icons/icon-leads.svg';
import { ReactComponent as SettingsIcon } from '@/assets/Icons/icon-settings.svg';

interface LeadsLayoutProps {
  children: React.ReactNode;
}

export const LeadsLayout = ({ children }: LeadsLayoutProps) => {
  return (
    <div className={styles.Layout}>
      <SideNavigation
        navigationLinks={[
          { text: 'Dashboard', href: '/leads/dashboard', Icon: <DashboardIcon /> },
          {
            text: 'Leads',
            href: '/leads/leadsOverview',
            Icon: <LeadsIcon />,
          },
          {
            text: 'Tenders',
            href: '/leads/tendersOverview',
            Icon: <TendersIcon />,
          },
          {
            text: 'Contracts',
            href: '/leads/contracts',
            Icon: <ContractsIcon />,
          },
          { text: 'Settings', href: '/leads/settings', Icon: <SettingsIcon /> },
        ]}
      />
      <main className={styles.Layout__contentWrapper}>
        <Header />
        {children}
      </main>
    </div>
  );
};
