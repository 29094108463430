import { Heading, Paragraph } from '@/components/1-atoms';
import { NavLink } from 'react-router-dom';
import styles from './SettingsItem.module.scss';

export interface SettingPage {
  title: string;
  subTitle: string;
  href: string;
}

export const SettingsItem = ({ href, title, subTitle }: SettingPage) => {
  return (
    <li className={styles.SettingsItem}>
      <NavLink className={styles.SettingsItem__link} to={href}>
        <Heading className={styles.SettingsItem__title} headingLevel={'h2'}>
          {title}
        </Heading>
        <Paragraph className={styles.SettingsItem__subTitle}>{subTitle}</Paragraph>
      </NavLink>
    </li>
  );
};
