import {
  CreateTender,
  CreateTenderPhase,
  CreateTenderQuestions,
  DeleteTenderPhase,
  UpdateTender,
  UpdateTenderPhase,
  UploadPhaseDocument,
} from '@/services/TenderServices';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

const initialState: TenderForm = {
  tenderPhases: [],
};

export const createTender = createAsyncThunk(
  'createTender',
  async (tender: TenderForm, { getState }) => {
    const state = getState() as RootState;
    const contactId = state.buyerProfile?.contact?.id;
    const orgId = state.user?.activeOrg?.id;

    if (!contactId) return Promise.reject(new Error('contactId not found'));
    if (!orgId) return Promise.reject(new Error('orgId not found'));

    try {
      const response = await CreateTender(tender, contactId, orgId);
      console.log('response', response);
      return { ...tender, ...response };
    } catch (error) {
      return Promise.reject(error);
    }
  },
);

export const updateTender = createAsyncThunk(
  'updateTender',
  async (tender: TenderForm, { getState }) => {
    const state = getState() as RootState;
    const contactId = state.buyerProfile?.contact?.id;
    const orgId = state.user?.activeOrg?.id;
    const tenderId = state.createTender?.tenderId;

    if (!contactId) return Promise.reject(new Error('contactId not found'));
    if (!orgId) return Promise.reject(new Error('orgId not found'));

    try {
      if (tenderId) {
        const response = await UpdateTender(tender, contactId, orgId);

        if (!response?.successfulOperation)
          return Promise.reject(new Error('Update Tender Failed'));

        return { ...tender };
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
);

export const createPhase = createAsyncThunk(
  'createPhase',
  async (phase: TenderFormPhase, { getState }) => {
    const state = getState() as RootState;
    const tenderId = state.createTender?.tenderId;

    if (!tenderId) return Promise.reject(new Error('tenderId not found'));

    try {
      const response = await CreateTenderPhase(phase, tenderId);
      return { ...phase, ...response };
    } catch (error) {
      return Promise.reject(error);
    }
  },
);

export const updatePhase = createAsyncThunk(
  'updatePhase',
  async (phase: TenderFormPhase, { getState }) => {
    const state = getState() as RootState;
    const tenderId = state.createTender?.tenderId;
    const phaseId = phase?.phaseId;

    if (!tenderId) return Promise.reject(new Error('tenderId not found'));
    if (!phaseId) return Promise.reject(new Error('phaseId not found'));

    try {
      const response = await UpdateTenderPhase(phase, tenderId);
      return { ...phase, ...response };
    } catch (error) {
      return Promise.reject(error);
    }
  },
);

export const deletePhase = createAsyncThunk(
  'deletePhase',
  async (phaseId: number, { getState }) => {
    const state = getState() as RootState;
    const tenderId = state.createTender?.tenderId; // TODO: should the tender Id be passed as an argument?
    if (!tenderId) return Promise.reject(new Error('tenderId not found'));

    try {
      const response = await DeleteTenderPhase(phaseId);
      if (response.successfulOperation) return phaseId;
    } catch (error) {
      return Promise.reject(error);
    }
  },
);

export const submitTenderQuestions = createAsyncThunk(
  'submitTenderQuestions',
  async (_, { getState }) => {
    const questionsData: {
      phaseId: number | undefined;
      headline: string;
      content: string;
      selectedCategoryId: number;
      selectedAcceptanceOptionId: number;
    }[] = [];
    const state = getState() as RootState;
    const tender = state.createTender;

    tender.tenderPhases.forEach((phase) => {
      // Assuming phaseId starts from 1
      phase.tenderQuestions.forEach((question) => {
        const formattedQuestion = {
          phaseId: phase.phaseId,
          headline: question.headline,
          content: question.content,
          selectedCategoryId: question.selectedCategoryId,
          selectedAcceptanceOptionId: 0, // Replace with the actual value
        };
        questionsData.push(formattedQuestion);
      });
    });

    try {
      const response = await CreateTenderQuestions(questionsData);
      return response?.successfulOperation
        ? response
        : Promise.reject(new Error('Failed to submit questions'));
    } catch (error) {
      return Promise.reject(error);
    }
  },
);

export const createTenderSlice = createSlice({
  name: 'createTender',
  initialState,
  reducers: {
    clearTenderForm: () => {
      return initialState;
    },

    setTender: (state: TenderForm, action: PayloadAction<TenderForm>) => {
      return (state = action.payload);
    },

    setPhases: (state: TenderForm, action: PayloadAction<TenderFormPhase[]>) => {
      state.tenderPhases = [...action.payload];
    },

    addPhaseQuestion: (
      state: TenderForm,
      action: PayloadAction<{ question: TenderFormPhaseQuestion; phaseId: number }>,
    ) => {
      state.tenderPhases = state.tenderPhases.map((phase) => {
        if (phase.phaseId === action.payload.phaseId) {
          return {
            ...phase,
            tenderQuestions: [...phase.tenderQuestions, action.payload.question],
          };
        }
        return phase;
      });

      return state;
    },

    deletePhaseQuestion: (
      state: TenderForm,
      action: PayloadAction<{ questionId: number; phaseId: number }>,
    ) => {
      state.tenderPhases = state.tenderPhases.map((phase) => {
        if (phase.phaseId === action.payload.phaseId) {
          return {
            ...phase,
            tenderQuestions: phase.tenderQuestions.filter(
              (question) => question.id !== action.payload.questionId,
            ),
          };
        }
        return phase;
      });
    },
  },

  extraReducers: (builder) => {
    builder.addCase(createTender.fulfilled, (state, action) => {
      return (state = action.payload);
    });

    builder.addCase(createPhase.fulfilled, (state, action) => {
      state.tenderPhases = [...state.tenderPhases, action.payload];
      return state;
    });

    builder.addCase(deletePhase.fulfilled, (state, action) => {
      state.tenderPhases = state.tenderPhases.filter((phase) => phase.phaseId !== action.payload);
      return state;
    });

    builder.addCase(submitTenderQuestions.fulfilled, (state, action) => {
      //  state = { tenderPhases: [] };
      console.log('submitTenderQuestions', action);
    });

    builder.addCase(updateTender.fulfilled, (state, action) => {
      return action.payload;
    });
  },
});

export const { setTender, addPhaseQuestion, deletePhaseQuestion, setPhases, clearTenderForm } =
  createTenderSlice.actions;

export default createTenderSlice.reducer;
