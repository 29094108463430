// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mntg4zV59m9RAbIeFdhK {
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: flex-end;
}

.VLmLFPBQfqMCjnRHTuzW {
  margin: 4rem 0;
}

.JryENbMiCbbaaLNfaAUx {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 2rem;
}`, "",{"version":3,"sources":["webpack://./src/components/3-organisms/TenderDetails/TenderContract/TenderContract.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,iBAAA;EACA,qBAAA;AACF;;AAEA;EACE,cAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,WAAA;EACA,SAAA;AACF","sourcesContent":[".TenderContract {\n  width: 100%;\n  display: flex;\n  flex-flow: column;\n  align-items: flex-end;\n}\n\n.TenderContract__uploadButton {\n  margin: 4rem 0;\n}\n\n.TenderContract__list {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  gap: 2rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TenderContract": `mntg4zV59m9RAbIeFdhK`,
	"TenderContract__uploadButton": `VLmLFPBQfqMCjnRHTuzW`,
	"TenderContract__list": `JryENbMiCbbaaLNfaAUx`
};
export default ___CSS_LOADER_EXPORT___;
