import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction';

import styles from './TasksCalendar.module.scss';
import classNames from 'classnames';
import { EventContentArg } from '@fullcalendar/core';
import { Heading } from '@/components/1-atoms';
import { useRef, useState } from 'react';
import { Filters } from '@/components/2-molecules';
export interface TasksCalendarProps {
  className?: string;
  tasks?: TenderTasks[];
}

export const TasksCalendar = ({ className, tasks }: TasksCalendarProps) => {
  const calendarRef = useRef<FullCalendar>(null);

  const [calendarTasks, setCalendarTasks] = useState<TenderTasks[]>(tasks || []);

  const getCalendarAPI = () => {
    if (calendarRef.current) return calendarRef?.current.getApi();
  };

  const changeView = (dateStr: Date) => {
    if (getCalendarAPI()?.view.type == 'dayGridMonth')
      getCalendarAPI()?.changeView('timeGridDay', dateStr);
  };

  const [selectedFilter, setSelectedFilter] = useState<string>('all');

  const handleFilterOptionChange = (value: string) => {
    setSelectedFilter(value);
  };

  const filteredTasks = calendarTasks.filter((item) => {
    if (selectedFilter === 'all') return true;
    return item.eventType === selectedFilter;
  });

  const renderEventContent = (eventInfo: EventContentArg) => {
    let dayOfWeek;
    let dateStr: Date;
    if (eventInfo.event.start) {
      dayOfWeek = new Date(eventInfo.event.start).getDate();
      dateStr = eventInfo.event.start;
    }

    return getCalendarAPI()?.view.type === 'dayGridMonth' ? (
      !eventInfo.isToday && (
        <span
          onClick={() => changeView(dateStr)}
          className={classNames(
            styles.TasksCalendar__event,
            styles[`TasksCalendar__event_${eventInfo.event.extendedProps.eventType}`],
          )}
        >
          {dayOfWeek}
        </span>
      )
    ) : (
      <span>{eventInfo.event.title}</span>
    );
  };

  return (
    <div className={classNames(styles.TasksCalendar, className)}>
      <div className={styles.TasksCalendar__card}>
        <Heading headingLevel={'h3'} className={styles.TasksCalendar__title}>
          Calendar
        </Heading>

        <FullCalendar
          ref={calendarRef}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          initialView={'dayGridMonth'}
          headerToolbar={{
            start: 'timeGridDay,timeGridWeek,dayGridMonth',
            center: 'prev title next',
            end: '',
          }}
          events={filteredTasks}
          dateClick={(arg: DateClickArg) => changeView(arg.date)}
          eventContent={renderEventContent}
        />

        <div className={styles.TasksCalendar__filtersWrapper}>
          <Filters
            selectedValue={selectedFilter}
            onChange={handleFilterOptionChange}
            type={'grouped'}
            options={[
              {
                label: 'All',
                value: 'all',
                className: styles.TasksCalendar__allFilter,
                activeClassName: styles.TasksCalendar__allFilter_active,
              },
              {
                label: 'Tenders',
                value: 'tender',
                className: styles.TasksCalendar__tendersFilter,
                activeClassName: styles.TasksCalendar__tendersFilter_active,
              },
              {
                label: 'Contracts',
                value: 'contract',
                className: styles.TasksCalendar__contractsFilter,
                activeClassName: styles.TasksCalendar__contractsFilter_active,
              },
              {
                label: 'Suppliers',
                value: 'supplier',
                className: styles.TasksCalendar__suppliersFilter,
                activeClassName: styles.TasksCalendar__suppliersFilter_active,
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};
