// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.KgUVQw8n3u67e57_2EJB {
  width: 100%;
}

._HUwFoMEfMkawB1_fLmp {
  width: 40rem;
  display: flex;
  align-items: center;
}

.UWh3BixAClsxfDpeXxBy {
  border: unset;
  color: var(--black);
  padding: 0.7rem 1.7rem;
  background-color: unset;
  border: unset;
  font-family: var(--font-primary);
  font-weight: var(--font-weight--bold);
}
.iUVuXQQzLLJMn70Rbgm7 {
  color: var(--secondary-color);
}

.uHeqFI6VE3AiR0XdQTi2 {
  padding-left: 4rem;
}

.lTdSec1hbDphhtY1pOrw {
  min-height: 30rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
}

.Qv2MgmN9SlmRNkkkxMdu {
  margin-bottom: 2rem;
}`, "",{"version":3,"sources":["webpack://./src/components/3-organisms/TendersOverview/TendersOverview.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;;AAEA;EACE,YAAA;EACA,aAAA;EACA,mBAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,sBAAA;EACA,uBAAA;EACA,aAAA;EACA,gCAAA;EACA,qCAAA;AACF;AACE;EACE,6BAAA;AACJ;;AAGA;EACE,kBAAA;AAAF;;AAGA;EACE,iBAAA;EACA,aAAA;EACA,mBAAA;EACA,sBAAA;EACA,uBAAA;EACA,SAAA;AAAF;;AAGA;EACE,mBAAA;AAAF","sourcesContent":[".TendersOverview {\n  width: 100%;\n}\n\n.TendersOverview__filters {\n  width: 40rem;\n  display: flex;\n  align-items: center;\n}\n\n.TendersOverview__tendersFilter {\n  border: unset;\n  color: var(--black);\n  padding: 0.7rem 1.7rem;\n  background-color: unset;\n  border: unset;\n  font-family: var(--font-primary);\n  font-weight: var(--font-weight--bold);\n\n  &_active {\n    color: var(--secondary-color);\n  }\n}\n\n.TendersOverview__actionsButton {\n  padding-left: 4rem;\n}\n\n.TendersOverview__emptyList {\n  min-height: 30rem;\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n  justify-content: center;\n  gap: 2rem;\n}\n\n.TendersOverview__emptyListHeading {\n  margin-bottom: 2rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TendersOverview": `KgUVQw8n3u67e57_2EJB`,
	"TendersOverview__filters": `_HUwFoMEfMkawB1_fLmp`,
	"TendersOverview__tendersFilter": `UWh3BixAClsxfDpeXxBy`,
	"TendersOverview__tendersFilter_active": `iUVuXQQzLLJMn70Rbgm7`,
	"TendersOverview__actionsButton": `uHeqFI6VE3AiR0XdQTi2`,
	"TendersOverview__emptyList": `lTdSec1hbDphhtY1pOrw`,
	"TendersOverview__emptyListHeading": `Qv2MgmN9SlmRNkkkxMdu`
};
export default ___CSS_LOADER_EXPORT___;
