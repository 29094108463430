// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VYbWPv6UkidraYH8lODt {
  position: relative;
}

.YcSGvQV0mft32MOf2wKh {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.AgpaSbr62Sx3JEs5HUGx {
  display: flex;
  align-items: center;
  gap: 2rem;
  cursor: pointer;
  margin-right: 2rem;
  z-index: 2;
}`, "",{"version":3,"sources":["webpack://./src/components/3-organisms/CreateTender/PhaseForm/PhaseForm.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;;AAEA;EACE,aAAA;EACA,8BAAA;EACA,WAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,SAAA;EACA,eAAA;EACA,kBAAA;EACA,UAAA;AACF","sourcesContent":[".PhaseForm {\n  position: relative;\n}\n\n.PhaseForm__heading {\n  display: flex;\n  justify-content: space-between;\n  width: 100%;\n}\n\n.PhaseForm__icons {\n  display: flex;\n  align-items: center;\n  gap: 2rem;\n  cursor: pointer;\n  margin-right: 2rem;\n  z-index: 2;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"PhaseForm": `VYbWPv6UkidraYH8lODt`,
	"PhaseForm__heading": `YcSGvQV0mft32MOf2wKh`,
	"PhaseForm__icons": `AgpaSbr62Sx3JEs5HUGx`
};
export default ___CSS_LOADER_EXPORT___;
