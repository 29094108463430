// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.JCipKZaQm4SZeNdSf06p {
  padding: 5rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  overflow-y: auto;
}`, "",{"version":3,"sources":["webpack://./src/components/3-organisms/DashBoard/CreateOrganisationForm/CreateOrganisationForm.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;EACA,gBAAA;AACF","sourcesContent":[".CreateOrganisationForm__popup {\n  padding: 5rem;\n  display: flex;\n  flex-direction: column;\n  gap: 2rem;\n  overflow-y: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CreateOrganisationForm__popup": `JCipKZaQm4SZeNdSf06p`
};
export default ___CSS_LOADER_EXPORT___;
