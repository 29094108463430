import { Heading } from '@/components/1-atoms';
import classNames from 'classnames';
import { ReactElement, useRef } from 'react';
import { FormErrorMessage } from '../FormErrorMessage';
import styles from './FormFieldInput.module.scss';
import { UseFormRegister } from 'react-hook-form';

type State = {
  required?: boolean;
  disabled?: boolean;
  hasError?: boolean;
};

export interface FormFieldInputProps {
  className?: string;
  classNameWrapper?: string;
  defaultValue?: string | number;
  errorMessage?: string;
  icon?: ReactElement;
  id: string;
  label?: string;
  name: string;
  max?: number | string;
  maxLength?: number;
  min?: number | string;
  minLength?: number;
  externalRef?: React.RefObject<HTMLInputElement>;
  pattern?: string;
  placeholder?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register?: UseFormRegister<any>;
  state?: State;
  onBlur?: () => void;
  type: 'text' | 'email' | 'search' | 'number' | 'password' | 'tel' | 'url' | 'date';
}

export const FormFieldInput = ({
  className,
  classNameWrapper,
  defaultValue,
  errorMessage,
  icon,
  id,
  label,
  name,
  max,
  maxLength,
  min,
  minLength,
  externalRef,
  pattern,
  placeholder,
  register,
  state,
  type,
  onBlur,
}: FormFieldInputProps) => {
  const { required, disabled, hasError } = state ?? {};

  const inputRef = useRef<HTMLInputElement | null>(null);

  const registerPropsHandler = () => {
    if (register) {
      const { ref, ...rest } = register(name, { required });
      return {
        ref: (e: HTMLInputElement | null) => {
          ref(e);
          inputRef.current = e;
        },
        ...rest,
      };
    } else if (externalRef) {
      return {
        ref: externalRef,
      };
    }
  };

  const focusInput = () => {
    inputRef.current?.focus();
  };

  return (
    <div
      className={classNames(
        styles.FormFieldInput,
        className,
        { [styles.hasError]: hasError },
        { [styles.isDisabled]: disabled },
      )}
      onClick={focusInput}
    >
      {label && (
        <Heading headingLevel='h3' id={id} className={styles.FormFieldInput__heading}>
          {label}
        </Heading>
      )}

      <div
        className={classNames(styles.FormFieldInput__inputWrapper, classNameWrapper, {
          [styles.hasError]: hasError,
        })}
      >
        {icon && <span>{icon}</span>}

        <input
          type={type}
          className={styles.FormFieldInput__input}
          name={name}
          id={id}
          disabled={disabled}
          placeholder={placeholder}
          defaultValue={defaultValue}
          min={min}
          max={max}
          maxLength={maxLength}
          minLength={minLength}
          pattern={pattern}
          onBlur={onBlur}
          {...registerPropsHandler()}
        />
      </div>
      {hasError && <FormErrorMessage id={type + id}>{errorMessage}</FormErrorMessage>}
    </div>
  );
};
