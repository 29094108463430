// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PXJ4sW4Rz29mU3qzvoRK {
  height: 100%;
}

.EhtbCPsK93IWPp38wuAU {
  margin-bottom: 4rem;
}

.kZrf8YEkN4D8UZCpv3F4 {
  display: flex;
  flex-flow: column;
  gap: 4rem;
}

.RVWIjK5t4lgTclujlZbz {
  margin-bottom: 4rem;
}

.jkfdwBK46QEo5jfCCe7Q {
  display: flex;
  width: 100%;
  gap: 4rem;
}

.HrcxKLJh6sFnXx6O0tui {
  margin: 7rem 0 auto 0;
  align-self: center;
  display: flex;
  gap: 2rem;
}`, "",{"version":3,"sources":["webpack://./src/components/3-organisms/SettingsOverview/AccountOverview/AccountOverview.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;;AAEA;EACE,mBAAA;AACF;;AAEA;EACE,aAAA;EACA,iBAAA;EACA,SAAA;AACF;;AAEA;EACE,mBAAA;AACF;;AAEA;EACE,aAAA;EACA,WAAA;EACA,SAAA;AACF;;AAEA;EACE,qBAAA;EACA,kBAAA;EACA,aAAA;EACA,SAAA;AACF","sourcesContent":[".AccountOverview {\n  height: 100%;\n}\n\n.AccountOverview__pageTitle {\n  margin-bottom: 4rem;\n}\n\n.AccountOverview__form {\n  display: flex;\n  flex-flow: column;\n  gap: 4rem;\n}\n\n.AccountOverview__avatarInput {\n  margin-bottom: 4rem;\n}\n\n.AccountOverview__row {\n  display: flex;\n  width: 100%;\n  gap: 4rem;\n}\n\n.AccountOverview__cta {\n  margin: 7rem 0 auto 0; // TODO: align on this value\n  align-self: center;\n  display: flex;\n  gap: 2rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AccountOverview": `PXJ4sW4Rz29mU3qzvoRK`,
	"AccountOverview__pageTitle": `EhtbCPsK93IWPp38wuAU`,
	"AccountOverview__form": `kZrf8YEkN4D8UZCpv3F4`,
	"AccountOverview__avatarInput": `RVWIjK5t4lgTclujlZbz`,
	"AccountOverview__row": `jkfdwBK46QEo5jfCCe7Q`,
	"AccountOverview__cta": `HrcxKLJh6sFnXx6O0tui`
};
export default ___CSS_LOADER_EXPORT___;
