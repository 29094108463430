// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.eGbBdXWx4WHeFIq7spMR {
  background-color: var(--athens-grey-light);
  border-radius: 2rem;
  display: flex;
}

.XNPjwz03wlb6QQ0aTgxU {
  flex: 1;
  padding: 3rem 2rem 2rem 2rem;
  border: none;
  background: transparent;
  font-size: var(--font-size--sm);
}
.XNPjwz03wlb6QQ0aTgxU:focus {
  outline: none;
}

.vTp8a1As0vPxU__2HwGg {
  display: flex;
  flex-flow: column;
  justify-content: center;
  margin: 2rem 2rem 2rem 2rem;
  gap: 2rem;
}

.tTPkRcm6IL6NWpo06i8c {
  min-width: 10rem;
  height: 3rem;
  text-transform: none;
  padding: 0.4rem;
}

.BbQVONAkMDcG83czH_pv {
  width: 2rem;
  height: 2rem;
}`, "",{"version":3,"sources":["webpack://./src/components/3-organisms/TenderDetails/ChatOverview/ChatRoomDetails/ChatInput/ChatInput.module.scss"],"names":[],"mappings":"AAAA;EACE,0CAAA;EACA,mBAAA;EACA,aAAA;AACF;;AAEA;EACE,OAAA;EACA,4BAAA;EACA,YAAA;EACA,uBAAA;EACA,+BAAA;AACF;AAAE;EACE,aAAA;AAEJ;;AAEA;EACE,aAAA;EACA,iBAAA;EACA,uBAAA;EACA,2BAAA;EACA,SAAA;AACF;;AAEA;EACE,gBAAA;EACA,YAAA;EACA,oBAAA;EACA,eAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;AACF","sourcesContent":[".ChatInput {\n  background-color: var(--athens-grey-light);\n  border-radius: 2rem;\n  display: flex;\n}\n\n.ChatInput__input {\n  flex: 1;\n  padding: 3rem 2rem 2rem 2rem;\n  border: none;\n  background: transparent;\n  font-size: var(--font-size--sm);\n  &:focus {\n    outline: none;\n  }\n}\n\n.ChatInput__actionsWrapper {\n  display: flex;\n  flex-flow: column;\n  justify-content: center;\n  margin: 2rem 2rem 2rem 2rem;\n  gap: 2rem;\n}\n\n.ChatInput__submitButton {\n  min-width: 10rem;\n  height: 3rem;\n  text-transform: none;\n  padding: 0.4rem;\n}\n\n.ChatInput__icon {\n  width: 2rem;\n  height: 2rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ChatInput": `eGbBdXWx4WHeFIq7spMR`,
	"ChatInput__input": `XNPjwz03wlb6QQ0aTgxU`,
	"ChatInput__actionsWrapper": `vTp8a1As0vPxU__2HwGg`,
	"ChatInput__submitButton": `tTPkRcm6IL6NWpo06i8c`,
	"ChatInput__icon": `BbQVONAkMDcG83czH_pv`
};
export default ___CSS_LOADER_EXPORT___;
