// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.COPkU3yRnHpnjnxoC_sB {
  display: flex;
  justify-content: space-between;
  flex-flow: column;
}

.kZmFiB9_FONCf9A2rO2V {
  align-items: flex-end;
}

.oo929Q2Xg1vb3kBGrmJA {
  display: flex;
  gap: 1rem;
}
.kZmFiB9_FONCf9A2rO2V .oo929Q2Xg1vb3kBGrmJA {
  flex-flow: row-reverse;
}

.kBQqf7_j4VXcUfURHqL6 {
  padding: 2rem;
  background-color: var(--athens-grey-light);
  margin-bottom: 1rem;
  border-radius: 0.5rem;
  white-space: break-spaces;
}
.kZmFiB9_FONCf9A2rO2V .kBQqf7_j4VXcUfURHqL6 {
  background-color: var(--athens-grey-light);
}

.mB0f9vTrki1iHxLHdI4x {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
}`, "",{"version":3,"sources":["webpack://./src/components/3-organisms/TenderDetails/ChatOverview/ChatRoomDetails/ChatBubble/ChatBubble.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;EACA,iBAAA;AACF;;AAEA;EACE,qBAAA;AACF;;AAEA;EACE,aAAA;EACA,SAAA;AACF;AACE;EACE,sBAAA;AACJ;;AAGA;EACE,aAAA;EACA,0CAAA;EACA,mBAAA;EACA,qBAAA;EACA,yBAAA;AAAF;AAEE;EACE,0CAAA;AAAJ;;AAIA;EACE,WAAA;EACA,YAAA;EACA,kBAAA;AADF","sourcesContent":[".ChatBubble {\n  display: flex;\n  justify-content: space-between;\n  flex-flow: column;\n}\n\n.ChatBubble__owner {\n  align-items: flex-end;\n}\n\n.ChatBubble__contentWrapper {\n  display: flex;\n  gap: 1rem;\n\n  .ChatBubble__owner & {\n    flex-flow: row-reverse;\n  }\n}\n\n.ChatBubble__messageContent {\n  padding: 2rem;\n  background-color: var(--athens-grey-light);\n  margin-bottom: 1rem;\n  border-radius: 0.5rem;\n  white-space: break-spaces;\n\n  .ChatBubble__owner & {\n    background-color: var(--athens-grey-light);\n  }\n}\n\n.ChatBubble__avatar {\n  width: 4rem;\n  height: 4rem;\n  border-radius: 50%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ChatBubble": `COPkU3yRnHpnjnxoC_sB`,
	"ChatBubble__owner": `kZmFiB9_FONCf9A2rO2V`,
	"ChatBubble__contentWrapper": `oo929Q2Xg1vb3kBGrmJA`,
	"ChatBubble__messageContent": `kBQqf7_j4VXcUfURHqL6`,
	"ChatBubble__avatar": `mB0f9vTrki1iHxLHdI4x`
};
export default ___CSS_LOADER_EXPORT___;
