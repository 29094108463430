// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.uI4oXOOyO838KQ2wh1xq {
  background-color: var(--white);
  box-shadow: var(--primary-shadow);
  border-radius: 1.6rem;
}

.RG_W0t7RsCRRG6uuYOgk {
  padding: 2rem 4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: var(--secondary-border);
}

.XNMCgbQxK7DEizFbPPWw {
  padding: 5rem 4rem;
}`, "",{"version":3,"sources":["webpack://./src/components/2-molecules/Cards/ContentCard/ContentCard.module.scss"],"names":[],"mappings":"AAAA;EACE,8BAAA;EACA,iCAAA;EACA,qBAAA;AACF;;AAEA;EACE,kBAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,sCAAA;AACF;;AAEA;EACE,kBAAA;AACF","sourcesContent":[".ContentCard {\n  background-color: var(--white);\n  box-shadow: var(--primary-shadow);\n  border-radius: 1.6rem;\n}\n\n.ContentCard__heading {\n  padding: 2rem 4rem;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  border-bottom: var(--secondary-border);\n}\n\n.ContentCard__body {\n  padding: 5rem 4rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ContentCard": `uI4oXOOyO838KQ2wh1xq`,
	"ContentCard__heading": `RG_W0t7RsCRRG6uuYOgk`,
	"ContentCard__body": `XNMCgbQxK7DEizFbPPWw`
};
export default ___CSS_LOADER_EXPORT___;
