export const mockedSuppliersList: Supplier[] = [
  {
    id: '1',
    name: 'Supplier_1',
    industry: 'Phone contracts',
    organizationNumber: '1223456789',
    activeContracts: '27',
    rating: '4',
    status: 'Active',
  },

  {
    id: '2',
    name: 'Supplier_2',
    industry: 'ERP',
    organizationNumber: '4563217890',
    activeContracts: '45',
    rating: '4',
    status: 'Active',
  },
  {
    id: '3',
    name: 'Supplier_3',
    industry: 'Debt collection',
    organizationNumber: '6748748481',
    activeContracts: '61',
    rating: '5',
    status: 'Active',
  },
  {
    id: '4',
    name: 'Supplier_4',
    industry: 'Construction',
    organizationNumber: '9876540321',
    activeContracts: '3',
    rating: '3',
    status: 'Active',
  },
  {
    id: '5',
    name: 'Supplier_5',
    industry: 'Phone contracts    ',
    organizationNumber: '0147852369',
    activeContracts: '15',
    rating: '4',
    status: 'Inactive',
  },
];
