// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.uKaWuYF2gTkGJzUMYbf2 {
  border: var(--secondary-border);
  animation: Zzxa_BqpR4iN0hYNvMBN 0.5s ease-out;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  padding: 2rem;
  flex: 1 1 0;
  height: 15rem;
}

.lLK5so7o9Y4kyTmB6tRh {
  display: flex;
  justify-content: space-between;
}

.fo1LMvmx1co1V5cKX_7i {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

.GSITS1kdHOjoOas_uxlN {
  color: var(--dark-grey-600);
}

.spelvATq9Jiu2uFjZdPb {
  color: var(--error-color);
}

.iS1qvXBwDCWyyLc0VXFY {
  color: var(--dark-grey-600);
  text-transform: capitalize;
  align-self: end;
}

.Yc2lPYYWHlNcg0rY4tuJ {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 1rem;
}

.vfAAoO3Lbcp5U6tDw7Ml {
  display: inline-block;
  color: var(--dark-grey-900);
  font-size: var(--font-size--xs);
  margin-right: 0.5rem;
  margin-left: 0.8rem;
}

.L0Qi3cR_LUIZ1MzMg7qf {
  display: inline-block;
  color: var(--dark-grey-700);
  font-size: var(--font-size--xs);
}

@keyframes Zzxa_BqpR4iN0hYNvMBN {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/2-molecules/Cards/TaskCard/TaskCard.module.scss"],"names":[],"mappings":"AAAA;EACE,+BAAA;EACA,6CAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;EACA,aAAA;EACA,WAAA;EACA,aAAA;AACF;;AAEA;EACE,aAAA;EACA,8BAAA;AACF;;AAEA;EACE,aAAA;EACA,qBAAA;EACA,sBAAA;AACF;;AAEA;EACE,2BAAA;AACF;;AAEA;EACE,yBAAA;AACF;;AAEA;EACE,2BAAA;EACA,0BAAA;EACA,eAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,2BAAA;EACA,mBAAA;AACF;;AAEA;EACE,qBAAA;EACA,2BAAA;EACA,+BAAA;EACA,oBAAA;EACA,mBAAA;AACF;;AAEA;EACE,qBAAA;EACA,2BAAA;EACA,+BAAA;AACF;;AAEA;EACE;IACE,UAAA;EACF;EACA;IACE,UAAA;EACF;AACF","sourcesContent":[".TaskCard {\n  border: var(--secondary-border);\n  animation: fadeIn 0.5s ease-out;\n  display: flex;\n  flex-direction: column;\n  gap: 4rem;\n  padding: 2rem;\n  flex: 1 1 0;\n  height: 15rem;\n}\n\n.TaskCard__row {\n  display: flex;\n  justify-content: space-between;\n}\n\n.TaskCard__statusWrapper {\n  display: flex;\n  align-items: flex-end;\n  flex-direction: column;\n}\n\n.TaskCard__statusHeading {\n  color: var(--dark-grey-600);\n}\n\n.TaskCard__status {\n  color: var(--error-color);\n}\n\n.TaskCard__eventType {\n  color: var(--dark-grey-600);\n  text-transform: capitalize;\n  align-self: end;\n}\n\n.TaskCard__detailWrapper {\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  margin-bottom: 1rem;\n}\n\n.TaskCard__detailHeading {\n  display: inline-block;\n  color: var(--dark-grey-900);\n  font-size: var(--font-size--xs);\n  margin-right: 0.5rem;\n  margin-left: 0.8rem;\n}\n\n.TaskCard__detail {\n  display: inline-block;\n  color: var(--dark-grey-700);\n  font-size: var(--font-size--xs);\n}\n\n@keyframes fadeIn {\n  from {\n    opacity: 0;\n  }\n  to {\n    opacity: 1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TaskCard": `uKaWuYF2gTkGJzUMYbf2`,
	"fadeIn": `Zzxa_BqpR4iN0hYNvMBN`,
	"TaskCard__row": `lLK5so7o9Y4kyTmB6tRh`,
	"TaskCard__statusWrapper": `fo1LMvmx1co1V5cKX_7i`,
	"TaskCard__statusHeading": `GSITS1kdHOjoOas_uxlN`,
	"TaskCard__status": `spelvATq9Jiu2uFjZdPb`,
	"TaskCard__eventType": `iS1qvXBwDCWyyLc0VXFY`,
	"TaskCard__detailWrapper": `Yc2lPYYWHlNcg0rY4tuJ`,
	"TaskCard__detailHeading": `vfAAoO3Lbcp5U6tDw7Ml`,
	"TaskCard__detail": `L0Qi3cR_LUIZ1MzMg7qf`
};
export default ___CSS_LOADER_EXPORT___;
