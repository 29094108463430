export const mockedCompaniesList: Organisation[] = [
  {
    companyName: 'ABC Corp',
    industry: 'Technology',
    orgId: 'ABC123',
    numberOfEmployees: 100,
    phoneNumber: 1234567890,
    email: 'john.doe@abccorp.com',
    address: '123 Main St, City, Country',
    numberOfInvoicesOutLastYear: 500,
    numberOfInvoicesInLastYear: 300,
    turnover: 1000000,
    contactPerson: {
      name: 'John Doe',
      id: '123456',
      userType: 'supplier',
      profileImage: {
        src: 'https://example.com/profiles/johndoe.jpg',
        alt: 'John Doe profile image',
      },
      email: 'johndoe@example.com',
      phoneNumber: 1234567890,
      role: 'User',
    },
  },
  {
    companyName: 'XYZ Inc',
    industry: 'Finance',
    orgId: 'XYZ456',
    numberOfEmployees: 50,
    phoneNumber: 9876543210,
    email: 'jane.smith@xyzinc.com',
    address: '456 Elm St, City, Country',
    numberOfInvoicesOutLastYear: 200,
    numberOfInvoicesInLastYear: 400,
    turnover: 500000,
    contactPerson: {
      name: 'Jane Smith',
      id: '789012',
      userType: 'supplier',
      profileImage: {
        src: 'https://example.com/profiles/janesmith.jpg',
        alt: 'Jane Smith profile image',
      },
      email: 'janesmith@example.com',
      phoneNumber: 9876543210,
      role: 'Admin',
    },
  },
  {
    companyName: 'DEF Ltd',
    industry: 'Manufacturing',
    orgId: 'DEF789',
    numberOfEmployees: 200,
    phoneNumber: 5555555555,
    email: 'david.brown@defltd.com',
    address: '789 Oak St, City, Country',
    numberOfInvoicesOutLastYear: 800,
    numberOfInvoicesInLastYear: 600,
    turnover: 2000000,
    contactPerson: {
      name: 'Alice Johnson',
      id: '345678',
      userType: 'buyer',
      profileImage: {
        src: 'https://example.com/profiles/alicejohnson.jpg',
        alt: 'Alice Johnson profile image',
      },
      email: 'alicejohnson@example.com',
      phoneNumber: 6543219870,
      role: 'User',
    },
  },
  {
    companyName: 'GHI Co',
    industry: 'Retail',
    orgId: 'GHI012',

    numberOfEmployees: 80,
    phoneNumber: 1111111111,
    email: 'sarah.johnson@ghico.com',
    address: '012 Pine St, City, Country',
    numberOfInvoicesOutLastYear: 400,
    numberOfInvoicesInLastYear: 200,
    turnover: 800000,
    contactPerson: {
      name: 'Bob Thompson',
      id: '901234',
      userType: 'supplier',
      profileImage: {
        src: 'https://example.com/profiles/bobthompson.jpg',
        alt: 'Bob Thompson profile image',
      },
      email: 'bobthompson@example.com',
      phoneNumber: 3219876540,
      role: 'User',
    },
  },

  {
    companyName: 'JKL Enterprises',
    industry: 'Consulting',
    orgId: 'JKL345',

    numberOfEmployees: 30,
    phoneNumber: 2222222222,
    email: 'michael.williams@jklenterprises.com',
    address: '345 Cedar St, City, Country',
    numberOfInvoicesOutLastYear: 100,
    numberOfInvoicesInLastYear: 150,
    turnover: 300000,
    contactPerson: {
      name: 'Eve Wilson',
      id: '567890',
      userType: 'supplier',
      profileImage: {
        src: 'https://example.com/profiles/evewilson.jpg',
        alt: 'Eve Wilson profile image',
      },
      email: 'evewilson@example.com',
      phoneNumber: 7890123456,
      role: 'Admin',
    },
  },
];
