// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Zjbeg768oUDvSczAW6W6 {
  display: flex;
  align-items: stretch;
  border: var(--primary-border);
  border-radius: 0.6rem;
  overflow: hidden;
  list-style-type: none;
}

.Pp78U2t4CDNHpn8ssNfy {
  flex: 1 1 0;
  cursor: pointer;
}

.CnmP_OZYS_T42afpe6RQ {
  text-decoration: none;
  border-right: var(--primary-border);
  display: block;
  padding: 2rem 0 2rem 2rem;
  transition: all 0.2s ease-in-out;
  height: 100%;
}
.CnmP_OZYS_T42afpe6RQ :last-of-type {
  border-right: unset;
}

.CnmP_OZYS_T42afpe6RQ.active {
  background: var(--primary-color);
  border: unset;
}
.CnmP_OZYS_T42afpe6RQ.active .hEKFG2gtsv19aoTGWPOW {
  color: var(--white);
}`, "",{"version":3,"sources":["webpack://./src/components/2-molecules/TopNavigation/TopNavigation.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,oBAAA;EACA,6BAAA;EACA,qBAAA;EACA,gBAAA;EACA,qBAAA;AACF;;AAEA;EACE,WAAA;EACA,eAAA;AACF;;AAEA;EACE,qBAAA;EACA,mCAAA;EACA,cAAA;EACA,yBAAA;EACA,gCAAA;EACA,YAAA;AACF;AACE;EACE,mBAAA;AACJ;;AAGA;EACE,gCAAA;EACA,aAAA;AAAF;AAEE;EACE,mBAAA;AAAJ","sourcesContent":[".TopNavigation {\n  display: flex;\n  align-items: stretch;\n  border: var(--primary-border);\n  border-radius: 0.6rem;\n  overflow: hidden;\n  list-style-type: none;\n}\n\n.TopNavigation__listItem {\n  flex: 1 1 0;\n  cursor: pointer;\n}\n\n.TopNavigation__link {\n  text-decoration: none;\n  border-right: var(--primary-border);\n  display: block;\n  padding: 2rem 0 2rem 2rem;\n  transition: all 0.2s ease-in-out;\n  height: 100%;\n\n  :last-of-type {\n    border-right: unset;\n  }\n}\n\n.TopNavigation__link:global(.active) {\n  background: var(--primary-color);\n  border: unset;\n\n  .TopNavigation__linkHeading {\n    color: var(--white);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TopNavigation": `Zjbeg768oUDvSczAW6W6`,
	"TopNavigation__listItem": `Pp78U2t4CDNHpn8ssNfy`,
	"TopNavigation__link": `CnmP_OZYS_T42afpe6RQ`,
	"TopNavigation__linkHeading": `hEKFG2gtsv19aoTGWPOW`
};
export default ___CSS_LOADER_EXPORT___;
