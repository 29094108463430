// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lzgd1Lm0glKl4IFCVR5p {
  width: 100%;
}

.oGpF51u8Jmd4uZbLGmGL {
  margin-bottom: 1rem;
}

._pHmXleB3SezI7PM0HiU {
  width: 18rem;
  height: 18rem;
  position: relative;
}

.rh_cL4mAS_NEwMmhekjK {
  width: 100%;
  height: 100%;
}

.mCITirSzcyZCbcMegAcM {
  display: none;
}

.WutSXesLZynS0fo6GflW {
  background-color: var(--white);
  width: 3.4rem;
  height: 3.4rem;
  border-radius: 50%;
  box-shadow: var(--primary-shadow);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: -1.5rem;
  right: -1.5rem;
}

.fcUK0zAeEenZhE5ATrW5 {
  width: 2rem;
  height: 2rem;
}`, "",{"version":3,"sources":["webpack://./src/components/2-molecules/Form/FormFieldImageInput/FormFieldImageInput.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;;AAEA;EACE,mBAAA;AACF;;AAEA;EACE,YAAA;EACA,aAAA;EACA,kBAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;AACF;;AAEA;EACE,aAAA;AACF;;AAEA;EACE,8BAAA;EACA,aAAA;EACA,cAAA;EACA,kBAAA;EACA,iCAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,kBAAA;EACA,eAAA;EACA,cAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;AACF","sourcesContent":[".FormFieldImageInput {\n  width: 100%;\n}\n\n.FormFieldImageInput__heading {\n  margin-bottom: 1rem;\n}\n\n.FormFieldImageInput__imagePreview {\n  width: 18rem;\n  height: 18rem;\n  position: relative;\n}\n\n.FormFieldImageInput__image {\n  width: 100%;\n  height: 100%;\n}\n\n.FormFieldImageInput__input {\n  display: none;\n}\n\n.FormFieldImageInput__iconWrapper {\n  background-color: var(--white);\n  width: 3.4rem;\n  height: 3.4rem;\n  border-radius: 50%;\n  box-shadow: var(--primary-shadow);\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  position: absolute;\n  bottom: -1.5rem;\n  right: -1.5rem;\n}\n\n.FormFieldImageInput__icon {\n  width: 2rem;\n  height: 2rem;\n}\n\n// TODO: Add Disabled State and Error State\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"FormFieldImageInput": `lzgd1Lm0glKl4IFCVR5p`,
	"FormFieldImageInput__heading": `oGpF51u8Jmd4uZbLGmGL`,
	"FormFieldImageInput__imagePreview": `_pHmXleB3SezI7PM0HiU`,
	"FormFieldImageInput__image": `rh_cL4mAS_NEwMmhekjK`,
	"FormFieldImageInput__input": `mCITirSzcyZCbcMegAcM`,
	"FormFieldImageInput__iconWrapper": `WutSXesLZynS0fo6GflW`,
	"FormFieldImageInput__icon": `fcUK0zAeEenZhE5ATrW5`
};
export default ___CSS_LOADER_EXPORT___;
