import classNames from 'classnames';
import styles from './AnchorLink.module.scss';
import { Link } from 'react-router-dom';

export interface AnchorLinkProps {
  className?: string;
  children: JSX.Element | string;
  link: string;
}

export const AnchorLink = ({ className, children, link }: AnchorLinkProps) => (
  <Link to={link} className={classNames(styles.AnchorLink, className)}>
    {children}
  </Link>
);
