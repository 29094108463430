import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '@/store';
import { setTitle } from '@/store/slices/headerSlice';

import { LeadsOverview } from '@/components/3-organisms';
import { LeadsLayout } from '@/components/4-templates';

import styles from './LeadsOverviewPage.module.scss';

export const LeadsOverviewPage = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setTitle('leads'));
    // dispatch(getLeadsList());
  }, []);

  const leadsList = useAppSelector((state) => state.supplierProfile.leadOverview);

  return (
    <LeadsLayout>
      <section className={styles.LeadsOverviewPage}>
        <LeadsOverview leadsList={leadsList} />
      </section>
    </LeadsLayout>
  );
};
