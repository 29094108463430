import { useForm, SubmitHandler } from 'react-hook-form';
import styles from './CreateSupplierForm.module.scss';
import { Heading } from '@/components/1-atoms';
import { FormFieldInput, FormFieldCta, PopupCard } from '@/components/2-molecules';

type AccountDetailsForm = {
  supplierName: string;
  organizationNumber: number;
  contactPerson: string;
  email: string;
  phone: string;
};

export interface CreateSupplierFormProps {
  className?: string;
  setOpenPopup: () => void;
}

export const CreateSupplierForm = ({ setOpenPopup }: CreateSupplierFormProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<AccountDetailsForm>();
  const onSubmit: SubmitHandler<AccountDetailsForm> = (data) => {
    console.log(data);
  };

  return (
    <PopupCard onCloseClick={() => setOpenPopup()}>
      <form className={styles.CreateSupplierForm} onSubmit={handleSubmit(onSubmit)}>
        <Heading headingLevel={'h2'}>Add new supplier</Heading>

        <FormFieldInput
          id={'supplierName'}
          label={'Supplier name'}
          type={'text'}
          name={'Supplier name'}
          register={register}
          // TODO: Add Disabled State and Error State
        />
        <FormFieldInput
          id={'organizationNumber'}
          label={'Organization number'}
          type={'text'}
          name={'Organization number'}
          register={register}
          // TODO: Add Disabled State and Error State
        />
        <FormFieldInput
          id={'contactPerson'}
          label={'Contact person'}
          type={'text'}
          name={'Contact person'}
          register={register}
          // TODO: Add Disabled State and Error State
        />
        <FormFieldInput
          id={'email'}
          label={'Email'}
          type={'text'}
          name={'Email'}
          register={register}
          // TODO: Add Disabled State and Error State
        />
        <FormFieldInput
          id={'phone'}
          label={'Phone'}
          type={'text'}
          name={'Phone'}
          register={register}
          // TODO: Add Disabled State and Error State
        />

        <FormFieldCta
          className={styles.CreateSupplierForm__submitButton}
          type='submit'
          id={'save'}
          label={'Add new supplier'}
        />
      </form>
    </PopupCard>
  );
};
