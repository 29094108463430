import { useId, useState } from 'react';
import styles from './FormFieldRadio.module.scss';
import { Heading } from '@/components/1-atoms';
import classNames from 'classnames';

interface Options {
  label: string;
  value: string | number;
}

type State = {
  required?: boolean;
  disabled?: boolean;
  hasError?: boolean;
};

export interface FormFieldRadioProps {
  title: string;
  options: Options[];
  name: string;
  errorMessage?: string;
  defaultSelectedValue?: string | number;
  className?: string;
  onChange: (value: string | number) => void;
  state?: State;
  optionsGap?: string;
}

export const FormFieldRadio = ({
  options,
  title,
  name,
  defaultSelectedValue,
  className,
  onChange,
  state,
  optionsGap = '4rem',
}: FormFieldRadioProps) => {
  const [selectedValue, setSelectedValue] = useState(defaultSelectedValue || '');

  const { required, disabled, hasError } = state ?? {};

  const handleChange = (value: string | number) => {
    console.log('value', value);
    setSelectedValue(value);
    onChange(value);
  };

  const generatedId = useId();

  return (
    <div className={classNames(styles.FormFieldRadio, className)}>
      <Heading headingLevel='h3' className={styles.FormFieldRadio__title}>
        {title}
      </Heading>
      <div style={{ gap: optionsGap }} className={styles.FormFieldRadio__groupContainer}>
        {options?.map((option) => (
          <div className={styles.FormFieldRadio__btnContainer} key={generatedId + option.value}>
            <input
              type='radio'
              id={generatedId}
              className={styles.FormFieldRadio__button}
              value={option.value}
              name={name}
              checked={option.value === selectedValue}
              onChange={() => handleChange(option.value)}
              required={required}
            />
            <label className={styles.FormFieldRadio__label} htmlFor={generatedId + option.value}>
              {option.label}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};
