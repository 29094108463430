// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.H9MNnfZJf0VKCnU4pyy6 {
  text-decoration: none;
  color: var(--accent-color);
  font-size: var(--font-size--md);
  font-weight: var(--font-weight--normal);
  font-family: var(--font-secondary);
}`, "",{"version":3,"sources":["webpack://./src/components/1-atoms/AnchorLink/AnchorLink.module.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;EACA,0BAAA;EACA,+BAAA;EACA,uCAAA;EACA,kCAAA;AACF","sourcesContent":[".AnchorLink {\n  text-decoration: none;\n  color: var(--accent-color);\n  font-size: var(--font-size--md);\n  font-weight: var(--font-weight--normal);\n  font-family: var(--font-secondary);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AnchorLink": `H9MNnfZJf0VKCnU4pyy6`
};
export default ___CSS_LOADER_EXPORT___;
