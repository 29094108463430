import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface HeaderState {
  title: string;
}
const initialState: HeaderState = {
  title: 'overview',
};

export const headerSlice = createSlice({
  name: 'header',
  initialState,
  reducers: {
    setTitle: (state: HeaderState, action: PayloadAction<string>) => {
      state.title = action.payload;
    },
  },
});

export const { setTitle } = headerSlice.actions;
export default headerSlice.reducer;
