import { useEffect, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { Accordion, FormFieldInput, CategoriesSelect, IconButton } from '@/components/2-molecules';
import { Heading, Paragraph } from '@/components/1-atoms';
import { categories as categoriesData } from '@/constants/categories';

import { ReactComponent as TrashIconGrey } from '@/assets/Icons/icon-trash-grey.svg';
import { ReactComponent as TrashIcon } from '@/assets/Icons/icon-trash.svg';
import { ReactComponent as PlusIcon } from '@/assets/Icons/icon-plus.svg';
import styles from './QuestionsForm.module.scss';
import { randomId } from '@/utilities/randomId';
import { useAppDispatch } from '@/store';
import { addPhaseQuestion } from '@/store/slices/createTenderSlice';

export interface QuestionsFormProps {
  className?: string;
  currentPhaseName: string;
}

export const QuestionsForm = ({ className, currentPhaseName }: QuestionsFormProps) => {
  const nestedFormName = `${currentPhaseName}.tenderQuestions`;

  const { control, register, getValues } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: nestedFormName,
  });

  const [categories, setCategories] = useState<Category[]>([...categoriesData]);

  const phaseId = getValues(`${currentPhaseName}.phaseId`);

  const submitCategories = (list: Category[]) => {
    fields.forEach((field, index) => {
      const question = field as unknown as TenderFormPhaseQuestion;

      const category = list.find((category) => category.value === question.selectedCategoryId);
      if (!category?.checked) remove(index);
    });

    setCategories(list);
  };

  useEffect(() => {
    fields.forEach((field) => {
      const question = field as unknown as TenderFormPhaseQuestion;

      const category = categories.find(
        (category) => category.value === question.selectedCategoryId,
      );

      if (!category?.checked)
        setCategories((prev) => {
          return prev.map((prevCategory) => {
            if (prevCategory.value === category?.value) {
              return { ...prevCategory, checked: true };
            }
            return prevCategory;
          });
        });
    });
  }, []);

  return (
    <div className={className}>
      {categories &&
        categories
          .filter((category) => category.checked)
          .map((category, index) => (
            <Accordion
              key={index}
              startOpen={true}
              className={styles.QuestionsForm__accordion}
              buttonClass={styles.QuestionsForm__accordionButton}
              heading={
                <div className={styles.QuestionsForm__accordionWrapper}>
                  <div className={styles.QuestionsForm__heading}>
                    <Heading className={styles.QuestionsForm__title} headingLevel={'h3'}>
                      {index + 1}. {category.label}
                    </Heading>

                    <IconButton
                      icon={<TrashIconGrey />}
                      onClick={(e) => {
                        e?.stopPropagation();
                        e?.preventDefault();
                        console.log('click', category);
                        setCategories((prev) => {
                          return prev.map((item) => {
                            if (item.value === category.value) return { ...item, checked: false };
                            return item;
                          });
                        });
                      }}
                    />
                  </div>
                  <IconButton
                    icon={<PlusIcon />}
                    className={styles.QuestionsForm__addButton}
                    onClick={(e) => {
                      e?.preventDefault();
                      e?.stopPropagation();
                      const question = {
                        phaseId: phaseId,
                        id: randomId(),
                        headline: '',
                        content: '',
                        selectedCategoryId: category.value,
                      };
                      append(question);
                      // dispatch(addPhaseQuestion({ phaseId, question }));
                    }}
                  >
                    <Paragraph>Add question</Paragraph>
                  </IconButton>
                </div>
              }
            >
              <div className={styles.QuestionsForm__questionsList}>
                {fields.map((field, index) => {
                  const question = field as unknown as TenderFormPhaseQuestion;

                  if (question.selectedCategoryId == category.value)
                    return (
                      <div className={styles.QuestionsForm__questionsInput} key={field.id}>
                        <FormFieldInput
                          classNameWrapper={styles.QuestionsForm__input}
                          type='text'
                          id={`${nestedFormName}.${index}.headline`}
                          name={`${nestedFormName}.${index}.headline` as const}
                          placeholder='Write your question here'
                          register={register}
                          state={{ required: true }}
                        />
                        <div className={styles.QuestionsForm__deleteIcon}>
                          <span
                            onClick={(e) => {
                              e.stopPropagation();
                              remove(index);
                            }}
                          >
                            {<TrashIcon />}
                          </span>
                        </div>
                      </div>
                    );
                })}
              </div>
            </Accordion>
          ))}
      <CategoriesSelect
        className={styles.QuestionsForm__categoriesSelect}
        categoriesList={categories}
        submitCategories={(list) => submitCategories(list)}
      />
    </div>
  );
};
