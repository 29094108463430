// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FPSabgJZNWyBxOI75Rvg {
  padding: 8rem 5rem 3rem 5rem;
}

.pHm2iogqTh46vizNy2Ru {
  margin: 4.5rem 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

.pEzn4nl7Ynn_KXGvSAAA {
  display: flex;
  gap: 5rem;
}

.q572V2NLj2coEKQFr5V5 {
  width: 50%;
}`, "",{"version":3,"sources":["webpack://./src/components/5-pages/leads/TendersOverviewPage/TendersOverviewPage.module.scss"],"names":[],"mappings":"AAAA;EACE,4BAAA;AACF;;AAEA;EACE,gBAAA;EACA,WAAA;EACA,aAAA;EACA,uBAAA;AACF;;AAEA;EACE,aAAA;EACA,SAAA;AACF;;AAEA;EACE,UAAA;AACF","sourcesContent":[".TendersOverviewPage {\n  padding: 8rem 5rem 3rem 5rem;\n}\n\n.TendersOverviewPage__createTenderCta {\n  margin: 4.5rem 0;\n  width: 100%;\n  display: flex;\n  justify-content: center;\n}\n\n.TendersOverviewPage__eventsSection {\n  display: flex;\n  gap: 5rem;\n}\n\n.TendersOverviewPage__calendar {\n  width: 50%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TendersOverviewPage": `FPSabgJZNWyBxOI75Rvg`,
	"TendersOverviewPage__createTenderCta": `pHm2iogqTh46vizNy2Ru`,
	"TendersOverviewPage__eventsSection": `pEzn4nl7Ynn_KXGvSAAA`,
	"TendersOverviewPage__calendar": `q572V2NLj2coEKQFr5V5`
};
export default ___CSS_LOADER_EXPORT___;
