import classNames from 'classnames';
import styles from './ChatRoomDetails.module.scss';

import { Heading } from '@/components/1-atoms';
import { IconButton } from '@/components/2-molecules';
import { ChatBubble } from './ChatBubble';

import { ReactComponent as MessageIcon } from '@/assets/Icons/icon-message.svg';
import { ReactComponent as ArrowIcon } from '@/assets/Icons/icon-arrow-accordion.svg';

import { useNavigate, useParams } from 'react-router-dom';
import { ChatInput } from './ChatInput';
import useChatWebSocket from '@/hooks/useChatWebSocket';
import { getChatRoom } from '@/services/ChatServices';
import { useEffect, useRef, useState } from 'react';
import { formatDateAndTime } from '@/utilities/dateHandler';

export interface ChatRoomDetailsProps {
  className?: string;
  userRole?: 'buyer' | 'supplier';
  contactId?: number;
}

export const ChatRoomDetails = ({ className, userRole, contactId }: ChatRoomDetailsProps) => {
  const navigate = useNavigate();

  const [chatRoom, setChatRoom] = useState<ChatRoomDetails>();
  const [participants, setParticipants] = useState<ChatRoomParticipant[]>();
  const messagesEndRef = useRef<null | HTMLDivElement>(null);

  const { roomId } = useParams();

  useEffect(() => {
    async function fetchChatRoom() {
      const response = await getChatRoom(Number(roomId));
      setChatRoom(response?.chatRoom);
      setParticipants(response?.participants);
    }
    fetchChatRoom();
  }, [contactId]);

  const { title, messages } = chatRoom ?? {};

  if (!contactId || !roomId) return null;

  const { sendMessage, messageHistory } = useChatWebSocket(roomId, contactId.toString());

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages, messageHistory]);

  return (
    <>
      {userRole !== 'supplier' && (
        <IconButton
          className={styles.ChatRoomDetails__backButton}
          icon={<ArrowIcon className={styles.ChatRoomDetails__arrowIcon} />}
          onClick={() => {
            navigate(-1);
          }}
        >
          Back
        </IconButton>
      )}
      <div
        className={classNames(styles.ChatRoomDetails, className)}
        style={{ marginTop: userRole === 'supplier' ? '8rem' : '0' }}
      >
        <div className={styles.ChatRoomDetails__header}>
          <div className={styles.ChatRoomDetails__title}>
            <MessageIcon />
            <Heading headingLevel={'h3'} className={styles.ChatRoomDetails__heading}>
              {title}
            </Heading>
          </div>
          {/* {createdDate} */}
        </div>

        <div className={styles.ChatRoomDetails__MessagesList}>
          {messages?.map((message, index) => (
            <ChatBubble
              key={index}
              message={message.content}
              date={formatDateAndTime(message.sentAt)}
              name={
                participants?.find(
                  (participant) => participant.contactId == Number(message.senderContactId),
                )?.name ?? ''
              }
              userIsOwner={message.senderContactId == contactId.toString()}
            />
          ))}

          {messageHistory?.map((message, index) => (
            <ChatBubble
              key={index}
              message={message.data.content}
              date={formatDateAndTime(message.data.sentAt)}
              name={
                participants?.find(
                  (participant) => participant.contactId == Number(message.data.senderContactId),
                )?.name ?? ''
              }
              userIsOwner={message.data.senderContactId == contactId.toString()}
            />
          ))}

          <div ref={messagesEndRef} />
        </div>

        <ChatInput
          onMessageSubmit={(message) => {
            sendMessage(message);
          }}
        />
      </div>
    </>
  );
};
