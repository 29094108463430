// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.uVAtqjtxh0NHjHkBmdl0 {
  border: var(--primary-border);
  border-radius: 0.6rem;
  display: flex;
  width: 100%;
  flex-direction: column;
}
.P9QX3qr6YRjw9bIKq41p .bIzZnjt2kX6B6E_khcua {
  border-bottom: var(--primary-border);
}
.P9QX3qr6YRjw9bIKq41p .U7_RZUGlwf9d4kiD92Ao {
  display: block;
}

.bIzZnjt2kX6B6E_khcua {
  background-color: var(--light-grey-150);
  border: none;
  padding: 2rem 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border-radius: 0.6rem 0.6rem 0 0;
}

.WGJldKNEA3f8gzsUKose {
  transition: all 0.3s ease-in-out;
}
.LB_zgS1oZTseLclWaeLw {
  transform: rotate(180deg);
}

.SF0R02aANERE8ChHGD63 {
  background-color: var(--white);
  animation: V03qNHFJPNUkt2FsM4EA 0.5s ease-in-out;
  border-radius: 0 0 0.6rem 0.6rem;
}

@keyframes V03qNHFJPNUkt2FsM4EA {
  0% {
    opacity: 0;
    max-height: 1.5rem;
  }
  100% {
    opacity: 1;
    max-height: 35rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/2-molecules/Accordion/Accordion.module.scss"],"names":[],"mappings":"AAAA;EACE,6BAAA;EACA,qBAAA;EACA,aAAA;EACA,WAAA;EACA,sBAAA;AACF;AAEI;EACE,oCAAA;AAAN;AAGI;EACE,cAAA;AADN;;AAMA;EACE,uCAAA;EACA,YAAA;EACA,oBAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,eAAA;EACA,gCAAA;AAHF;;AAMA;EACE,gCAAA;AAHF;AAKE;EACE,yBAAA;AAHJ;;AAOA;EACE,8BAAA;EACA,gDAAA;EACA,gCAAA;AAJF;;AAOA;EACE;IACE,UAAA;IACA,kBAAA;EAJF;EAMA;IACE,UAAA;IACA,iBAAA;EAJF;AACF","sourcesContent":[".Accordion {\n  border: var(--primary-border);\n  border-radius: 0.6rem;\n  display: flex;\n  width: 100%;\n  flex-direction: column;\n\n  &_open {\n    .Accordion__button {\n      border-bottom: var(--primary-border);\n    }\n\n    .Accordion__content {\n      display: block;\n    }\n  }\n}\n\n.Accordion__button {\n  background-color: var(--light-grey-150);\n  border: none;\n  padding: 2rem 1.5rem;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  cursor: pointer;\n  border-radius: 0.6rem 0.6rem 0 0;\n}\n\n.Accordion__icon {\n  transition: all 0.3s ease-in-out;\n\n  &_open {\n    transform: rotate(180deg);\n  }\n}\n\n.Accordion__contentWrapper {\n  background-color: var(--white);\n  animation: animateAccordion 0.5s ease-in-out;\n  border-radius: 0 0 0.6rem 0.6rem;\n}\n\n@keyframes animateAccordion {\n  0% {\n    opacity: 0;\n    max-height: 1.5rem;\n  }\n  100% {\n    opacity: 1;\n    max-height: 35rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Accordion": `uVAtqjtxh0NHjHkBmdl0`,
	"Accordion_open": `P9QX3qr6YRjw9bIKq41p`,
	"Accordion__button": `bIzZnjt2kX6B6E_khcua`,
	"Accordion__content": `U7_RZUGlwf9d4kiD92Ao`,
	"Accordion__icon": `WGJldKNEA3f8gzsUKose`,
	"Accordion__icon_open": `LB_zgS1oZTseLclWaeLw`,
	"Accordion__contentWrapper": `SF0R02aANERE8ChHGD63`,
	"animateAccordion": `V03qNHFJPNUkt2FsM4EA`
};
export default ___CSS_LOADER_EXPORT___;
