import { useRef, useState } from 'react';
import classNames from 'classnames';
import { Heading, TextArea, Image, AnchorLink, TextBox } from '@/components/1-atoms';
import { ActionCard, Button, IconButton, PopupCard } from '@/components/2-molecules';
import styles from './NoticeBoard.module.scss';
import { ReactComponent as AddMessageIcon } from '@/assets/Icons/icon-add-message.svg';
import { ReactComponent as UploadIcon } from '@/assets/Icons/icon-upload.svg';
import EmptyTasksImage from '@/assets/Images/image-empty-tasks.svg';
import { NoticeItem } from './NoticeItem';
import { useAppSelector } from '@/store';
import { LoginType } from '@/constants/loginTypes';
import { CreateAlert } from '@/services/NoticeServices';

export interface NoticeBoardProps {
  className?: string;
  notices: TenderNotice[];
  tender?: TenderDetails | undefined;
  contactId?: number | undefined;
  updateNotices: (notice: TenderNotice) => void;
}

export const NoticeBoard = ({
  className,
  notices,
  tender,
  contactId,
  updateNotices,
}: NoticeBoardProps) => {
  const [openPopup, setOpenPopup] = useState(false);
  const [textAreaEmpty, setTextAreaEmpty] = useState(false);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const userRole = useAppSelector((state) => state.user.loginProfile?.version);

  const onNoticeSubmit = async (message: string) => {
    if (!tender || !tender.noticeBoard.id) return;

    const alert: TenderNotice = {
      id: tender.noticeBoard.id,
      message: message,
      createdAtDate: new Date().toISOString(),
      createdByContactId: contactId ?? 0,
      noticeBoardId: tender.noticeBoard.id,
      importanceLevel: { id: 1, name: 'Low' },
      event: { id: 5, name: 'General' },
      subEventId: 1,
    };

    try {
      await CreateAlert(alert);
      updateNotices(alert);
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmit = (value: string) => {
    if (!value) {
      setTextAreaEmpty(true);
      return;
    }
    onNoticeSubmit(value);
    setTextAreaEmpty(false);
    setOpenPopup(false);
  };

  return (
    <>
      {notices.length > 0 ? (
        <div className={classNames(styles.NoticeBoard, className)}>
          {userRole !== LoginType.Supplier ? (
            <IconButton
              className={styles.NoticeBoard__addNoticeButton}
              icon={<AddMessageIcon />}
              onClick={() => setOpenPopup(true)}
            >
              Add new message to the notice board
            </IconButton>
          ) : (
            <br />
          )}
          {notices.map((notice) => (
            <NoticeItem key={notice.id} notice={notice} />
          ))}
        </div>
      ) : userRole === LoginType.Supplier ? (
        <div className={styles.NoticeBoard__emptyList}>
          <Image src={EmptyTasksImage} alt={'Empty Tasks Image'} />
          <TextBox
            title={
              <Heading headingLevel={'h3'} className={styles.NoticeBoard__addNoticeHeading}>
                No notices yet
              </Heading>
            }
            description={
              <>
                For every new tender notice you will get a
                <br />
                notification on the <AnchorLink link={''}>Dashboard.</AnchorLink>
              </>
            }
            alignment={'centre'}
          />
        </div>
      ) : (
        <div className={classNames(styles.NoticeBoard__emptyList, className)}>
          <ActionCard
            onClick={() => setOpenPopup(true)}
            className={styles.NoticeBoard__addNoticeCard}
          >
            <AddMessageIcon />
            <TextBox
              title={
                <Heading headingLevel={'h3'} className={styles.NoticeBoard__addNoticeHeading}>
                  Add new message to the notice board
                </Heading>
              }
              description={<>All suppliers will see this message</>}
              alignment={'centre'}
            />
          </ActionCard>
        </div>
      )}

      {openPopup && (
        <PopupCard onCloseClick={() => setOpenPopup(false)} className={styles.NoticeBoard__popup}>
          <Heading headingLevel={'h3'}>New notification</Heading>
          <TextArea
            name='Notice text area'
            placeholder='Enter your message here'
            cols={30}
            rows={10}
            reference={textAreaRef}
            hasError={textAreaEmpty}
            required
          />
          <div className={styles.NoticeBoard__popupFooter}>
            <IconButton icon={<UploadIcon />} IconClass={styles.NoticeBoard__uploadIcon}>
              {' '}
              Upload files{' '}
            </IconButton>
            <div className={styles.NoticeBoard__actionButtonsWrapper}>
              <Button
                type={'borderlessPrimary'}
                onClick={() => {
                  textAreaRef.current && onSubmit(textAreaRef.current.value);
                }}
              >
                Send
              </Button>
              <Button type={'borderlessSecondary'} onClick={() => setOpenPopup(false)}>
                Close
              </Button>
            </div>
          </div>
        </PopupCard>
      )}
    </>
  );
};
