import { ReactComponent as StarIcon } from '@/assets/Icons/icon-star.svg';

export interface RatingStarProps {
  rating: string;
}

export const RatingStar = ({ rating }: RatingStarProps) => {
  const numericRating = parseInt(rating || '0');

  const filledStarColor = '#f79915';
  const emptyStarColor = '#BEBECA';

  const stars = Array(5)
    .fill(emptyStarColor)
    .map((color, index) => {
      if (index < numericRating) {
        return filledStarColor;
      }
      return color;
    });

  return (
    <div>
      {stars.map((color, index) => (
        <span key={index}>
          <StarIcon color={color} />
        </span>
      ))}
    </div>
  );
};
